.docSelector {
  .docSelector-header {
    display         : flex;
    width           : 100%;
    padding         : 70px 0 40px 0;
    flex-direction  : row;
    align-content   : inherit;
    justify-content : flex-start;
    align-items     : center;

    .primary-heading {
      flex-basis : 30%;
    }
    .supporting-copy {
      flex-basis : 30%;
      p {
        margin-bottom : 0;
      }
    }
  }

  .container {
    display         : flex;
    padding         : 30px 5% 80px 5%;
    justify-content : center;
    align-items     : flex-start;
    flex-direction  : column;
    align-content   : inherit;
  }

  .docSelector-list {
    padding-left    : 0;
    columns         : 340px;
    list-style      : none;
    columns         : 2;
    border-radius: 5px;
    overflow: hidden;
  }

  .content-label {
    display: block;
    margin : 0 0 20px 0;
    color  : rgba(255, 255, 255, 0.5);
  }

  .docSelector-type {
    display      : block;
    margin       : 0 8px 20px 2px;
    -webkit-column-break-inside: avoid; 
    page-break-inside: avoid; 
    break-inside : avoid;
  }

  .gradient-border {
    padding : 0;
  }

  .docSelector-doc {
    @include flamasemi-book;

    position            : relative;
    display             : inline-block;

    width               : 100%;
    margin-bottom       : 0;
    padding             : 20px;

    cursor              : pointer;
    font-size           : 16px;
    border-right        : 1px solid rgba(225, 232, 237, 0.15);
    border-bottom       : 1px solid rgba(225, 232, 237, 0.15);

    -webkit-user-select : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    user-select         : none;

    &:hover {
      background-image : linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
    }

    &:first-of-type{
      border-radius : 3px;
      border-top    : 1px solid rgba(225, 232, 237, 0.15);
      // border-top: 1px solid rgb(223, 231, 237);
    }

    &:last-of-type{
      border-radius : 3px;
      border-bottom : 1px solid rgba(225, 232, 237, 0.15);
      // border-bottom: 1px solid rgb(223, 231, 237);
    }

    &.active {
      background-image : linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
    }

    input {
      position : absolute;
      height   : 0;
      width    : 0;
      cursor   : pointer;
      opacity  : 0;
    }

    &:hover input {
      ~ .checkmark {
        background-color : rgba(225, 232, 237, 0.1);
      }
    }

    input:checked {
      ~ .checkmark {
        background-color : $purple;
        border-color     : $purple;

        &:after {
          display : block;
        }
      }
    }
  }

  .checkmark {
    position         : absolute;
    top              : 50%;
    left             : 15px;
    height           : 34px;
    width            : 34px;
    margin-right     : 20px;
    background-color : rgba(225, 232, 237, 0.05);
    border           : 1px solid rgba(225, 232, 237, 0.4);
    border-radius    : 100%;
    transform        : translateY(-50%);

    &:after {
      content           : "";

      position          : absolute;
      display           : none;
      top               : 8px;
      left              : 12px;

      height            : 13px;
      width             : 8px;

      border            : solid white;
      border-width      : 0 3px 3px 0;

      -webkit-transform : rotate(45deg);
      -ms-transform     : rotate(45deg);
      transform         : rotate(45deg);
    }
  }

  // REVIEW IF WE NEED THIS
  .btn-primary {
    margin : 20px 0;
  }


  &.light,
  &.neutral {
    .content-label {
      color : $purple;
    }

    .docSelector-doc, .docSelector-doc:first-of-type, .docSelector-doc:last-of-type {
      border-color : #DFE7ED;
    }

    .docSelector-doc {
      background-color : $white;
      color            : $dark-teal;

      .checkmark {
        background-color: $light-gray;
        border-color: $light-gray-mcui;
      }

      &:hover, &.active {
        background-image : linear-gradient(170.1deg, rgba(197, 52, 148, 0.1) -0.43%, rgba(59, 148, 163, 0.1) 97.4%), $white;
        border-color     : #DFE7ED;
      }

      &:hover input {
        ~ .checkmark {
          background-color : $white;
        }
      }

      input:checked {
        ~ .checkmark {
          background-color : $purple;
          border-color     : $purple;

          &:after {
            display : block;
          }
        }
      }
    }

    .checkmark {
      background : rgba(195, 209, 219, 0.1);
      border     : 1px solid #DFE7ED;
    }
  }

  &.list-view {
    .docSelector-doc {
      padding : 20px 20px 20px 64px;
      
    }
  }

  &.fourUp-view {
    .checkmark {
      top          : 15px;
      right        : 15px;
      left         : auto;
      margin-right : 0;
      transform    : translateY(0);
    }

    .docSelector-title {
      &:before {
        content       : url("/assets/images/whitepaper.png");
        display       : block;
        height        : 27px;
        width         : 20px;
        margin-bottom : 20px;
      }
    }

    .docSelector-doc {
      display       : inline-block;
      margin        : 3px;
      padding       : 20px;
      border        : 1px solid #DFE7ED;
      border-radius : 3px;
      flex-basis    : calc(50% - 6px);
    }

    .docSelector-list {
      display   : flex;
      flex-wrap : wrap;
    }
  }

  &.toolkit-view {
    .docSelector-doc{
      &:before {
        content   : url('/assets/images/whitepaper.png');
        position  : absolute;
        display   : inline-block;
        top       : 50%;
        height    : 27px;
        width     : 20px;
        transform : translateY(-50%);
      }
      &:hover {
        cursor: default;
        background-image: none;
      }
    }

    .docSelector-title {
      margin-left : 40px;
    }

    &.light {
      .docSelector-doc:hover {
        cursor           : inherit;
        background-image : none
      }
    }
  }

  //Added the Legacy List view
  &.listLegacy-view {
    .docSelector-list {
      columns: 1;
      width: 100%;
    }
    .content-label {
      display: inline;
      margin-right: 5px;
    }
    .docSelector-doc {
      padding: 20px 20px 20px 60px;
      // border-top: 1px solid rgba(225, 232, 237, 0.15);
      // border-bottom: 1px solid rgba(225, 232, 237, 0.15);
      border-top: 1px solid rgb(223, 231, 237);
      border-bottom: 1px solid rgb(223, 231, 237);
    }
    .docSelector-type {
      margin: 0 8px 0 2px;
    }
  }

  @include media-breakpoint-down(sm) {
    .docSelector-list {
      columns: 1;
    }
    .docSelector-header {
      padding        : 0 0 40px;
      align-items    : flex-start;
      flex-direction : column;

      .primary-heading, .supporting-copy {
        flex-basis : auto;
      }
    }

    .intro-copy {
      padding        : 0 0 20px 0;
      flex-direction : column;
    }

    //REVIEW IF WE NEED THIS
    .btn-primary {
      margin : 10px auto;
      
    }

    .container {
      padding : 40px 20px;
    }

    &.fourUp-view .docSelector-doc {
      flex-basis : auto;
    }
  }
}