
.image-module {
  padding : 70px 0;
  .container {
    display         : flex;

    justify-content : center;
    align-items     : center;
  }

  @include media-breakpoint-down(sm) {
  	img {
  		max-width: 100%;
  	}
  }
}