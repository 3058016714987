.contact-module {
  .container {
    display         : flex;
    padding         : 7em 0;
    justify-content : space-around;
  }

  .contact-copy {
    margin-right : 10%;
  }

  .primary-heading {
    margin-bottom : 30px;
  }

  .contact-methods {
    display     : flex;
    align-items : initial;

    .method {
      @include flamasemi-semibold;

      display          : flex;

      width            : 200px;
      margin           : 3px;
      padding          : 40px 0;
      background-color : #263240;
      font-size        : 15px;
      letter-spacing   : 0.03em;
      line-height      : 24px;
      text-align       : center;
      text-transform   : uppercase;
      border-color     : rgba(225, 232, 237, 0.15);
      border-style     : solid;
      border-width     : 1px;
      border-radius    : 3px;
      flex-direction   : column;
      align-items      : center;

      &:hover {
        background-image: linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
        border-color     : rgba(225, 232, 237, 0.25);
      }
      img {
        height        : 75px;
        width         : auto;  // Fixes janky image aspects
        margin-bottom : 35px;
      }
      .content-label {
        @include flama-basic;
        white-space : nowrap;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .contact-copy {
      margin-right : 2%;
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      padding        : 40px 20px 20px 20px ;
      flex-direction : column;
    }

    .primary-heading {
      margin-bottom : 15px;
    }

    .contact-copy {
      margin-right : 0;
    }

    .contact-methods {
      margin-top     : 20px;
      flex-direction : column;

      .method {
        width          : 100%;
        padding        : 20px;

        font-size      : 11px;
        line-height    : 24px;

        flex-direction : row;

        img {
          height : 40px;
          width  : 40px;
          margin : 0 20px 0 0;
        }
        .content-label {
          margin-left : 20px;
        }
      }
    }
  }
}

//Making sure the chat in contact is hidden - Remove once we start using the chat feature or another third card
.contact-methods a.method:nth-child(3) {
  display:none;
}