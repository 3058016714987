
.locations-carousel {
  .carousel-list {
    margin : 0 auto;
    
  }
  .carousel-item {    
    margin       : 0;
    border-right : 1px solid #4F5661;

    @include media-breakpoint-down(md) {
      margin : 0 5px;
    }

    &:last-of-type {
      margin-right : 0;
    }
 
    &:hover {
      .location-info-overlay {
        cursor  : pointer;
        opacity : 1;
      }
      .location-text {
        opacity : 0;
      }
    }

    .location-description, .location-info-overlay {
      height : 450px;
      width  : 100%;
    }

    .location-city {
      @include flamasemi-light;

      margin-bottom : 6px;

      font-size     : 26px;
      line-height   : 32px;
    }

    .location-country {
      @include flama-basic;

      font-size      : 10px;
      letter-spacing : 0.2em;
      line-height    : 10px;
      text-transform : uppercase;
    }

    .location-address, .location-phone {
      @include flamasemi-light;

      opacity     : 0.7;
      font-size   : 16px;
      color       : #FFFFFF;
      line-height : 24px;
    }

    .location-description {
      position         : relative;
      background-color : $teal;

      .location-photo {
        position         : relative;
        display          : flex;

        overflow         : hidden;
        height           : 100%;
        width            : 100%;

        background-color : $dark-charcoal;

        justify-content  : center;
        align-items      : center;

        img {
          @include transform(translateX(-50%));

          position : absolute;
          top      : 0;
          left     : 50%;

          height: auto;
          width: 100%;
        }
      }
      .location-text {
        position         : absolute;
        bottom           : 0;
        left             : 0;

        width            : 100%;
        padding          : 18px 30px 30px 30px;

        background-color : $dark-charcoal;
        color            : $white;

        transition       : all 300ms ease;
      }
    }

    .location-info-overlay {
      position         : absolute;
      display          : flex;
      top              : 0;
      left             : 0;

      padding          : 30px;

      opacity          : 0;
      background-color : rgba($dark-charcoal, 0.95);
      color            : $white;

      transition       : all 200ms ease;

      flex-direction   : column;
      justify-content  : flex-end;

      &:after {
        content    : '';

        position   : absolute;
        display    : block;
        bottom     : 0;
        left       : 0;

        height     : 5px;
        width      : 100%;

        background : linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
      }

      .location-country {
        margin-bottom : 20px;
      }

      .location-phone {
        position     : relative;

        margin       : 10px 0;
        padding-left : 20px;

        &:before {
          @include transform(translatey(-50%));
          content           : '';
          position          : absolute;
          display           : block;
          top               : 50%;
          left              : 0;

          height            : 12px;
          width             : 12px;
          background-image  : url('/assets/images/phone-small.png');
          background-repeat : no-repeat;
          background-size   : 12px 11.12px;
        }
      }

      .cta-container.text-only {
        color: $white;

        .cta-text {
          display             : inline-block;
          border-bottom-color : rgba($white, 0.1);
        }
        svg path {
          fill : $white;
        }

        &:hover, &:hover {
          color: $white;
        }
      }
    }
  }
} 