
.gradient-border {
  padding      : 0 30px;
  border-width : 0;
  border-image : $gradient-top-bottom;
  border-left  : 4px solid;

  @include media-breakpoint-down(md) {
    padding           : 0 20px;
    border-left-width : 4px;
  }
}

.gradient-bg {
  background-color : $purple;
  background-image : $gradient-list;
  color            : $white;

  .primary-heading, a {
    color : $white;
  }
  .supporting-copy {
    color : $white;
  }
}

.gradient-overlay {
  position   : absolute;
  display    : block;
  top        : 0;
  left       : 0;
  height     : 100%;
  width      : 100%;
  opacity    : 0.8;
  background : linear-gradient(71.94deg, #202C3A 0%, rgba(123, 38, 108, 0.1) 68.4%);
}

.light {
  background-color : $white;
  color            : $dark-charcoal;

  .primary-heading, .hero-heading {
    color : $dark-teal;
  }

  // a:not(.btn) {
  //   color: $dark-purple;
  //   &:hover {
  //     color: $purple;
  //   }
  // }

  .supporting-copy {
    color : $charcoal;
  }

  .object-background {
    background-color: $light-gray-web;
  }

  .table-cell-heading {
    color: $mid-gray;
  }
}

.neutral {
  background-color : $light-gray-web;
  color            : $dark-charcoal;

  .primary-heading .hero-heading {
    color : $dark-teal;
  }

  // a:not(.btn) {
  //   color: $dark-purple;
  //   &:hover {
  //     color: $purple;
  //   }
  // }

  .supporting-copy {
    color : $charcoal;
  }

  .object-background {
    background-color: $almost-white;
  }

  .table-cell-heading {
    color: $dark-teal;
  }
}

.dark {
  background-color : $dark-charcoal;
  color            : $white;

  .primary-heading, a:not(.btn) {
    color : $white;
  }

  .secondary-heading, .content-label {
    color : $white;
  }

  .featured-content {
    & > a {
      .secondary-heading, .content-label {
        color: $white;
      }
    }
  }

  a:not(.btn):hover {
    color   :  $almost-white;
  }

  .supporting-copy {
    color   : $almost-white;
  }

  .object-background {
    background-color: $dark-charcoal;
  }

  .table-cell-heading {
    color: $white;
  }
}

// importants used here to overwrite styles, these shouldn't get used a lot  
.bg-light-gray {
  background-color : $light-gray !important;
}

.bg-white {
  background-color : $white !important;
}

.bg-dark-charcoal {
  background-color : $dark-charcoal !important;
}