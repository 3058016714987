
//MasterControl Custom Variables

//Official MasterControl Colors
$teal                : #3B94A3;
$purple              : #C53494;
$magenta             : #C53494; // THIS IS THE PURPLE VARIABLE NEED TO REFACTOR WHERE THIS IS USED
$dark-teal           : #164A62;
$dark-purple         : #7B266C;
$red                 : #D01E51;
$orange              : #E65B2B;
$yellow              : #FFCC67;
$light-green         : #C9DC5E;
$green               : #79B75B;
$white               : #FFFFFF;
$light-gray-mcui     : #C3C5C8;
$light-gray          : #F6F8F9; //Needs to be #C3C5C8 to be official color - need to refactor
$mid-gray            : #8A8B8C;
$dark-gray           : #4E4E50;
$black               : #000000;
$primary-color       : $teal;
$secondary-color     : $purple;

//Web Only Supplement Colors WE NEED TO REVIEW THESE AND SEE IF THE OFFICIAL COLORS CAN BE SUBSTITUTED

$light-gray-web      : #F6F8F9; // This can be the light gray color that refactors the other light gray above.
$almost-white        : #D6DFE3; // Let's review this color
$light-teal          : #52C0D3;
$charcoal            : #3D4F63;
$dark-charcoal       : #202C3A;

// Gradients

$purple-to-teal      : linear-gradient(90deg, $purple 0%, $teal 100%);
$gradient-corner     : linear-gradient(135deg, $purple 0%, $light-teal 100%);
$gradient-top-bottom : linear-gradient($light-teal, $purple) 0 100%;
$gradient-list       : linear-gradient(162.03deg, $purple -0.43%, $teal 97.4%);

//Add a btn hover color to override the default REFACTOR THIS LATER


//Triangle size for Hero and PPC
$triangle-size-sm	:	40px;
$triangle-size-md	:	50px;
$triangle-size-lg	:	70px;