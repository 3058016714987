
$round-button-size : 60px;

.btn {
  padding: 14px 45px;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  border-radius: 100px;
}
.btn:hover {
  cursor: pointer;
  color : inherit;
}
.btn-sm {
  padding: 10px 20px;
}
.btn-xs {
  padding: 5px 15px;
}

.btn-primary, a.btn-primary {
  @include flamasemi-basic;
  opacity           : 1;
  background-color  : $teal;
  background-image  : linear-gradient(to right, $purple , $teal);
  background-repeat : no-repeat;
  background-size   : 105%;
  color             : $white;
  transition        : all 300ms ease-out;

  &:hover, 
  &:active, 
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    cursor              : pointer;
    background-color    : $teal;
    background-position : -500px 0;
    color               : $white;
    border              : 0;
    box-shadow          : none;
  }
  &.disabled {
    background-image  : linear-gradient(to right, $light-gray-mcui, $light-gray-mcui);
  }
  &.stroked {
    background-clip: padding-box;
    border-radius: 40px;
    box-sizing: border-box;
    color: $dark-gray;
    display: block;
    margin: 0 auto;
    padding: 3px;
    position: relative;
    height: 50px;
    z-index: 2;

    & span {
      align-items: center;
      background: $white;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      height: 100%;
      transition: background .5s ease;
      width: 100%;
      padding: 0 40px
    }
    &:hover span, &:active span {
      color: $white;
      background: transparent;
    }
  }
}

.btn-secondary {
  @include flamasemi-basic;
  opacity           : 1;
  background-color  : $teal;
  color             : $white;
  transition        : all 300ms ease-out;

  &:hover, 
  &:active, 
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    cursor              : pointer;
    background-color  : $dark-teal;
    color               : $white;
    border              : 0;
    box-shadow          : none;
  }
}

.btn-slate {
  @include flamasemi-basic;
  opacity           : 1;
  background-color  : $dark-gray;
  background-image  : linear-gradient(to right, $mid-gray , $dark-gray);
  background-repeat : no-repeat;
  background-size   : 105%;
  color             : $white;
  transition        : all 300ms ease-out;

  &:hover {
    background-color    : $dark-gray;
    background-position : -275px 0;
    color               : $white;
  }
}

.btn-white {
  @include flamasemi-basic;
  display          : inline-block;
  opacity          : 1;
  background-color : $white;
  font-size        : 14px;
  color            : $magenta;
  line-height      : 24px;
  border-radius    : 100px;
  transition       : all 300ms ease-out;
  border: 0;

  &:hover, 
  &:active, 
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    cursor              : pointer;
    background-color  : $teal;
      color             : $white;
  }
}



.btn-default {
  @include flamasemi-basic;
  display          : inline-block;
  opacity          : 1;
  background-color : $light-gray-mcui;
  font-size        : 14px;
  color            : $black;
  line-height      : 24px;
  border-radius    : 100px;
  transition       : all 300ms ease-out;
  border: 0;

  &:hover, 
  &:active, 
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    cursor              : pointer;
    background-color  : $dark-gray;
      color             : $white;
  }
}

.btn .badge, span.badge {
  background-color: $teal;
  margin-left: 5px;
  border-radius: 10px;
  color: $white;
}

.btn-mobile-fixed {
  position          : fixed;
  bottom            : 0;
  left              : 0;
  width             : 100vw;
  padding           : 14px 45px;
  background-color  : $teal;
  background-image  : linear-gradient(to right, $purple , $teal);
  background-repeat : no-repeat;
  background-size   : 105%;
  font-size         : 14px;
  color             : $white;
  line-height       : 24px;
  border            : 0;
  transition        : all 300ms ease-out;
}

.circle-button, .dark .light .circle-button {
  position            : relative;
  display             : flex;
  height              : $round-button-size;
  width               : $round-button-size;
  margin              : 0;
  background-color    : $light-gray-web;
  border              : 1px solid  $almost-white;
  border-radius       : 100px;
  transition          : all 200ms ease;
  backface-visibility : hidden;
  justify-content     : center;
  align-items         : center;
  flex                : none;

  &:before {
    content          : '';
    position         : absolute;
    z-index          : 5;
    height           : $round-button-size;
    width            : $round-button-size;
    opacity          : 0;
    background-color : #C53494;
    border-radius    : 100%;
    transition       : all 200ms ease-out;
    transform        : scale(0.01);
  }

  svg {
    z-index : 10;
    path {
      transition : all 200ms ease-out;
      fill       : $purple;
    }
  }

  &:hover {
    cursor : pointer;
    border : 1px solid transparent;

    &:before {
      opacity   : 1;
      transform : scale(1);
    }
    svg {
      path {
        fill : $white;
      }
    }
  }
}

.play-button {
  position          : absolute;
  display           : flex;
  top               : 50%;
  left              : 50%;
  height            : $round-button-size;
  width             : $round-button-size;
  margin            : 0;
  background        : $purple;
  border            : 1px solid $light-gray-mcui;
  border-radius     : 100px;
  transition        : all 200ms ease;
  -webkit-transform : translate(-50%, -50%);
  transform         : translate(-50%, -50%);
  justify-content   : center;
  align-items       : center;
  flex              : none;

  span {
    display : none;
  }

  &:before {
    content          : '';
    position         : absolute;
    z-index          : 5;
    height           : $round-button-size;
    width            : $round-button-size;
    opacity          : 0;
    background-color : $white;
    border-radius    : 100%;
    z-index          : 0;
    transition       : all 200ms ease-out;
    transform        : scale(0.01);
  }

  svg {
    z-index : 10;

    path {
      transition : all 200ms ease-out;
      z-index: 100;
      fill : $white;
    }
  }

  &:hover {
    cursor           : pointer;
    background-color : transparent;
    border           : 1px solid transparent;

    svg {
      path {
        fill: $purple;
      }
    }

    &:before {
      opacity   : 1;
      transform : scale(1);
    }
  }
}

a:hover .play-button {
  cursor           : pointer;
  background-color : transparent;
  border           : 1px solid transparent;
  svg {
    path {
      fill: $purple;
    }
  }
  &:before {
    opacity   : 1;
    transform : scale(1);
  }
}

.dark {
  .circle-button {
    background-color : #263240;
    border           : 1px solid  rgba(225, 232, 237, 0.15);

    svg path {
      fill : $white;
    }
    &:hover {
      background-color : transparent;
      border           : 1px solid $white;

      svg path {
        fill : $white;
      }
    }
  }
}