
.form-check-label {
  position            : relative;
  display             : block;
  padding-left        : 35px;
  cursor              : pointer;
  font-size           : 1.2rem;
  line-height         : 25px;
  -webkit-user-select : none;
  -moz-user-select    : none;
  -ms-user-select     : none;
  user-select         : none;
}

// Hide the browser's default checkbox
.form-check-label input {
  position : absolute;
  height   : 0;
  width    : 0;
  cursor   : pointer;
  opacity  : 0;
}

// Create a custom checkbox
.checkmark {
  position         : absolute;
  top              : 0;
  left             : 0;
  height           : 25px;
  width            : 25px;
  background-color : transparent;
  border           : 1px solid #E1E8ED;
  border-radius    : 3px;
  transition       : all 200ms ease;
}

// On mouse-over, add a grey background color
.form-check-label:hover input ~ .checkmark {
  background-color : #E1E8ED;
}

// When the checkbox is checked, add a blue background
.form-check-label input:checked ~ .checkmark {
  background-color : #202C3A;
  border-color     : #202C3A;
}

// Create the checkmark/indicator (hidden when not checked)
.checkmark:after {
  content  : "";
  position : absolute;
  display  : none;
}

// Show the checkmark when checked
.form-check-label input:checked ~ .checkmark:after {
  display : block;
}

// Style the checkmark/indicator
.form-check-label .checkmark:after {
  top               : 5.7px;
  left              : 8.2px;
  height            : 9px;
  width             : 6px;
  border            : solid white;
  border-width      : 0 3px 3px 0;
  -webkit-transform : rotate(45deg);
  -ms-transform     : rotate(45deg);
  transform         : rotate(45deg);
}

.dark {
  .checkmark {
    background-color : rgba(255, 255, 255, 0.05);
    border-color     : rgba(225, 232, 237, 0.3);
  }

  .form-check-label:hover input ~ .checkmark {
    background-color : rgba(225, 232, 237, 0.3);
    border-color     : rgba(255, 255, 255, 0.05);
  }

  .form-check-label input:checked ~ .checkmark {
    background-color : $purple;
    border-color     : $purple;
  }
}