// Global Narration Cards
.narrationCards {
  padding: 30px 0;
  @include media-breakpoint-up(md) {
    padding: 45px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 60px 0;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
  }

  p, blockquote {
    margin-bottom: 0;
    width: 100%;
  }

  .card-wrapper {
    margin-top: 10px;

    .col {
      flex-basis: auto; // Replace the bootstrap flex basis for IE
      margin-bottom: 15px;
    
      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  .card { 
    padding: 30px;
    border-radius: 5px;
    align-items: flex-start;
    height: 100%;
  }

  &.light {

    h3 {
      color: $teal;
    }

    .card {
      border: 1px solid rgba(78,78,80,0.15); 
    }
  }

  &.neutral {
   
    h3 {
      color: $teal;
    }

    .card {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
      border: 0;
    }
  }

  &.dark {
   
    h3, h4 {
      color: $white;
    }

    .card {
      background-color: #263240;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
      border: 1px solid rgba(214,223,227,0.15); 
    }
  }
}

// Narration Cards: Info
.narrationCards-info {

  .icon {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
      width: 80px;
      height: 80px;
    }

    img {
      flex: 0 0 auto; // For IE
    }
  }

  h4 {
    font-size: 22px;
    line-height: 30px;
    width: 100%; // For IE
  }
}

// Narration Cards: Quote
.narrationCards-quote {

  .quotemark {
    font-size: 80px;
    color: $purple;
    margin: 10px 0 0 -15px;
  }

  blockquote {
    @include flamasemi-book;
    font-size: 28px;
    line-height: 33px;
  }

  .rule {
    width: 33.333%;
    height: 2px;
    margin: 20px 0;
    background: $purple-to-teal; 
  }

  .author {
    @include flamasemi-semibold;
    font-size: 16px;
  }

  .logo {
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
      height: 60px;
    }

    img {
      max-height: 60px;
      max-width: 120px;
    }
  }
}

// Narration Cards: HCBI
.narrationCards-HCBI {

  .card {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    h3,h4 {
      font-size: 32px;
      line-height: 36px;
      @include flamasemi-light;
    }

    p {
      font-size: 28px;
      line-height: 32px;
      @include flamasemi-light;
    }

    .btn {
      margin-top: 50px;
    }
  }

  h1,h2,h3,h4,h5,h6,p {
    color: $white;
  }
}

// Narration Cards: Links
.narrationCards-links {
  
  h4 {
    color: #164A62;
    font-size: 22px;
    line-height: 30px;
  }

  .card {

    h4 {
      margin-top: 30px;
    }

    .icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 30px;
      right:30px;

      img {
        max-width: 100%;
        max-height: 50px;
      }
    }

    .cta-container {
      margin-top: auto;

      .cta-text {
        color: $purple;
        border-bottom: 1px solid $purple;
      }
    }
  }

  &.dark {

    .card {

      .cta-container {

        .cta-text {
          color: $white;
        }
      }
    }
  }
}