.contact-us-thanks {
  strong {
    @include flamasemi-semibold;
  }

  .hero-background {
    background-image: linear-gradient(to right, $dark-purple, $red);
  }

  .hero {
    background-image: url('/assets/images/boxes-white.svg');
    background-repeat: no-repeat;
    background-position: 180% center;
    background-size: 70% 110%;
    margin: 65px 0 30px;
    padding: 30px 15px;
    
    h1, h2 {
      color: $white;
      text-align: center; 
    }

    h2 {
      margin-top: 30px;
    }
    
    a, a:hover, a:focus {
      color: $white;
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      padding: 100px 0 20px;
      background-position: 120% center;
      background-size: contain;

      h1, h2 {
        text-align: left;
      }

      h2 {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      background-position: 100% center;
      background-size: 30% 120%;
    }
  }

  .mediaGrid {
    padding: 0;

    h3 {
      color: $dark-teal;
      margin-top: 50px;
    }

    .content-grid {
      margin-top: 10px;
      margin-bottom: 50px;

      .large.card {  

        .image-container {
          height: 300px;
        }
      }
    }
  }
}