
html {
  font-size : 10px; // This is what sets the rems - Need to rethink this
}

p, .default {
  // font-size:16px; // Moved to typography
}

html, body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color : $dark-teal;
}

body {
  // margin-top: 70px; // This gets added by JS because it does not need to be on all pages
  @include media-breakpoint-down(md) {
    // margin-top: 50px; // This gets added by JS because it does not need to be on all pages
  }
  @include flama-basic; // Moved to typography
  a {
    color           : $dark-purple;
    text-decoration : none;
    transition      : color 200ms ease;

    &:hover {
      color           : $purple;
      text-decoration : none;
    }
    &:visited:not(.btn) {
      color           : $dark-teal;
      text-decoration : none;
    }
    p {
      color: $dark-teal;
    }
  }
  
  // THIS IS THE "a" tag LINK ANIMATION
  p, dl dd {
    a:not(.btn) {
      text-decoration: underline;
      z-index: 1;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: -0.25em;
        right: -0.25em;
        background-color: rgba($teal, 0.15);
        transform-origin: center center;
        transform: scaleX(0);
        transition: transform 0.2s ease-in-out;
      }

      &:hover::before {
        transform: scaleX(1);
        transform-origin: center center;
      }

      &.btn {
        text-decoration: none;
        color: $white;
      }
      & * {
        text-decoration: none;
      }
    }
  } 
}

//Fixes safari row flex issue
@media not all and (min-resolution:.001dpcm) {
  .row:before, .row:after{
   display: inline-block !important;
   content: normal;
  }
  //Removes ugly focus state and adds faint gray outline
  a:focus {
    outline: 1px solid rgba(78,78,80,0.1)
  }
}
//Targets iOS devices to fix weird row issue
@supports (-webkit-overflow-scrolling: touch) {
  .row:before, .row:after {
    display: none !important;
  }
}
//Fixes phone numbers on iOS
a[href^="tel"] {
  color: inherit;
}

img[src*="terminus"] {
  display: none;
}

.hidden {
  display:none;
}

hr {
  height           : 1px;
  width            : 100%;

  background-image : linear-gradient(150.36deg, $purple -10.21%, #3B94A3 124.46%);
  border           : 0;
}

svg, path, line {
  backface-visibility: hidden;
}

ul {
  list-style-type: square;
}