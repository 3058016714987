
.footer {
  padding          : 0;
  background-color : $light-gray-web;

  ul {
    padding-left : 0;
    list-style   : none;
  }

  .container {
    padding : 0;
  }

  .top-section {
    display         : flex;

    justify-content : space-around;
    flex-direction  : row;

    .site-links, .social {
      margin : 15px 20px;
    }

    .lets-chat-container {
      position   : relative;
      padding    : 60px 60px 80px 60px;
      background : $purple;
      background : linear-gradient(145deg, $purple 0%, $teal 100%);
      color      : $white;

      &:before {
        content      : '';
        position     : absolute;
        display      : block;
        right        : 0;
        bottom       : 0;
        height       : 0;
        width        : 0;
        border-color : transparent $light-gray-web transparent transparent;
        border-style : solid;
        border-width : 60px 60px 0 0;
      }

      .lets-chat {
        @include flamasemi-ultralight;
        padding-bottom : 20px;
        font-size      : 40px;
        line-height    : 48px;
      }

      .phone {
        @include flamasemi-ultralight;
        display     : flex;
        margin      : 7px 0;
        font-size   : 18px;
        line-height : 24px;
        letter-spacing: 0.1em;
        align-items : center;

        span:first-of-type {
          @include flama-semibold;
          display        : inline-block;
          margin         : 2px 15px 0 0;
          font-size      : 16px;
          letter-spacing : 0.2em;
          line-height    : 14px;
          text-transform : uppercase;
        }
      }

      .btn {
        @include flamasemi-semibold;
        color        : $dark-teal;
        margin-top   : 20px;
        width        : 100%;
        &:hover{
          color      : $white;
        }
      }

      .email  {
        display         : block;
        opacity         : 1;
        font-size       : 16px;
        color           : $white;
        line-height     : 24px;
        text-decoration : none;
        text-decoration : underline;
        transition      : opacity 200ms ease;

        a {
          color: $white;
        }

        &:hover {
          opacity : 0.8;
        }
      }
    }

    .site-links {
      display         : flex;
      margin          : 85px 20px 0;
      flex-direction  : row;
      justify-content : space-evenly;
      flex            : auto;

      .site-links-group {
        .site-links-group-title {
          @include flama-basic;
          margin         : 0 0 15px 0;
          font-size      : 12px;
          color          : $dark-teal;
          letter-spacing : 0.2em;
          line-height    : 14px;
          text-transform : uppercase;
        }
        ul.site-links-group-list {
          display       : block;
          margin-bottom : 0;

          li {
            a {
              opacity         : 0.6;
              font-size       : 12px;
              color           : $charcoal;
              line-height     : 28px;
              text-decoration : none;

              &:hover {
                opacity : 1;
              }
            }
          }
        }
      }
    }

    .social {
      display         : flex;
      margin          : 85px 0 0 0;
      justify-content : flex-end;
      align-items     : flex-start;

      a {
        display       : inline-block;
        margin        : 0 10px;
        margin-bottom : 0;

        svg path {
          transition : fill 200ms ease-out;
          fill       : $dark-teal;
        }
        &:hover {
          svg.facebook path {
            fill : #3b5d9a;
          }

          svg.twitter path {
            fill : #1ea4f0;
          }

          svg.linkedin path {
            fill : #3b5d9a;
          }

          svg.pinterest path {
            fill : #e40027;
          }
        }
      }
    }
  }

  .bottom-section {
    display         : flex;
    padding         : 75px 0 40px 0;
    align-items     : flex-end;
    justify-content : space-between;

    .address, .copyright, .legal {
      margin : 15px 20px;
    }

    .address {
      display         : flex;
      margin          : 0;
      justify-content : start;
      order           : 1;

      svg {
        height : 42px;
        width  : 83px;
        margin : 0 30px 0 0;

        path {
          fill : #3B94A3;
        }
      }
      div {
        opacity     : 0.7;
        font-size   : 12px;
        color       : $dark-teal;
        line-height : 20px;

        a {
          display         : block;
          margin          : 0;
          font-size       : 12px;
          color           : $purple;
          text-decoration : underline;
        }
      }
    }

    .legal, .copyright {
      font-size : 12px;
    }

    .legal {
      display         : flex;

      margin          : 0;

      justify-content : end;
      order           : 3;

      ul {
        margin : 0;
        li {
          display : inline-block;
          margin  : 15px 0 0 40px ;

          a {
            opacity : 0.6;
            color   : $charcoal;
          }
        }
      }
    }

    .copyright {
      margin     : 0;
      opacity    : 0.6;
      color      : $charcoal;
      text-align : center;
      flex       : auto;
      order      : 2;
    }
  }
}
//desktop styles 
@include media-breakpoint-down(md) {
  .footer {
    .top-section {
      flex-direction : column;

      .lets-chat-container {
        padding : 40px 20px 45px;

        .lets-chat {
          padding-bottom : 20px;
          font-size      : 26px;
          line-height    : 32px;
        }
        .phone {
          display     : block;
          margin      : 7px 0;
          font-size   : 18px;
          line-height : 24px;

          span, span:first-of-type {
            display : block;
          }
        }
        .btn{
          width: auto;
        }
      }

      .site-links {
        margin-top     : 30px;
        flex-direction : row;
        justify-content : space-between;

        .site-links-group {
          width: 33%;
          padding: 5px;

          .site-links-group-title {
            margin : 15px 0;
            &:hover {
              cursor : pointer;
            }
          }
        }
      }

      .social{
        margin          : 30px 20px 25px;
        justify-content : space-around;

        a {
          margin : 0;
        }
      }
    }
    .bottom-section {
      padding        : 25px 15px ;

      flex-direction : column;
      align-items    : flex-start;

      .address {
        flex-direction : column;

        svg {
          height        : 26px;
          width         : 52px;
          margin-bottom : 25px;
        }
        div {
          margin-bottom : 8px;
          a {
            display : block;
            margin  : 15px 0 0 0;
          }
        }
      }

      .legal {
        display         : flex;

        justify-content : end;
        order           : 2;

        ul {
          margin : 0;
          li {
            display : inline-block;
            margin  : 5px 40px 5px 0;

            a {
              opacity              : 0.6;
              color                : $charcoal;
              text-decoration-line : underline;
            }
          }
        }
      }

      .copyright {
        margin-top : 15px;
        opacity    : 0.6;
        color      : $charcoal;
        order      : 3;
      }
    }
  }
}