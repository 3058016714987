//Narr-Generic
.narration-longForm, .single-page-content {
  max-width: 1140px;
  width: 100%;
  padding:40px 5%;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  font-family:'Flama Book', sans-serif;

  h2, h3 {
    font-family: 'FlamaSemicondensed Book', sans-serif;
    border-image: linear-gradient(#52C0D3, #C53494) 0 100%;
    border-left: 6px solid;
    padding-left: 15px;
    font-size: 30px;
    color: #164A62;
    margin: 30px 0 15px 0;
    line-height:1.3em;
  }
  strong {
    font-family: 'flamasemi-bold', sans-serif;
    font-weight: 700;
  }
  p {
    font-family:'Flama Book', sans-serif;
  }
  ul, ol {
    column-count:2;
    column-gap:40px;
    padding: 15px 30px 20px 40px;
    background-color: #F6F8F9;

    li {
      margin-bottom: 15px;
    }

    li > ul, & > ul {
      column-count:1;
      list-style-type: circle;
    }

    @media screen and (max-width:767.98px) {
      column-count:1;
      column-gap:normal;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  // a {
  //   text-decoration: underline;
  //   color: $teal;
  //   z-index: 1;
  //   position: relative;

  //   &::before {
  //     content: '';
  //     position: absolute;
  //     z-index: -1;
  //     top: 0;
  //     bottom: 0;
  //     left: -0.25em;
  //     right: -0.25em;
  //     background-color: rgba($teal, 0.15);
  //     transform-origin: center center;
  //     transform: scaleX(0);
  //     transition: transform 0.2s ease-in-out;
  //   }

  //   &:hover {
  //     text-decoration: underline;
  //     color: $teal;
  //   }

  //   &:hover::before {
  //     transform: scaleX(1);
  //     transform-origin: center center;
  //   }

  //   &.btn {
  //     text-decoration: none;
  //     color: $white;
  //   }
  // }

  &.dark {
    h2, h3 {
      color: $white;
    }
  }

}