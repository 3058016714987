
.about-us {
  &.dark{
    .featured-event-title {

      &:after {
        content      : '';
        position     : absolute;
        top          : 0;
        right        : 0;
        height       : 0;
        width        : 0;
        border-color : #202C3A transparent transparent transparent;
        border-style : solid;
        border-width : 50px 0 0 50px;
      }
      .secondary-heading {
        color: $charcoal;
      }
    }
  }
  .gradient-carousel {
    .primary-heading {
      flex-basis : auto;
      flex-grow  : 1;
    }
  }
  
  .featured-event-title-container, .featured-content {
    max-width : 1366px;
  }

  .featured-event-title {
    position         : relative;
    display          : flex;
    margin           : 0;
    padding          : 40px 60px;
    background-color : #F6F8F9;
    justify-content  : space-between;
    align-items      : center;

    &:after {
      content      : '';
      position     : absolute;
      top          : 0;
      right        : 0;
      height       : 0;
      width        : 0;
      border-color : #ffffff transparent transparent transparent;
      border-style : solid;
      border-width : 50px 0 0 50px;
    }

    @include media-breakpoint-down(sm) {
      &:after {
        border-width : 40px 0 0 40px;
      }
    }

    .secondary-heading {
      margin      : 0;
      line-height : normal;
    }

    .cta-container {
      margin  : 0;
      padding : 0;

      &.text-only .cta-text {
        color : $magenta;
      }
    }

    @include media-breakpoint-down(sm) {
      padding : 30px 50px 30px 30px;
    }
  }

  .featured-content-wrapper {
    border-top    : 1px solid #D6DFE3;
    border-bottom : 1px solid #D6DFE3;
  }
};