
.cta-container {
  margin-top : 40px;
  padding    : 0;

  .cta {
    display         : flex;
    text-decoration : none;
    align-items     : flex-start;
  }

  .cta-text-group {
    margin : 0 22px;
  }

  .content-label {
    margin-bottom : 4px;
    color         : $purple;
  }

  &:hover {
    .circle-button {
      border : 1px solid $purple;

      &:before {
        opacity   : 1;
        transform : scale(1);
      }
      svg {
        path {
          fill : $white;
        }
      }
    }
  }

  &.text-only {
    padding : 10px 0;

    .cta-text {
      margin-right   : 10px;
      padding-top    : 10px;
      padding-bottom : 10px;
      border-bottom  : 1px solid rgba($purple, 0.1);
      transition     : all 200ms ease;

      svg {
        margin-left : 20px;
        transition  : transform 200ms;
        transform   : translateX(0px);
      }
      &:hover {
        margin-right  : 0px;
        padding-right : 10px;
        cursor        : pointer;
        svg {
          transform : translateX(10px);
        }
      }
    }
    svg path {
      fill : $purple;
    }
  }

  &.action {
    &:before {
      content          : '';
      display          : block;
      height           : 2px;
      width            : 100%;
      background-color : $light-gray-web;
    }
    .cta {
      padding : 22px;
    }
  }

  &.gradient-top {
    &:before {
      height     : 1px;
      background : $purple-to-teal;
    }
  }

  &.watch {
    .cta-text-group {
      margin : 0;
    }

    .cta {
      position     : relative;
      padding-left : 100px;
    }

    .promo-image {
      position : absolute;
      display  : block;
      top      : 22px;
      left     : 0;
      height   : 55px;
      width    : 77px;

      img {
        width: 100%;
      }

      &:before, &:after {
        content  : '';
        position : absolute;
      }

      &:before {
        top        : 0;
        left       : 0;
        height     : 47px;
        width      : 77px;
        opacity    : 0.5;
        background : linear-gradient(144.46deg, #C53494 -0.43%, #3B94A3 97.4%);
        transition : opacity 250ms;
      }

      &:after {
        top          : 50%;
        left         : 50%;
        height       : 0;
        width        : 0;
        border-color : transparent transparent transparent #FFFFFF;
        border-style : solid;
        border-width : 4.5px 0 4.5px 9px;
        transform    : translate(-50%, -50%);
      }
    }

    &:hover {
      .promo-image:before {
        opacity : 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.action {
      .cta {
        padding : 22px 0;
      }
    }
    &.watch {
      .cta-text-group {
        margin-left: 90px;
      }
    }
    &.dark-mobile-only {
      background-color : $dark-charcoal;

      &:before {
        display : none;
      }

      &:after {
        content    : '';
        display    : block;
        height     : 3px;
        width      : 100%;
        background : linear-gradient(90deg, $purple 0%, #3B94A3 97.93%);
      }

      .content-label {
        opacity : 0.5;
        color   : $white;
      }

      .cta-text {
        color : $white;
      }

      .circle-button {
        background : #263240;
        border     : 1px solid rgba(225, 232, 237, 0.15);

        svg path {
          fill : $white;
        }
      }
    }
  }
}

.cta-container {
  a.cta-text, a.cta {
    color: $dark-teal;
    &:hover {
      color: $teal;
    }
    &:visited {
      color: $dark-teal;
    }
  }
}

.dark {
  .cta-container {
    .content-label {
      opacity : 0.5;
      color   : $white;
    }

    &.text-only {
      .cta-text {
        border-bottom-color : $purple;
        color : $white;
      }
    }

    &.action {
      &:before, &:after  {
        content          : '';
        display          : block;
        height           : 2px;
        width            : 100%;
        opacity          : 0.3;
        background-color : #6A6A6A;
      }
    }

    &:hover {
      .circle-button {
        background-color : transparent;
        border           : 1px solid transparent;

        svg path {
          fill : $white;
        }
      }
    }
  }
}