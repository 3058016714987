
.bottom-content {
  .primary-heading, .supporting-copy {
    margin-bottom : 20px;
    width:100%;
  }

  img {
    max-width : 100%;
  }

  .image-background {
    min-height          : 300px;

    background-position : center center;
    background-size     : cover;
  }

  .about {
    display         : flex;
    padding         : 30px 35px;
    justify-content : center;
    flex-direction  : column;
    align-items     : flex-start;

    @include media-breakpoint-up(md) {
      padding         : 80px 60px;
    }

    @include media-breakpoint-only(xl) {
      max-width : calc(1140px / 2);
    }
    @include media-breakpoint-only(lg) {
      max-width : calc(960px / 2);
    }
  }
}