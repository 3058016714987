//subscribe module for subscription
.subscribe-module, .subscribe-iframe {
  font-size: 14px;

  &.dark {
    select.form-control, .form-control {
      background    : rgba(255, 255, 255, 0.05);
      color         : $white;
      border        : 1px solid rgba(225, 232, 237, 0.3);
      &:focus {
        background-color : transparent;
        color            : #fff;
      }
      option {
        background-color:#202C3A;
      }
    }
  }
  select.form-control, .form-control {
    @include flama-book;
    cursor        : pointer;
    box-sizing    : border-box;
    padding       : 12px !important;
    font-size     : 1.2rem;
    line-height   : 2;
    border        : 1px solid rgba(225, 232, 237, 0.9);
    border-radius : 0px;

    &:focus {
      background-color : inherit;
      border-color     : rgba($purple, 0.3);
      box-shadow       : 0 0 0 0.1rem rgba($purple, 0.3);
      outline          : 0;
    }
  }
  label[for=WebFormComments], #WebFormComments {
    display:none;
  }
}

.contact-form-cn {
    .form-group {
        position: relative;
        margin-bottom: 2.4rem;
    }

    .control-label {
        font-size: 1.5rem;
        color: inherit;
    }

    select::-ms-expand {
        display: none;
    }

    select.form-control, .form-control {
        @include flama-book;
        box-sizing: border-box;
        font-size: 1.5rem;
        color: inherit;
        line-height: 2;
        border: 1px solid rgba(225, 232, 237, 0.9);
        border-radius: 0px;

        &:focus {
            color: inherit;
            border-color: rgba($purple, 0.3);
            box-shadow: 0 0 0 0.1rem rgba($purple, 0.3);
            outline: 0;
        }
    }

    .select-wrapper {
        position: relative;
    }

    select {
        appearance: none;
        padding-right: 15px;
        padding-left: 15px;
        transition: padding 250ms;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
            box-shadow: none;
        }
    }

    .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
        height: 50px;
    }

    .select-button {
        position: absolute;
        top: calc(50% - 3px);
        right: 14px;
        height: 6px;
        width: 9px;
        opacity: 0.4;
        border-color: $dark-teal transparent transparent transparent;
        border-style: solid;
        border-width: 6px 4.5px 0 4.5px;
        pointer-events: none;
    }

    .focused {

        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            bottom: 0px;
            background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
        }
    }

    .error-message {
        position: absolute;
        left: 2px;
        bottom: 8px;
        font-family: Flama;
        line-height: 16px;
        font-size: 10px;
        color: #D01E51;
        opacity: 1;
        transition: opacity 250ms;

        &.d-none {
            opacity: 0;
        }
    }

    #mainError {
        bottom: -40px;
        font-size: 16px;
        left: 15px;
    }

    &.light {
        color: $dark-teal !important;
    }
}

// Contact form fields
.contact-form {

  .form-group {
    position      : relative;
    margin-bottom : 2.4rem;
  }

  .control-label {
    font-size: 1.5rem;
    color: inherit;
  }

  select::-ms-expand {
    display: none;
  }

  select.form-control, .form-control {
    @include flama-book;
    box-sizing    : border-box;
    font-size     : 1.5rem;
    color         : inherit;
    line-height   : 2;
    border        : 1px solid rgba(225, 232, 237, 0.9);
    border-radius : 0px;

    &:focus {
      color            : inherit;
      border-color     : rgba($purple, 0.3);
      box-shadow       : 0 0 0 0.1rem rgba($purple, 0.3);
      outline          : 0;
    }
  }

  .select-wrapper {
    position: relative;
  }

  select {
    appearance         : none;
    padding-right      : 15px;
    padding-left       : 15px;
    transition         : padding 250ms;
    -webkit-appearance : none;
    -moz-appearance    : none;

    &:focus {
      box-shadow : none;
    }
  }

  .input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]), .input-group-lg>select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
    height : 50px;
  }

  .select-button {
    position       : absolute;
    top            : calc(50% - 3px);
    right          : 14px;
    height         : 6px;
    width          : 9px;
    opacity        : 0.4;
    border-color   : $dark-teal transparent transparent transparent;
    border-style   : solid;
    border-width   : 6px 4.5px 0 4.5px;
    pointer-events : none;
  }

  .focused {
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      bottom: 0px;
      background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
    }
  }

  .error-message {
    position: absolute;
    left: 2px;
    bottom: 8px;
    font-family: Flama;
    line-height: 16px;
    font-size: 10px;
    color: #D01E51;
    opacity: 0;
    transition: opacity 250ms;
  }

  .error-msg {
    display: none;
    color: #D01E51;
    font-size: 1.2rem;
    margin-left: 5px;
  }

  .field-validation-error {
    display: none;
  }  

  .field-validation-error ~ .error-msg {
    display: inline;
  }

  .field-validation-error ~ div:not(.clear-error) {

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      bottom: 0;
      background: #D01E51;
    }
    
    input, select {
      border-color: #D01E51;
      
      &:focus {
        box-shadow: none;
      }
    }
    
    .error-message {
      opacity: 1;
    }
  }

  &.light {
    color: $dark-teal !important;
  }
}

//Global input values
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]), .input-group-lg>select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]), .form-control {
  height : 50px;
}

// Scoped to inner modal
.inner-modal {

  .form-group {
    position      : relative;
    margin-bottom : 2.4rem;
  }

  select.form-control, .form-control {
    @include flama-book;
    box-sizing    : border-box;
    padding       : 12px;
    font-size     : 1.2rem;
    color         : inherit;
    line-height   : 2;
    border        : 1px solid rgba(225, 232, 237, 0.3);
    border-radius : 0px;

    &:focus {
      color            : #fff;
      border-color     : rgba($purple, 0.3);
      box-shadow       : 0 0 0 0.1rem rgba($purple, 0.3);
      outline          : 0;
    }
  }

  select {
    appearance         : none;
    padding-right      : 15px;
    padding-left       : 15px;
    transition         : padding 250ms;
    -webkit-appearance : none;
    -moz-appearance    : none;

    &:focus {
      box-shadow : none;
    }
  }

  .input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]), .input-group-lg>select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
    height : 50px;
  }

  .select-button {
    position       : absolute;
    top            : calc(50% - 3px);
    right          : 14px;
    height         : 6px;
    width          : 9px;
    opacity        : 0.4;
    border-color   : $dark-teal transparent transparent transparent;
    border-style   : solid;
    border-width   : 6px 4.5px 0 4.5px;
    pointer-events : none;
  }

  .dark {
    .select-button {
      border-color : rgba(#F6F8F9, 0.4) transparent transparent transparent;
    }
  }
}

// Generic Form (add to forms shared partial)
.form {
  width: 100%; // for IE compatibility

  h5 {
    margin-bottom: 16px;
  }

  .form-primary {
    padding: 60px;
  }

  .form-complete {
    padding: 30px 30px 60px;
    height: 100%;

    @include media-breakpoint-up(md) {
      padding: 150px 60px 60px;
    }

    .response {
      text-align: center;
      margin-bottom: 60px;

      .supporting-copy {
        margin: 20px 0;
      }
    }

    .link-card {
      background: $white;
      padding: 30px;
      border-top: 2px solid $teal;
      width: 100%;

      ul {
        list-style: none;
        margin-top: 50px;
      }

      li {
        margin-bottom: 10px;
      }

      a, a:hover, a:focus {
        text-decoration: underline;
        color: $purple;
      }
    }
  }

  ::placeholder {
    opacity: 1 !important;
    color: $dark-teal !important;
  }

  input, select, textarea {
    height: 50px !important;
    margin-bottom: 16px;
    color: $dark-teal !important;
    font-family : 'Flama' !important;
    border-radius: 1px;
    border: 1px solid #E1E8ED;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 12px;
  }

  label {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100% !important;
    padding: 0 15px !important;
    text-transform: uppercase;
    font-size: 8px;
    letter-spacing: 0.2em;
    color: #6A6A6A;
    opacity: 0.6;
    z-index: 1;
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
  }

  input {
    position: relative;
    padding: 10px 15px;
    transition: padding 250ms;
    overflow: hidden;
    
    &:focus {
        box-shadow: none;
    }
  }

  select {
    padding-left: 15px;
    padding-right: 15px;
    transition: padding 250ms;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    &:focus {
        box-shadow: none;
    }

    &::-ms-expand {
      display: none; // Hides default select arrows in IE 10 and 11
    }
  }

  .select-button {
    position: absolute;
    right: 15px;
    top: 25px;
    width: 9px;
    height: 6px;
    border-style: solid;
    border-width: 6px 4.5px 0 4.5px;
    border-color: $dark-teal transparent transparent transparent;
    opacity: 0.4;
    pointer-events: none;
  }

  textarea {
    padding: 15px;
    min-height: 100px;
    
    &:focus {
      box-shadow: none;
    }
  }

  .populated {
    flex-direction: column;
    
    label {
      opacity: 1;
      position: relative;
      top: 0;
      padding: 0 !important; // have to use important to override WLS stuff.
    }
    
    input, select, textarea {
      color: $dark-charcoal !important;
    }

    input {
      padding-top: 29px !important;
      padding-bottom: 19px !important;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-top:18px !important;
      }
    }

    select {
      padding-top: 18px!important;
    }

    .select-button {
      top: auto;
      bottom: 45px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      label {
        position: absolute;
        top: 10px;
        width: 100% !important;
        padding: 0 15px !important;
      }

      .select-button {
        top: 20px;
        bottom: auto;
      }
    }
  }

  .focused {
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      bottom: 16px;
      background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
    }
  }

  .error-message {
    position: absolute;
    left: 2px;
    bottom: 8px;
    font-family: Flama;
    line-height: 16px;
    font-size: 10px;
    color: #D01E51;
    opacity: 0;
    transition: opacity 250ms;
  }

  .has-error {

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      bottom: 25px;
      background: #D01E51;
    }
    
    input {
      border-color: #D01E51;
      
      &:focus {
        box-shadow: none;
      }
    }
    
    .error-message {
      opacity: 1;
    }
  }

  .email-field {
    display: flex;
    margin-bottom: 10px;
    
    a {
      flex: 0 1 30%;
      text-align: center;
      padding: 16px 0;
      font-size: 12px;
    }
    
    &:after {
      display: none;
    }
  }

  .fa-spinner {
    margin-right: 10px;
  }

  button {
    line-height: 24px;
    font-size: 14px;
    padding: 12px;
    border-radius: 100px;
  }

  .btn-success {
    background: linear-gradient(105.27deg, $magenta -53.18%, #3B94A3 143.82%);
    border-color: transparent;
    
    &:hover, &:focus, &:active, &:active:hover &:active:focus {
      background: linear-gradient(105.27deg, $magenta -53.18%, #3B94A3 143.82%);
      border-color: transparent;
    }
  }

  .btn-dark {
    color: $white;
    background: rgba(61, 79, 99, 0.4);
    border-color: transparent;
    
    &:hover, &:focus, &:active, &:active:hover &:active:focus {
      background: rgba(61, 79, 99, 0.4);
      border-color: transparent;
    }
  }

  .form-flex {
    display: flex;
    align-items: center;
    padding: 0;
  }

  @include media-breakpoint-up(md) {  
    .form-margin-right {
      width: 47.5%;
      margin-right: 2.5%;
    }
    
    .form-margin-left {
        width: 47.5%;
        margin-left: 2.5%;
    }
  }
}

$faint-white: rgba(255,255,255,0.05);

.form-stats {
  padding: 120px;
  background-color: $dark-charcoal;

  .user-savings {
    border-radius: 5px;
    background-color: $faint-white;
    overflow: hidden;
    margin-top: 60px;
  }

  .stat-row {
    padding: 20px 0;
    margin: 0 40px;
    border-bottom: solid 1px $faint-white;
    
    &:last-child {
      border-bottom: 0;
    }

    &.unlocked {
      padding: 20px 40px;
      margin: 0;
      background-color: $white;
      border-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  .stat-name,
  .stat-value {
    @include flamasemi-book;
    font-size   : 22px;
    line-height : 30px;

    &.add-dollar:before {
      content: '$';
    }
  }

  .stat-name {
    color: $white;

    &.unlocked {
      color: $purple;
    }
  }

  .stat-value {
    font-weight: 600;
    color: $white;

    &.unlocked {
      color: $purple;
    }
  }
}

//Thanks form
// These are some default overrides
// .inner-modal.mcui-embedded-form.p-success .resources-info-scroll .requested-resources.thanks-container {
//   .requested-resources-inner ul {
//     padding: 0;
//     li.resource-row {
//       padding: 10px 25px;
//     }
//   }
// }
// .inner-modal .resources-info-scroll .requested-resources .requested-resources-inner ul li .shown-doc-links, .inner-modal .resources-info .requested-resources .requested-resources-inner ul li .shown-doc-links {
//   display: flex;
//   align-items: center;
//   .circle-button {
//     margin-left: auto;
//   }
// }
// For the form thanks container
// May need to move this to modal CSS
.thanks-container {
  padding: 80px 0;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 40px 0;
  }

  .thanks-message {
    position: relative;
    padding: 60px 40px 60px 0;
    @media screen and (max-width: 992px) {
      padding: 0 40px 40px 0;
    }
    img.share-1 {
      position: absolute;
      top: 34px;
      left: 172px;
      @media screen and (max-width: 992px) {
        top: -26px;
        left: 168px;
      }
      @media screen and (max-width: 768px) {
        top: -39px;
        left: 112px;
      }
    }
  }

  .download-header {
    color: #C53494;
    font-family: 'FlamaSemicondensed Semibold', sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .docSelector {
    border-radius: 5px;
    box-shadow: 0 8px 10px rgba(0,0,0,0.15);

    .docSelector-list {
      columns: 1;
    }

    .download-item {
      padding: 10px 20px;
      border-top: 1px solid rgba(225, 232, 237, 0.35);
      border-bottom: 1px solid rgba(225, 232, 237, 0.35);
    }
    .resource-heading {
      font-family: 'FlamaSemicondensed Light', sans-serif;
      font-size: 14px;
      margin: 0 10px;
    }
    a {
      &.item-icon {
        display: flex;
        align-items: center;
        width: 100%;
        img {
          width: 50px;
        }
        .circle-button {
          margin-left: auto;
          justify-self: flex-end;
        }
      }
      &:hover .circle-button:before, &:hover .dark .light .circle-button:before {
        opacity: 1;
        transform: scale(1);
      }
      &:hover .circle-button, &:hover .dark .light .circle-button {
        svg path, .dark .light svg path {
          fill: #FFFFFF;
        }
      }
    }
  }
}

// Resource page specific CSS
.prospect-form-widget.resource-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;

  .resources-info-scroll {
    order: 1;
    width: 100%;
    flex-grow: 0;
    margin-bottom: 30px;
  }
  .requested-resources {
    order: 3;
    width: 100%;
    padding: 40px 15px;
    flex-grow: 0;
  }
  .pardot-wrpr {
    order: 2;
    width: 100%;
    flex-grow: 0;
  }

  @media screen and (min-width: 992px) {
    flex-wrap: wrap;

    .resources-info-scroll {
      order: 1;
      width: 50%;
    }
    .requested-resources {
      order: 2;
      width: 50%;
      padding: 15px;
    }
    .pardot-wrpr {
      order: 3;
      width: 50%;
    }
  }

  .before-complete {
    h2 {
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: 1.5em;
    }
  }

  .pardot-doc-req-form {
    position: relative;
    padding: 30px 15px 15px 15px;

    @media screen and (min-width: 992px) {
      padding: 45px 30px 30px 30px;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      height: 0;
      width: 0;
      border-color: transparent #F6F8F9 transparent transparent;
      border-style: solid;
      border-width: 50px 50px 0 0;
    }
  }
}
