//All Side Side
.sideSide {
  .layout-left, &.layout-left {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
  .layout-right, &.layout-right {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }
}

// ROI
.sideSide-ROI {

  .cube-stack {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .cube-stack svg {
    position: absolute;
    left: -200px;
    top: -250px;
  }

  .btn-primary {
    margin-top: 20px;
  }
}

//HCI
.two-col-image-text-promo {
  padding: 20px 5px 40px 5px;
  
  .secondary-heading {
    color: #3B94A3;
    margin-bottom: 15px;
  }
  
  .image {
    margin-bottom: 30px;
    
    img {
      width: 100%;
    }
  }

  .content {
    position: relative;
  }

  .inner-content {
    width:100%;
  }
  
  @include media-breakpoint-up(md) {
    padding: 60px 0;


    .secondary-heading {
      margin-bottom: 30px;
    }
    
    .image {
      // -webkit-box-ordinal-group: 7;
      // -ms-flex-order: 6;
      // order: 6;
      margin-bottom: 0;
    }
    
    .content {
      // -webkit-box-ordinal-group: 2;
      // -ms-flex-order: 1;
      // order: 1;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    &.flip //, 
    // &.layout-left 
    {
      .image {
        // -webkit-box-ordinal-group: 2;
        // -ms-flex-order: 1;
        // order: 1;
        
        img {
          margin: 0 20px 0 0;
          float: right;
        }
      }
      
      .content {
        // -webkit-box-ordinal-group: 7;
        // -ms-flex-order: 6;
        // order: 6; 
      }
    }
  }
}