//International Flags CSS
.mcui-flag {
  background: url('/assets/images/mcui-flag-sprite.png') center center no-repeat;
  width: 27px;
  height: 18px;
  border: solid 1px #dedede;
  float: left;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.mcui-flag-us {
  background-position: 0 0;
}

.mcui-flag-gb {
  background-position: -27px 0;
}

.mcui-flag-de {
  background-position: -54px 0;
}

.mcui-flag-fr {
  background-position: -81px 0;
}

.mcui-flag-jp {
  background-position: -108px 0;
}

.mcui-flag-au {
  background-position: -135px 0;
}

.mcui-flag-zh {
  background-position: -162px 0;
}