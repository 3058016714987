//Custom SCSS file for MasterControl
@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit; 
}

.container-fluid:after, 
.container-fluid:before, 
.container:after, 
.container:before, 
.modal-footer:after, 
.modal-footer:before, 
.row:after, 
.row:before {
    display: table;
    content: " ";
}

//Bootstrap 4.2.1 -- DONT MESS WITH ME!
// @import "../bootstrap/original-dont-edit/bootstrap.scss";

//WLS modified bootstrap
@import "../bootstrap/wls-bootstrap/functions";
@import "../bootstrap/wls-bootstrap/variables";

@import "../bootstrap/wls-bootstrap/mixins/breakpoints";
@import "../bootstrap/wls-bootstrap/mixins/hover";
@import "../bootstrap/wls-bootstrap/mixins/buttons";
@import "../bootstrap/wls-bootstrap/mixins/transition"; 
@import "../bootstrap/wls-bootstrap/mixins/box-shadow";
@import "../bootstrap/wls-bootstrap/mixins/gradients";
@import "../bootstrap/wls-bootstrap/mixins/background-variant";

@import "../bootstrap/wls-bootstrap/utilities/flex";


// WLS Custom Styles
// Shared partials
@import 'shared/variables';
@import 'shared/fonts'; 
@import 'shared/footer';
@import 'shared/forms'; 
@import 'shared/mixins';
@import 'shared/reset';
@import 'shared/mcui-typography';
@import 'shared/buttons'; 
@import 'shared/ctas';
@import 'shared/classes'; 
@import 'shared/global';  
@import 'shared/accordion';
@import 'shared/form-checkmarks';
@import 'shared/popovers'; 
@import 'shared/narr-generic'; 
@import 'shared/solution-marker';


// Modules partials
@import 'modules/bottom-content';
@import 'modules/contact';
@import 'modules/flags';
@import 'modules/hero';
@import 'modules/list';
@import 'modules/docSelector';  
@import 'modules/image';
@import 'modules/narration';
@import 'modules/locations-carousel';
@import 'modules/side-side';
@import 'modules/narration-cards';
@import 'modules/topic-selector';

// Pages partials  
@import 'pages/about-us';
@import 'pages/contact'; 
@import 'pages/contact-thanks';
@import 'pages/news';
@import 'pages/404';


//ie fixes
@import 'shared/ie-fixes';
