@-ms-viewport {
  width: device-width;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.modal-footer:after,
.modal-footer:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@font-face {
  font-family: 'Flama Bold';
  src: url("../../assets/fonts/Flama-Bold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Bold.woff2") format("woff2"), url("../../assets/fonts/Flama-Bold.woff") format("woff"), url("../../assets/fonts/Flama-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Book';
  src: url("../../assets/fonts/Flama-Book.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Book.woff2") format("woff2"), url("../../assets/fonts/Flama-Book.woff") format("woff"), url("../../assets/fonts/Flama-Book.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Extrabold';
  src: url("../../assets/fonts/Flama-Extrabold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Extrabold.woff2") format("woff2"), url("../../assets/fonts/Flama-Extrabold.woff") format("woff"), url("../../assets/fonts/Flama-Extrabold.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Light';
  src: url("../../assets/fonts/Flama-Light.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Light.woff2") format("woff2"), url("../../assets/fonts/Flama-Light.woff") format("woff"), url("../../assets/fonts/Flama-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Flama Semibold';
  src: url("../../assets/fonts/Flama-Semibold.eot") format("embedded-opentype"), url("../../assets/fonts/Flama-Semibold.woff2") format("woff2"), url("../../assets/fonts/Flama-Semibold.woff") format("woff"), url("../../assets/fonts/Flama-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Book';
  src: url("../../assets/fonts/FlamaSemicondensed-Book.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Book.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Book.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Book.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Light';
  src: url("../../assets/fonts/FlamaSemicondensed-Light.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Light.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Light.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Semibold';
  src: url("../../assets/fonts/FlamaSemicondensed-Semibold.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Semibold.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Semibold.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: 'FlamaSemicondensed Ultralight';
  src: url("../../assets/fonts/FlamaSemicondensed-Ultralight.eot") format("embedded-opentype"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.woff2") format("woff2"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.woff") format("woff"), url("../../assets/fonts/FlamaSemicondensed-Ultralight.ttf") format("truetype");
}

.flamasemi-ultralight {
  font-family: 'FlamaSemicondensed Ultralight', sans-serif;
  font-style: normal;
}

.flamasemi-light {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
}

.flamasemi-book {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
}

.flamasemi-basic {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
}

.flamasemi-medium {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

.flamasemi-semibold {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

.flama-light {
  font-family: 'Flama Light', sans-serif;
}

.flama-book {
  font-family: 'Flama Book', sans-serif;
}

.flama-basic {
  font-family: 'Flama Book', sans-serif;
}

.flama-semibold {
  font-family: 'Flama Semibold', sans-serif;
}

.flama-bold {
  font-family: 'Flama Bold', sans-serif;
}

.flama-extrabold {
  font-family: 'Flama Extrabold', sans-serif;
}

.footer {
  padding: 0;
  background-color: #F6F8F9;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer .container {
  padding: 0;
}

.footer .top-section {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.footer .top-section .site-links, .footer .top-section .social {
  margin: 15px 20px;
}

.footer .top-section .lets-chat-container {
  position: relative;
  padding: 60px 60px 80px 60px;
  background: #C53494;
  background: linear-gradient(145deg, #C53494 0%, #3B94A3 100%);
  color: #FFFFFF;
}

.footer .top-section .lets-chat-container:before {
  content: '';
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  height: 0;
  width: 0;
  border-color: transparent #F6F8F9 transparent transparent;
  border-style: solid;
  border-width: 60px 60px 0 0;
}

.footer .top-section .lets-chat-container .lets-chat {
  font-family: 'FlamaSemicondensed Ultralight', sans-serif;
  font-style: normal;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 48px;
}

.footer .top-section .lets-chat-container .phone {
  font-family: 'FlamaSemicondensed Ultralight', sans-serif;
  font-style: normal;
  display: flex;
  margin: 7px 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  align-items: center;
}

.footer .top-section .lets-chat-container .phone span:first-of-type {
  font-family: 'Flama Semibold', sans-serif;
  display: inline-block;
  margin: 2px 15px 0 0;
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 14px;
  text-transform: uppercase;
}

.footer .top-section .lets-chat-container .btn {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  color: #164A62;
  margin-top: 20px;
  width: 100%;
}

.footer .top-section .lets-chat-container .btn:hover {
  color: #FFFFFF;
}

.footer .top-section .lets-chat-container .email {
  display: block;
  opacity: 1;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 24px;
  text-decoration: none;
  text-decoration: underline;
  transition: opacity 200ms ease;
}

.footer .top-section .lets-chat-container .email a {
  color: #FFFFFF;
}

.footer .top-section .lets-chat-container .email:hover {
  opacity: 0.8;
}

.footer .top-section .site-links {
  display: flex;
  margin: 85px 20px 0;
  flex-direction: row;
  justify-content: space-evenly;
  flex: auto;
}

.footer .top-section .site-links .site-links-group .site-links-group-title {
  font-family: 'Flama Book', sans-serif;
  margin: 0 0 15px 0;
  font-size: 12px;
  color: #164A62;
  letter-spacing: 0.2em;
  line-height: 14px;
  text-transform: uppercase;
}

.footer .top-section .site-links .site-links-group ul.site-links-group-list {
  display: block;
  margin-bottom: 0;
}

.footer .top-section .site-links .site-links-group ul.site-links-group-list li a {
  opacity: 0.6;
  font-size: 12px;
  color: #3D4F63;
  line-height: 28px;
  text-decoration: none;
}

.footer .top-section .site-links .site-links-group ul.site-links-group-list li a:hover {
  opacity: 1;
}

.footer .top-section .social {
  display: flex;
  margin: 85px 0 0 0;
  justify-content: flex-end;
  align-items: flex-start;
}

.footer .top-section .social a {
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 0;
}

.footer .top-section .social a svg path {
  transition: fill 200ms ease-out;
  fill: #164A62;
}

.footer .top-section .social a:hover svg.facebook path {
  fill: #3b5d9a;
}

.footer .top-section .social a:hover svg.twitter path {
  fill: #1ea4f0;
}

.footer .top-section .social a:hover svg.linkedin path {
  fill: #3b5d9a;
}

.footer .top-section .social a:hover svg.pinterest path {
  fill: #e40027;
}

.footer .bottom-section {
  display: flex;
  padding: 75px 0 40px 0;
  align-items: flex-end;
  justify-content: space-between;
}

.footer .bottom-section .address, .footer .bottom-section .copyright, .footer .bottom-section .legal {
  margin: 15px 20px;
}

.footer .bottom-section .address {
  display: flex;
  margin: 0;
  justify-content: start;
  order: 1;
}

.footer .bottom-section .address svg {
  height: 42px;
  width: 83px;
  margin: 0 30px 0 0;
}

.footer .bottom-section .address svg path {
  fill: #3B94A3;
}

.footer .bottom-section .address div {
  opacity: 0.7;
  font-size: 12px;
  color: #164A62;
  line-height: 20px;
}

.footer .bottom-section .address div a {
  display: block;
  margin: 0;
  font-size: 12px;
  color: #C53494;
  text-decoration: underline;
}

.footer .bottom-section .legal, .footer .bottom-section .copyright {
  font-size: 12px;
}

.footer .bottom-section .legal {
  display: flex;
  margin: 0;
  justify-content: end;
  order: 3;
}

.footer .bottom-section .legal ul {
  margin: 0;
}

.footer .bottom-section .legal ul li {
  display: inline-block;
  margin: 15px 0 0 40px;
}

.footer .bottom-section .legal ul li a {
  opacity: 0.6;
  color: #3D4F63;
}

.footer .bottom-section .copyright {
  margin: 0;
  opacity: 0.6;
  color: #3D4F63;
  text-align: center;
  flex: auto;
  order: 2;
}

@media (max-width: 991.98px) {
  .footer .top-section {
    flex-direction: column;
  }
  .footer .top-section .lets-chat-container {
    padding: 40px 20px 45px;
  }
  .footer .top-section .lets-chat-container .lets-chat {
    padding-bottom: 20px;
    font-size: 26px;
    line-height: 32px;
  }
  .footer .top-section .lets-chat-container .phone {
    display: block;
    margin: 7px 0;
    font-size: 18px;
    line-height: 24px;
  }
  .footer .top-section .lets-chat-container .phone span, .footer .top-section .lets-chat-container .phone span:first-of-type {
    display: block;
  }
  .footer .top-section .lets-chat-container .btn {
    width: auto;
  }
  .footer .top-section .site-links {
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer .top-section .site-links .site-links-group {
    width: 33%;
    padding: 5px;
  }
  .footer .top-section .site-links .site-links-group .site-links-group-title {
    margin: 15px 0;
  }
  .footer .top-section .site-links .site-links-group .site-links-group-title:hover {
    cursor: pointer;
  }
  .footer .top-section .social {
    margin: 30px 20px 25px;
    justify-content: space-around;
  }
  .footer .top-section .social a {
    margin: 0;
  }
  .footer .bottom-section {
    padding: 25px 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .bottom-section .address {
    flex-direction: column;
  }
  .footer .bottom-section .address svg {
    height: 26px;
    width: 52px;
    margin-bottom: 25px;
  }
  .footer .bottom-section .address div {
    margin-bottom: 8px;
  }
  .footer .bottom-section .address div a {
    display: block;
    margin: 15px 0 0 0;
  }
  .footer .bottom-section .legal {
    display: flex;
    justify-content: end;
    order: 2;
  }
  .footer .bottom-section .legal ul {
    margin: 0;
  }
  .footer .bottom-section .legal ul li {
    display: inline-block;
    margin: 5px 40px 5px 0;
  }
  .footer .bottom-section .legal ul li a {
    opacity: 0.6;
    color: #3D4F63;
    text-decoration-line: underline;
  }
  .footer .bottom-section .copyright {
    margin-top: 15px;
    opacity: 0.6;
    color: #3D4F63;
    order: 3;
  }
}

.subscribe-module, .subscribe-iframe {
  font-size: 14px;
}

.subscribe-module.dark select.form-control, .subscribe-module.dark .form-control, .subscribe-iframe.dark select.form-control, .subscribe-iframe.dark .form-control {
  background: rgba(255, 255, 255, 0.05);
  color: #FFFFFF;
  border: 1px solid rgba(225, 232, 237, 0.3);
}

.subscribe-module.dark select.form-control:focus, .subscribe-module.dark .form-control:focus, .subscribe-iframe.dark select.form-control:focus, .subscribe-iframe.dark .form-control:focus {
  background-color: transparent;
  color: #fff;
}

.subscribe-module.dark select.form-control option, .subscribe-module.dark .form-control option, .subscribe-iframe.dark select.form-control option, .subscribe-iframe.dark .form-control option {
  background-color: #202C3A;
}

.subscribe-module select.form-control, .subscribe-module .form-control, .subscribe-iframe select.form-control, .subscribe-iframe .form-control {
  font-family: 'Flama Book', sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px !important;
  font-size: 1.2rem;
  line-height: 2;
  border: 1px solid rgba(225, 232, 237, 0.9);
  border-radius: 0px;
}

.subscribe-module select.form-control:focus, .subscribe-module .form-control:focus, .subscribe-iframe select.form-control:focus, .subscribe-iframe .form-control:focus {
  background-color: inherit;
  border-color: rgba(197, 52, 148, 0.3);
  box-shadow: 0 0 0 0.1rem rgba(197, 52, 148, 0.3);
  outline: 0;
}

.subscribe-module label[for=WebFormComments], .subscribe-module #WebFormComments, .subscribe-iframe label[for=WebFormComments], .subscribe-iframe #WebFormComments {
  display: none;
}

.contact-form-cn .form-group {
  position: relative;
  margin-bottom: 2.4rem;
}

.contact-form-cn .control-label {
  font-size: 1.5rem;
  color: inherit;
}

.contact-form-cn select::-ms-expand {
  display: none;
}

.contact-form-cn select.form-control, .contact-form-cn .form-control {
  font-family: 'Flama Book', sans-serif;
  box-sizing: border-box;
  font-size: 1.5rem;
  color: inherit;
  line-height: 2;
  border: 1px solid rgba(225, 232, 237, 0.9);
  border-radius: 0px;
}

.contact-form-cn select.form-control:focus, .contact-form-cn .form-control:focus {
  color: inherit;
  border-color: rgba(197, 52, 148, 0.3);
  box-shadow: 0 0 0 0.1rem rgba(197, 52, 148, 0.3);
  outline: 0;
}

.contact-form-cn .select-wrapper {
  position: relative;
}

.contact-form-cn select {
  appearance: none;
  padding-right: 15px;
  padding-left: 15px;
  transition: padding 250ms;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.contact-form-cn select:focus {
  box-shadow: none;
}

.contact-form-cn .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .contact-form-cn .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .contact-form-cn .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .contact-form-cn .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .contact-form-cn .input-group-lg > select.form-control:not([size]):not([multiple]), .contact-form-cn select.form-control-lg:not([size]):not([multiple]) {
  height: 50px;
}

.contact-form-cn .select-button {
  position: absolute;
  top: calc(50% - 3px);
  right: 14px;
  height: 6px;
  width: 9px;
  opacity: 0.4;
  border-color: #164A62 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 4.5px 0 4.5px;
  pointer-events: none;
}

.contact-form-cn .focused:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  bottom: 0px;
  background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
}

.contact-form-cn .error-message {
  position: absolute;
  left: 2px;
  bottom: 8px;
  font-family: Flama;
  line-height: 16px;
  font-size: 10px;
  color: #D01E51;
  opacity: 1;
  transition: opacity 250ms;
}

.contact-form-cn .error-message.d-none {
  opacity: 0;
}

.contact-form-cn #mainError {
  bottom: -40px;
  font-size: 16px;
  left: 15px;
}

.contact-form-cn.light {
  color: #164A62 !important;
}

.contact-form .form-group {
  position: relative;
  margin-bottom: 2.4rem;
}

.contact-form .control-label {
  font-size: 1.5rem;
  color: inherit;
}

.contact-form select::-ms-expand {
  display: none;
}

.contact-form select.form-control, .contact-form .form-control {
  font-family: 'Flama Book', sans-serif;
  box-sizing: border-box;
  font-size: 1.5rem;
  color: inherit;
  line-height: 2;
  border: 1px solid rgba(225, 232, 237, 0.9);
  border-radius: 0px;
}

.contact-form select.form-control:focus, .contact-form .form-control:focus {
  color: inherit;
  border-color: rgba(197, 52, 148, 0.3);
  box-shadow: 0 0 0 0.1rem rgba(197, 52, 148, 0.3);
  outline: 0;
}

.contact-form .select-wrapper {
  position: relative;
}

.contact-form select {
  appearance: none;
  padding-right: 15px;
  padding-left: 15px;
  transition: padding 250ms;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.contact-form select:focus {
  box-shadow: none;
}

.contact-form .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .contact-form .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .contact-form .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .contact-form .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .contact-form .input-group-lg > select.form-control:not([size]):not([multiple]), .contact-form select.form-control-lg:not([size]):not([multiple]) {
  height: 50px;
}

.contact-form .select-button {
  position: absolute;
  top: calc(50% - 3px);
  right: 14px;
  height: 6px;
  width: 9px;
  opacity: 0.4;
  border-color: #164A62 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 4.5px 0 4.5px;
  pointer-events: none;
}

.contact-form .focused:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  bottom: 0px;
  background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
}

.contact-form .error-message {
  position: absolute;
  left: 2px;
  bottom: 8px;
  font-family: Flama;
  line-height: 16px;
  font-size: 10px;
  color: #D01E51;
  opacity: 0;
  transition: opacity 250ms;
}

.contact-form .error-msg {
  display: none;
  color: #D01E51;
  font-size: 1.2rem;
  margin-left: 5px;
}

.contact-form .field-validation-error {
  display: none;
}

.contact-form .field-validation-error ~ .error-msg {
  display: inline;
}

.contact-form .field-validation-error ~ div:not(.clear-error):after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  bottom: 0;
  background: #D01E51;
}

.contact-form .field-validation-error ~ div:not(.clear-error) input, .contact-form .field-validation-error ~ div:not(.clear-error) select {
  border-color: #D01E51;
}

.contact-form .field-validation-error ~ div:not(.clear-error) input:focus, .contact-form .field-validation-error ~ div:not(.clear-error) select:focus {
  box-shadow: none;
}

.contact-form .field-validation-error ~ div:not(.clear-error) .error-message {
  opacity: 1;
}

.contact-form.light {
  color: #164A62 !important;
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]), .form-control {
  height: 50px;
}

.inner-modal .form-group {
  position: relative;
  margin-bottom: 2.4rem;
}

.inner-modal select.form-control, .inner-modal .form-control {
  font-family: 'Flama Book', sans-serif;
  box-sizing: border-box;
  padding: 12px;
  font-size: 1.2rem;
  color: inherit;
  line-height: 2;
  border: 1px solid rgba(225, 232, 237, 0.3);
  border-radius: 0px;
}

.inner-modal select.form-control:focus, .inner-modal .form-control:focus {
  color: #fff;
  border-color: rgba(197, 52, 148, 0.3);
  box-shadow: 0 0 0 0.1rem rgba(197, 52, 148, 0.3);
  outline: 0;
}

.inner-modal select {
  appearance: none;
  padding-right: 15px;
  padding-left: 15px;
  transition: padding 250ms;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.inner-modal select:focus {
  box-shadow: none;
}

.inner-modal .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .inner-modal .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .inner-modal .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .inner-modal .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .inner-modal .input-group-lg > select.form-control:not([size]):not([multiple]), .inner-modal select.form-control-lg:not([size]):not([multiple]) {
  height: 50px;
}

.inner-modal .select-button {
  position: absolute;
  top: calc(50% - 3px);
  right: 14px;
  height: 6px;
  width: 9px;
  opacity: 0.4;
  border-color: #164A62 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 4.5px 0 4.5px;
  pointer-events: none;
}

.inner-modal .dark .select-button {
  border-color: rgba(246, 248, 249, 0.4) transparent transparent transparent;
}

.form {
  width: 100%;
}

.form h5 {
  margin-bottom: 16px;
}

.form .form-primary {
  padding: 60px;
}

.form .form-complete {
  padding: 30px 30px 60px;
  height: 100%;
}

@media (min-width: 768px) {
  .form .form-complete {
    padding: 150px 60px 60px;
  }
}

.form .form-complete .response {
  text-align: center;
  margin-bottom: 60px;
}

.form .form-complete .response .supporting-copy {
  margin: 20px 0;
}

.form .form-complete .link-card {
  background: #FFFFFF;
  padding: 30px;
  border-top: 2px solid #3B94A3;
  width: 100%;
}

.form .form-complete .link-card ul {
  list-style: none;
  margin-top: 50px;
}

.form .form-complete .link-card li {
  margin-bottom: 10px;
}

.form .form-complete .link-card a, .form .form-complete .link-card a:hover, .form .form-complete .link-card a:focus {
  text-decoration: underline;
  color: #C53494;
}

.form ::placeholder {
  opacity: 1 !important;
  color: #164A62 !important;
}

.form input, .form select, .form textarea {
  height: 50px !important;
  margin-bottom: 16px;
  color: #164A62 !important;
  font-family: 'Flama' !important;
  border-radius: 1px;
  border: 1px solid #E1E8ED;
  box-sizing: border-box;
  line-height: 24px;
  font-size: 12px;
}

.form label {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100% !important;
  padding: 0 15px !important;
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 0.2em;
  color: #6A6A6A;
  opacity: 0.6;
  z-index: 1;
  opacity: 0;
  transition: opacity 250ms;
  pointer-events: none;
}

.form input {
  position: relative;
  padding: 10px 15px;
  transition: padding 250ms;
  overflow: hidden;
}

.form input:focus {
  box-shadow: none;
}

.form select {
  padding-left: 15px;
  padding-right: 15px;
  transition: padding 250ms;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form select:focus {
  box-shadow: none;
}

.form select::-ms-expand {
  display: none;
}

.form .select-button {
  position: absolute;
  right: 15px;
  top: 25px;
  width: 9px;
  height: 6px;
  border-style: solid;
  border-width: 6px 4.5px 0 4.5px;
  border-color: #164A62 transparent transparent transparent;
  opacity: 0.4;
  pointer-events: none;
}

.form textarea {
  padding: 15px;
  min-height: 100px;
}

.form textarea:focus {
  box-shadow: none;
}

.form .populated {
  flex-direction: column;
}

.form .populated label {
  opacity: 1;
  position: relative;
  top: 0;
  padding: 0 !important;
}

.form .populated input, .form .populated select, .form .populated textarea {
  color: #202C3A !important;
}

.form .populated input {
  padding-top: 29px !important;
  padding-bottom: 19px !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form .populated input {
    padding-top: 18px !important;
  }
}

.form .populated select {
  padding-top: 18px !important;
}

.form .populated .select-button {
  top: auto;
  bottom: 45px;
}

@media (min-width: 768px) {
  .form .populated {
    flex-direction: row;
  }
  .form .populated label {
    position: absolute;
    top: 10px;
    width: 100% !important;
    padding: 0 15px !important;
  }
  .form .populated .select-button {
    top: 20px;
    bottom: auto;
  }
}

.form .focused:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  bottom: 16px;
  background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
}

.form .error-message {
  position: absolute;
  left: 2px;
  bottom: 8px;
  font-family: Flama;
  line-height: 16px;
  font-size: 10px;
  color: #D01E51;
  opacity: 0;
  transition: opacity 250ms;
}

.form .has-error:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  bottom: 25px;
  background: #D01E51;
}

.form .has-error input {
  border-color: #D01E51;
}

.form .has-error input:focus {
  box-shadow: none;
}

.form .has-error .error-message {
  opacity: 1;
}

.form .email-field {
  display: flex;
  margin-bottom: 10px;
}

.form .email-field a {
  flex: 0 1 30%;
  text-align: center;
  padding: 16px 0;
  font-size: 12px;
}

.form .email-field:after {
  display: none;
}

.form .fa-spinner {
  margin-right: 10px;
}

.form button {
  line-height: 24px;
  font-size: 14px;
  padding: 12px;
  border-radius: 100px;
}

.form .btn-success {
  background: linear-gradient(105.27deg, #C53494 -53.18%, #3B94A3 143.82%);
  border-color: transparent;
}

.form .btn-success:hover, .form .btn-success:focus, .form .btn-success:active, .form .btn-success:active:hover .form .btn-success:active:focus {
  background: linear-gradient(105.27deg, #C53494 -53.18%, #3B94A3 143.82%);
  border-color: transparent;
}

.form .btn-dark {
  color: #FFFFFF;
  background: rgba(61, 79, 99, 0.4);
  border-color: transparent;
}

.form .btn-dark:hover, .form .btn-dark:focus, .form .btn-dark:active, .form .btn-dark:active:hover .form .btn-dark:active:focus {
  background: rgba(61, 79, 99, 0.4);
  border-color: transparent;
}

.form .form-flex {
  display: flex;
  align-items: center;
  padding: 0;
}

@media (min-width: 768px) {
  .form .form-margin-right {
    width: 47.5%;
    margin-right: 2.5%;
  }
  .form .form-margin-left {
    width: 47.5%;
    margin-left: 2.5%;
  }
}

.form-stats {
  padding: 120px;
  background-color: #202C3A;
}

.form-stats .user-savings {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  margin-top: 60px;
}

.form-stats .stat-row {
  padding: 20px 0;
  margin: 0 40px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}

.form-stats .stat-row:last-child {
  border-bottom: 0;
}

.form-stats .stat-row.unlocked {
  padding: 20px 40px;
  margin: 0;
  background-color: #FFFFFF;
  border-bottom: 0;
}

.form-stats .stat-row p {
  margin: 0;
}

.form-stats .stat-name,
.form-stats .stat-value {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
}

.form-stats .stat-name.add-dollar:before,
.form-stats .stat-value.add-dollar:before {
  content: '$';
}

.form-stats .stat-name {
  color: #FFFFFF;
}

.form-stats .stat-name.unlocked {
  color: #C53494;
}

.form-stats .stat-value {
  font-weight: 600;
  color: #FFFFFF;
}

.form-stats .stat-value.unlocked {
  color: #C53494;
}

.thanks-container {
  padding: 80px 0;
}

@media screen and (max-width: 992px) {
  .thanks-container {
    flex-direction: column;
    padding: 40px 0;
  }
}

.thanks-container .thanks-message {
  position: relative;
  padding: 60px 40px 60px 0;
}

@media screen and (max-width: 992px) {
  .thanks-container .thanks-message {
    padding: 0 40px 40px 0;
  }
}

.thanks-container .thanks-message img.share-1 {
  position: absolute;
  top: 34px;
  left: 172px;
}

@media screen and (max-width: 992px) {
  .thanks-container .thanks-message img.share-1 {
    top: -26px;
    left: 168px;
  }
}

@media screen and (max-width: 768px) {
  .thanks-container .thanks-message img.share-1 {
    top: -39px;
    left: 112px;
  }
}

.thanks-container .download-header {
  color: #C53494;
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.thanks-container .docSelector {
  border-radius: 5px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

.thanks-container .docSelector .docSelector-list {
  columns: 1;
}

.thanks-container .docSelector .download-item {
  padding: 10px 20px;
  border-top: 1px solid rgba(225, 232, 237, 0.35);
  border-bottom: 1px solid rgba(225, 232, 237, 0.35);
}

.thanks-container .docSelector .resource-heading {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-size: 14px;
  margin: 0 10px;
}

.thanks-container .docSelector a.item-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.thanks-container .docSelector a.item-icon img {
  width: 50px;
}

.thanks-container .docSelector a.item-icon .circle-button {
  margin-left: auto;
  justify-self: flex-end;
}

.thanks-container .docSelector a:hover .circle-button:before, .thanks-container .docSelector a:hover .dark .light .circle-button:before {
  opacity: 1;
  transform: scale(1);
}

.thanks-container .docSelector a:hover .circle-button svg path, .thanks-container .docSelector a:hover .circle-button .dark .light svg path, .thanks-container .docSelector a:hover .dark .light .circle-button svg path, .thanks-container .docSelector a:hover .dark .light .circle-button .dark .light svg path {
  fill: #FFFFFF;
}

.prospect-form-widget.resource-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
}

.prospect-form-widget.resource-form .resources-info-scroll {
  order: 1;
  width: 100%;
  flex-grow: 0;
  margin-bottom: 30px;
}

.prospect-form-widget.resource-form .requested-resources {
  order: 3;
  width: 100%;
  padding: 40px 15px;
  flex-grow: 0;
}

.prospect-form-widget.resource-form .pardot-wrpr {
  order: 2;
  width: 100%;
  flex-grow: 0;
}

@media screen and (min-width: 992px) {
  .prospect-form-widget.resource-form {
    flex-wrap: wrap;
  }
  .prospect-form-widget.resource-form .resources-info-scroll {
    order: 1;
    width: 50%;
  }
  .prospect-form-widget.resource-form .requested-resources {
    order: 2;
    width: 50%;
    padding: 15px;
  }
  .prospect-form-widget.resource-form .pardot-wrpr {
    order: 3;
    width: 50%;
  }
}

.prospect-form-widget.resource-form .before-complete h2 {
  margin-bottom: 1em;
}

.prospect-form-widget.resource-form .before-complete h4 {
  margin-bottom: 1.5em;
}

.prospect-form-widget.resource-form .pardot-doc-req-form {
  position: relative;
  padding: 30px 15px 15px 15px;
}

@media screen and (min-width: 992px) {
  .prospect-form-widget.resource-form .pardot-doc-req-form {
    padding: 45px 30px 30px 30px;
  }
}

.prospect-form-widget.resource-form .pardot-doc-req-form::before {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  height: 0;
  width: 0;
  border-color: transparent #F6F8F9 transparent transparent;
  border-style: solid;
  border-width: 50px 50px 0 0;
}

body {
  margin: 0;
}

body {
  font-family: 'Flama Book', sans-serif;
}

h1 {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 36px;
}

h3 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 28px;
  line-height: 33px;
}

h4 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
}

h5 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
}

h6 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

p {
  line-height: 22px;
}

p, .default {
  font-size: 16px;
}

p strong, .default strong {
  font-family: 'Flama Semibold', sans-serif;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }
  h2 {
    font-size: 23px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 16px;
    line-height: 22px;
  }
}

p, tr, td {
  font-family: 'Flama Book', sans-serif;
}

.hero-heading {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
  font-size: 56px;
  line-height: 62px;
}

.primary-heading {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
}

.secondary-heading {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 36px;
}

.supporting-copy {
  font-family: 'Flama Book', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.tertiary-heading {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 22px;
  line-height: 30px;
}

.cta-text {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.nav-menu-item {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.table-cell-heading {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 21px;
  text-transform: uppercase;
}

.content-label {
  font-family: 'Flama Book', sans-serif;
  font-size: 10px;
  letter-spacing: 0.25em;
  line-height: 14px;
  text-transform: uppercase;
}

.content-label strong {
  font-family: 'Flama Semibold', sans-serif;
}

.thead-title {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 28px;
  line-height: 32px;
}

.tbody-title, .comp-title {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  text-transform: uppercase;
}

.tbody-title strong, .comp-title strong {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

.list-title {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.list-item {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
}

.text-link {
  font-family: 'Flama Book', sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.caption {
  font-family: 'Flama Book', sans-serif;
  font-style: italic;
  font-size: 16px;
  line-height: 22px;
}

.helper-text {
  font-family: 'Flama Book', sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.megamenu-desc, .arrow-text {
  font-family: 'Flama Book', sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.form-check .form-check-label {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 26px;
}

.footer .site-links ul.site-links-group-list li a {
  font-family: 'Flama Book', sans-serif;
  font-size: 12px;
  line-height: 28px;
}

@media (max-width: 991.98px) {
  .hero-heading {
    font-size: 42px;
    line-height: 48px;
  }
}

@media (max-width: 767.98px) {
  .hero-heading {
    font-size: 30px;
    line-height: 35px;
  }
  .primary-heading {
    font-family: 'FlamaSemicondensed Book', sans-serif;
    font-style: normal;
    font-size: 26px;
    line-height: 32px;
  }
  .secondary-heading {
    font-size: 22px;
    line-height: 26px;
  }
}

.btn {
  padding: 14px 45px;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  border-radius: 100px;
}

.btn:hover {
  cursor: pointer;
  color: inherit;
}

.btn-sm {
  padding: 10px 20px;
}

.btn-xs {
  padding: 5px 15px;
}

.btn-primary, a.btn-primary {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  opacity: 1;
  background-color: #3B94A3;
  background-image: linear-gradient(to right, #C53494, #3B94A3);
  background-repeat: no-repeat;
  background-size: 105%;
  color: #FFFFFF;
  transition: all 300ms ease-out;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus, a.btn-primary:hover, a.btn-primary:active, a.btn-primary:focus, a.btn-primary:not(:disabled):not(.disabled):active, a.btn-primary:not(:disabled):not(.disabled):active:focus {
  cursor: pointer;
  background-color: #3B94A3;
  background-position: -500px 0;
  color: #FFFFFF;
  border: 0;
  box-shadow: none;
}

.btn-primary.disabled, a.btn-primary.disabled {
  background-image: linear-gradient(to right, #C3C5C8, #C3C5C8);
}

.btn-primary.stroked, a.btn-primary.stroked {
  background-clip: padding-box;
  border-radius: 40px;
  box-sizing: border-box;
  color: #4E4E50;
  display: block;
  margin: 0 auto;
  padding: 3px;
  position: relative;
  height: 50px;
  z-index: 2;
}

.btn-primary.stroked span, a.btn-primary.stroked span {
  align-items: center;
  background: #FFFFFF;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background .5s ease;
  width: 100%;
  padding: 0 40px;
}

.btn-primary.stroked:hover span, .btn-primary.stroked:active span, a.btn-primary.stroked:hover span, a.btn-primary.stroked:active span {
  color: #FFFFFF;
  background: transparent;
}

.btn-secondary {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  opacity: 1;
  background-color: #3B94A3;
  color: #FFFFFF;
  transition: all 300ms ease-out;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  cursor: pointer;
  background-color: #164A62;
  color: #FFFFFF;
  border: 0;
  box-shadow: none;
}

.btn-slate {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  opacity: 1;
  background-color: #4E4E50;
  background-image: linear-gradient(to right, #8A8B8C, #4E4E50);
  background-repeat: no-repeat;
  background-size: 105%;
  color: #FFFFFF;
  transition: all 300ms ease-out;
}

.btn-slate:hover {
  background-color: #4E4E50;
  background-position: -275px 0;
  color: #FFFFFF;
}

.btn-white {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  display: inline-block;
  opacity: 1;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #C53494;
  line-height: 24px;
  border-radius: 100px;
  transition: all 300ms ease-out;
  border: 0;
}

.btn-white:hover, .btn-white:active, .btn-white:focus, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled):active:focus {
  cursor: pointer;
  background-color: #3B94A3;
  color: #FFFFFF;
}

.btn-default {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  display: inline-block;
  opacity: 1;
  background-color: #C3C5C8;
  font-size: 14px;
  color: #000000;
  line-height: 24px;
  border-radius: 100px;
  transition: all 300ms ease-out;
  border: 0;
}

.btn-default:hover, .btn-default:active, .btn-default:focus, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled):active:focus {
  cursor: pointer;
  background-color: #4E4E50;
  color: #FFFFFF;
}

.btn .badge, span.badge {
  background-color: #3B94A3;
  margin-left: 5px;
  border-radius: 10px;
  color: #FFFFFF;
}

.btn-mobile-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 14px 45px;
  background-color: #3B94A3;
  background-image: linear-gradient(to right, #C53494, #3B94A3);
  background-repeat: no-repeat;
  background-size: 105%;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  border: 0;
  transition: all 300ms ease-out;
}

.circle-button, .dark .light .circle-button {
  position: relative;
  display: flex;
  height: 60px;
  width: 60px;
  margin: 0;
  background-color: #F6F8F9;
  border: 1px solid #D6DFE3;
  border-radius: 100px;
  transition: all 200ms ease;
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  flex: none;
}

.circle-button:before, .dark .light .circle-button:before {
  content: '';
  position: absolute;
  z-index: 5;
  height: 60px;
  width: 60px;
  opacity: 0;
  background-color: #C53494;
  border-radius: 100%;
  transition: all 200ms ease-out;
  transform: scale(0.01);
}

.circle-button svg, .dark .light .circle-button svg {
  z-index: 10;
}

.circle-button svg path, .dark .light .circle-button svg path {
  transition: all 200ms ease-out;
  fill: #C53494;
}

.circle-button:hover, .dark .light .circle-button:hover {
  cursor: pointer;
  border: 1px solid transparent;
}

.circle-button:hover:before, .dark .light .circle-button:hover:before {
  opacity: 1;
  transform: scale(1);
}

.circle-button:hover svg path, .dark .light .circle-button:hover svg path {
  fill: #FFFFFF;
}

.play-button {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  margin: 0;
  background: #C53494;
  border: 1px solid #C3C5C8;
  border-radius: 100px;
  transition: all 200ms ease;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  flex: none;
}

.play-button span {
  display: none;
}

.play-button:before {
  content: '';
  position: absolute;
  z-index: 5;
  height: 60px;
  width: 60px;
  opacity: 0;
  background-color: #FFFFFF;
  border-radius: 100%;
  z-index: 0;
  transition: all 200ms ease-out;
  transform: scale(0.01);
}

.play-button svg {
  z-index: 10;
}

.play-button svg path {
  transition: all 200ms ease-out;
  z-index: 100;
  fill: #FFFFFF;
}

.play-button:hover {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}

.play-button:hover svg path {
  fill: #C53494;
}

.play-button:hover:before {
  opacity: 1;
  transform: scale(1);
}

a:hover .play-button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}

a:hover .play-button svg path {
  fill: #C53494;
}

a:hover .play-button:before {
  opacity: 1;
  transform: scale(1);
}

.dark .circle-button {
  background-color: #263240;
  border: 1px solid rgba(225, 232, 237, 0.15);
}

.dark .circle-button svg path {
  fill: #FFFFFF;
}

.dark .circle-button:hover {
  background-color: transparent;
  border: 1px solid #FFFFFF;
}

.dark .circle-button:hover svg path {
  fill: #FFFFFF;
}

.cta-container {
  margin-top: 40px;
  padding: 0;
}

.cta-container .cta {
  display: flex;
  text-decoration: none;
  align-items: flex-start;
}

.cta-container .cta-text-group {
  margin: 0 22px;
}

.cta-container .content-label {
  margin-bottom: 4px;
  color: #C53494;
}

.cta-container:hover .circle-button {
  border: 1px solid #C53494;
}

.cta-container:hover .circle-button:before {
  opacity: 1;
  transform: scale(1);
}

.cta-container:hover .circle-button svg path {
  fill: #FFFFFF;
}

.cta-container.text-only {
  padding: 10px 0;
}

.cta-container.text-only .cta-text {
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(197, 52, 148, 0.1);
  transition: all 200ms ease;
}

.cta-container.text-only .cta-text svg {
  margin-left: 20px;
  transition: transform 200ms;
  transform: translateX(0px);
}

.cta-container.text-only .cta-text:hover {
  margin-right: 0px;
  padding-right: 10px;
  cursor: pointer;
}

.cta-container.text-only .cta-text:hover svg {
  transform: translateX(10px);
}

.cta-container.text-only svg path {
  fill: #C53494;
}

.cta-container.action:before {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  background-color: #F6F8F9;
}

.cta-container.action .cta {
  padding: 22px;
}

.cta-container.gradient-top:before {
  height: 1px;
  background: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

.cta-container.watch .cta-text-group {
  margin: 0;
}

.cta-container.watch .cta {
  position: relative;
  padding-left: 100px;
}

.cta-container.watch .promo-image {
  position: absolute;
  display: block;
  top: 22px;
  left: 0;
  height: 55px;
  width: 77px;
}

.cta-container.watch .promo-image img {
  width: 100%;
}

.cta-container.watch .promo-image:before, .cta-container.watch .promo-image:after {
  content: '';
  position: absolute;
}

.cta-container.watch .promo-image:before {
  top: 0;
  left: 0;
  height: 47px;
  width: 77px;
  opacity: 0.5;
  background: linear-gradient(144.46deg, #C53494 -0.43%, #3B94A3 97.4%);
  transition: opacity 250ms;
}

.cta-container.watch .promo-image:after {
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #FFFFFF;
  border-style: solid;
  border-width: 4.5px 0 4.5px 9px;
  transform: translate(-50%, -50%);
}

.cta-container.watch:hover .promo-image:before {
  opacity: 0;
}

@media (max-width: 767.98px) {
  .cta-container.action .cta {
    padding: 22px 0;
  }
  .cta-container.watch .cta-text-group {
    margin-left: 90px;
  }
  .cta-container.dark-mobile-only {
    background-color: #202C3A;
  }
  .cta-container.dark-mobile-only:before {
    display: none;
  }
  .cta-container.dark-mobile-only:after {
    content: '';
    display: block;
    height: 3px;
    width: 100%;
    background: linear-gradient(90deg, #C53494 0%, #3B94A3 97.93%);
  }
  .cta-container.dark-mobile-only .content-label {
    opacity: 0.5;
    color: #FFFFFF;
  }
  .cta-container.dark-mobile-only .cta-text {
    color: #FFFFFF;
  }
  .cta-container.dark-mobile-only .circle-button {
    background: #263240;
    border: 1px solid rgba(225, 232, 237, 0.15);
  }
  .cta-container.dark-mobile-only .circle-button svg path {
    fill: #FFFFFF;
  }
}

.cta-container a.cta-text, .cta-container a.cta {
  color: #164A62;
}

.cta-container a.cta-text:hover, .cta-container a.cta:hover {
  color: #3B94A3;
}

.cta-container a.cta-text:visited, .cta-container a.cta:visited {
  color: #164A62;
}

.dark .cta-container .content-label {
  opacity: 0.5;
  color: #FFFFFF;
}

.dark .cta-container.text-only .cta-text {
  border-bottom-color: #C53494;
  color: #FFFFFF;
}

.dark .cta-container.action:before, .dark .cta-container.action:after {
  content: '';
  display: block;
  height: 2px;
  width: 100%;
  opacity: 0.3;
  background-color: #6A6A6A;
}

.dark .cta-container:hover .circle-button {
  background-color: transparent;
  border: 1px solid transparent;
}

.dark .cta-container:hover .circle-button svg path {
  fill: #FFFFFF;
}

.gradient-border {
  padding: 0 30px;
  border-width: 0;
  border-image: linear-gradient(#52C0D3, #C53494) 0 100%;
  border-left: 4px solid;
}

@media (max-width: 991.98px) {
  .gradient-border {
    padding: 0 20px;
    border-left-width: 4px;
  }
}

.gradient-bg {
  background-color: #C53494;
  background-image: linear-gradient(162.03deg, #C53494 -0.43%, #3B94A3 97.4%);
  color: #FFFFFF;
}

.gradient-bg .primary-heading, .gradient-bg a {
  color: #FFFFFF;
}

.gradient-bg .supporting-copy {
  color: #FFFFFF;
}

.gradient-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background: linear-gradient(71.94deg, #202C3A 0%, rgba(123, 38, 108, 0.1) 68.4%);
}

.light {
  background-color: #FFFFFF;
  color: #202C3A;
}

.light .primary-heading, .light .hero-heading {
  color: #164A62;
}

.light .supporting-copy {
  color: #3D4F63;
}

.light .object-background {
  background-color: #F6F8F9;
}

.light .table-cell-heading {
  color: #8A8B8C;
}

.neutral {
  background-color: #F6F8F9;
  color: #202C3A;
}

.neutral .primary-heading .hero-heading {
  color: #164A62;
}

.neutral .supporting-copy {
  color: #3D4F63;
}

.neutral .object-background {
  background-color: #D6DFE3;
}

.neutral .table-cell-heading {
  color: #164A62;
}

.dark {
  background-color: #202C3A;
  color: #FFFFFF;
}

.dark .primary-heading, .dark a:not(.btn) {
  color: #FFFFFF;
}

.dark .secondary-heading, .dark .content-label {
  color: #FFFFFF;
}

.dark .featured-content > a .secondary-heading, .dark .featured-content > a .content-label {
  color: #FFFFFF;
}

.dark a:not(.btn):hover {
  color: #D6DFE3;
}

.dark .supporting-copy {
  color: #D6DFE3;
}

.dark .object-background {
  background-color: #202C3A;
}

.dark .table-cell-heading {
  color: #FFFFFF;
}

.bg-light-gray {
  background-color: #F6F8F9 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-dark-charcoal {
  background-color: #202C3A !important;
}

html {
  font-size: 10px;
}

html, body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #164A62;
}

body {
  font-family: 'Flama Book', sans-serif;
}

body a {
  color: #7B266C;
  text-decoration: none;
  transition: color 200ms ease;
}

body a:hover {
  color: #C53494;
  text-decoration: none;
}

body a:visited:not(.btn) {
  color: #164A62;
  text-decoration: none;
}

body a p {
  color: #164A62;
}

body p a:not(.btn), body dl dd a:not(.btn) {
  text-decoration: underline;
  z-index: 1;
  position: relative;
}

body p a:not(.btn)::before, body dl dd a:not(.btn)::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -0.25em;
  right: -0.25em;
  background-color: rgba(59, 148, 163, 0.15);
  transform-origin: center center;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

body p a:not(.btn):hover::before, body dl dd a:not(.btn):hover::before {
  transform: scaleX(1);
  transform-origin: center center;
}

body p a:not(.btn).btn, body dl dd a:not(.btn).btn {
  text-decoration: none;
  color: #FFFFFF;
}

body p a:not(.btn) *, body dl dd a:not(.btn) * {
  text-decoration: none;
}

@media not all and (min-resolution: 0.001dpcm) {
  .row:before, .row:after {
    display: inline-block !important;
    content: normal;
  }
  a:focus {
    outline: 1px solid rgba(78, 78, 80, 0.1);
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .row:before, .row:after {
    display: none !important;
  }
}

a[href^="tel"] {
  color: inherit;
}

img[src*="terminus"] {
  display: none;
}

.hidden {
  display: none;
}

hr {
  height: 1px;
  width: 100%;
  background-image: linear-gradient(150.36deg, #C53494 -10.21%, #3B94A3 124.46%);
  border: 0;
}

svg, path, line {
  backface-visibility: hidden;
}

ul {
  list-style-type: square;
}

@media (max-width: 991.98px) {
  .accordion-item .toggle-content {
    display: none;
  }
  .accordion-item.active .toggle-content {
    display: block;
  }
}

@media (min-width: 992px) {
  .accordion-item .toggle-content, .accordion-item.active .toggle-content {
    display: inherit;
  }
}

.r-accordion {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.15);
}

.r-accordion .gradient-border {
  padding: 0;
}

.r-accordion ul.resource-list {
  margin-bottom: 0;
  list-style-type: none;
  padding-inline-start: 0;
  position: relative;
  border-top: 1px solid rgba(195, 197, 200, 0.35);
  border-bottom: 1px solid rgba(195, 197, 200, 0.35);
}

.r-accordion ul.resource-list .resource-row {
  position: relative;
  padding: 10px 40px 10px 20px;
  border-bottom: 1px solid rgba(195, 197, 200, 0.35);
}

.r-accordion ul.resource-list .resource-row:last-of-type {
  border-bottom: none;
}

.r-accordion ul.resource-list .resource-row.active .info-expand {
  opacity: 1;
  transition: opacity 0.4s;
}

.r-accordion .tease-doc-links, .r-accordion .shown-doc-links {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.r-accordion .tease-doc-links img.doc-icon, .r-accordion .shown-doc-links img.doc-icon {
  width: 40px;
  height: auto;
  margin-right: 15px;
}

.r-accordion .tease-doc-links p.resource-heading, .r-accordion .shown-doc-links p.resource-heading {
  color: #164A62;
  margin: 0;
}

.r-accordion .tease-doc-links .circle-button, .r-accordion .shown-doc-links .circle-button {
  margin-left: auto;
}

.r-accordion .info-click {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  color: #C53494;
  padding: 10px;
  font-size: 16px;
}

.r-accordion .info-expand {
  position: relative;
  opacity: 0;
  top: 0;
  left: 0;
  padding-top: 15px;
  transition: opacity 0.4s ease-in-out;
}

@media screen and (min-width: 768px) {
  .r-scroll {
    padding: 10px 15px 10px 0;
    max-height: 400px;
    overflow-y: scroll;
  }
  .r-scroll::-webkit-scrollbar {
    width: 4px;
    height: 80%;
  }
  .r-scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #C53494 0%, #3B94A3 97.93%);
  }
  .r-scroll::-webkit-scrollbar-track {
    background: rgba(154, 154, 154, 0.1);
  }
}

.form-check-label {
  position: relative;
  display: block;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #E1E8ED;
  border-radius: 3px;
  transition: all 200ms ease;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #E1E8ED;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #202C3A;
  border-color: #202C3A;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 5.7px;
  left: 8.2px;
  height: 9px;
  width: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dark .checkmark {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(225, 232, 237, 0.3);
}

.dark .form-check-label:hover input ~ .checkmark {
  background-color: rgba(225, 232, 237, 0.3);
  border-color: rgba(255, 255, 255, 0.05);
}

.dark .form-check-label input:checked ~ .checkmark {
  background-color: #C53494;
  border-color: #C53494;
}

.popover {
  background-color: #FFFFFF;
}

.popover .popover-body {
  padding: 10px;
  color: #4E4E50;
  font-size: 14px;
}

.narration-longForm, .single-page-content {
  max-width: 1140px;
  width: 100%;
  padding: 40px 5%;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  font-family: 'Flama Book', sans-serif;
}

.narration-longForm h2, .narration-longForm h3, .single-page-content h2, .single-page-content h3 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  border-image: linear-gradient(#52C0D3, #C53494) 0 100%;
  border-left: 6px solid;
  padding-left: 15px;
  font-size: 30px;
  color: #164A62;
  margin: 30px 0 15px 0;
  line-height: 1.3em;
}

.narration-longForm strong, .single-page-content strong {
  font-family: 'flamasemi-bold', sans-serif;
  font-weight: 700;
}

.narration-longForm p, .single-page-content p {
  font-family: 'Flama Book', sans-serif;
}

.narration-longForm ul, .narration-longForm ol, .single-page-content ul, .single-page-content ol {
  column-count: 2;
  column-gap: 40px;
  padding: 15px 30px 20px 40px;
  background-color: #F6F8F9;
}

.narration-longForm ul li, .narration-longForm ol li, .single-page-content ul li, .single-page-content ol li {
  margin-bottom: 15px;
}

.narration-longForm ul li > ul, .narration-longForm ul > ul, .narration-longForm ol li > ul, .narration-longForm ol > ul, .single-page-content ul li > ul, .single-page-content ul > ul, .single-page-content ol li > ul, .single-page-content ol > ul {
  column-count: 1;
  list-style-type: circle;
}

@media screen and (max-width: 767.98px) {
  .narration-longForm ul, .narration-longForm ol, .single-page-content ul, .single-page-content ol {
    column-count: 1;
    column-gap: normal;
  }
}

.narration-longForm img, .single-page-content img {
  max-width: 100%;
  height: auto;
}

.narration-longForm.dark h2, .narration-longForm.dark h3, .single-page-content.dark h2, .single-page-content.dark h3 {
  color: #FFFFFF;
}

.solution-marker {
  width: 60px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .solution-marker {
    width: 80px;
  }
}

.solution-marker .shape {
  background: linear-gradient(135deg, #C53494 0%, #52C0D3 100%);
  border-radius: 5px;
  height: 60px;
  width: 60px;
}

@media (min-width: 768px) {
  .solution-marker .shape {
    height: 80px;
    width: 80px;
  }
}

.solution-marker .shape abbr {
  text-align: center;
  color: #FFFFFF;
  font-family: 'FlamaSemicondensed Ultralight', sans-serif;
  font-style: normal;
  font-size: 32px;
  font-weight: 100;
  text-decoration: none !important;
  cursor: default !important;
}

@media (min-width: 768px) {
  .solution-marker .shape abbr {
    font-size: 50px;
  }
}

.solution-marker .solution-name {
  color: #C3C5C8;
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  text-align: center;
  font-size: 10px;
}

@media (min-width: 768px) {
  .solution-marker .solution-name {
    font-size: 12px;
  }
}

.offset-marker .solution-marker {
  position: absolute;
  top: -40px;
  margin: 0;
}

.bottom-content .primary-heading, .bottom-content .supporting-copy {
  margin-bottom: 20px;
  width: 100%;
}

.bottom-content img {
  max-width: 100%;
}

.bottom-content .image-background {
  min-height: 300px;
  background-position: center center;
  background-size: cover;
}

.bottom-content .about {
  display: flex;
  padding: 30px 35px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .bottom-content .about {
    padding: 80px 60px;
  }
}

@media (min-width: 1200px) {
  .bottom-content .about {
    max-width: calc(1140px / 2);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .bottom-content .about {
    max-width: calc(960px / 2);
  }
}

.contact-module .container {
  display: flex;
  padding: 7em 0;
  justify-content: space-around;
}

.contact-module .contact-copy {
  margin-right: 10%;
}

.contact-module .primary-heading {
  margin-bottom: 30px;
}

.contact-module .contact-methods {
  display: flex;
  align-items: initial;
}

.contact-module .contact-methods .method {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  display: flex;
  width: 200px;
  margin: 3px;
  padding: 40px 0;
  background-color: #263240;
  font-size: 15px;
  letter-spacing: 0.03em;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border-color: rgba(225, 232, 237, 0.15);
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  flex-direction: column;
  align-items: center;
}

.contact-module .contact-methods .method:hover {
  background-image: linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
  border-color: rgba(225, 232, 237, 0.25);
}

.contact-module .contact-methods .method img {
  height: 75px;
  width: auto;
  margin-bottom: 35px;
}

.contact-module .contact-methods .method .content-label {
  font-family: 'Flama Book', sans-serif;
  white-space: nowrap;
}

@media (max-width: 1199.98px) {
  .contact-module .contact-copy {
    margin-right: 2%;
  }
}

@media (max-width: 991.98px) {
  .contact-module .container {
    padding: 40px 20px 20px 20px;
    flex-direction: column;
  }
  .contact-module .primary-heading {
    margin-bottom: 15px;
  }
  .contact-module .contact-copy {
    margin-right: 0;
  }
  .contact-module .contact-methods {
    margin-top: 20px;
    flex-direction: column;
  }
  .contact-module .contact-methods .method {
    width: 100%;
    padding: 20px;
    font-size: 11px;
    line-height: 24px;
    flex-direction: row;
  }
  .contact-module .contact-methods .method img {
    height: 40px;
    width: 40px;
    margin: 0 20px 0 0;
  }
  .contact-module .contact-methods .method .content-label {
    margin-left: 20px;
  }
}

.contact-methods a.method:nth-child(3) {
  display: none;
}

.mcui-flag {
  background: url("/assets/images/mcui-flag-sprite.png") center center no-repeat;
  width: 27px;
  height: 18px;
  border: solid 1px #dedede;
  float: left;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.mcui-flag-us {
  background-position: 0 0;
}

.mcui-flag-gb {
  background-position: -27px 0;
}

.mcui-flag-de {
  background-position: -54px 0;
}

.mcui-flag-fr {
  background-position: -81px 0;
}

.mcui-flag-jp {
  background-position: -108px 0;
}

.mcui-flag-au {
  background-position: -135px 0;
}

.mcui-flag-zh {
  background-position: -162px 0;
}

.hero-module {
  position: relative;
  margin: 0px 0 40px 0;
  background-color: #F6F8F9;
  justify-content: center;
}

.hero-module .intro-copy {
  position: relative;
  display: flex;
  top: 40px;
  background-color: #202C3A;
  justify-content: flex-end;
}

.hero-module .intro-copy:before {
  content: '';
  position: absolute;
  display: block;
  right: 0px;
  height: 0;
  width: 0;
  border-color: transparent #F6F8F9 transparent transparent;
  border-style: solid;
  border-width: 0 60px 60px 0;
}

.hero-module .intro-copy .hero-section-wrapper {
  max-width: calc(1140px / 2);
  padding: 70px 5%;
  width: 100%;
}

.hero-module .intro-copy .hero-section-wrapper br {
  display: none;
}

.hero-module .intro-copy .hero-section-wrapper ul, .hero-module .intro-copy .hero-section-wrapper ol {
  font-family: 'Flama book', sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.hero-module .intro-copy .hero-section-wrapper ul li, .hero-module .intro-copy .hero-section-wrapper ol li {
  margin-bottom: 10px;
}

.hero-module .gradient {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 10px;
  width: 100%;
  background: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

.hero-module .primary-heading {
  margin-bottom: 3%;
  color: #FFFFFF;
}

.hero-module .supporting-copy {
  opacity: 0.8;
  color: #FFFFFF;
}

.hero-module .description {
  display: flex;
  color: #3D4F63;
  justify-content: space-between;
  flex-direction: column;
}

.hero-module .description .hero-section-wrapper {
  max-width: calc(1140px / 2);
  padding: 50px 5% 20px;
}

@media (min-width: 992px) {
  .hero-module {
    justify-content: flex-end;
  }
  .hero-module .intro-copy .hero-section-wrapper {
    padding-right: 10%;
    padding-left: 10%;
  }
  .hero-module .description .hero-section-wrapper {
    padding-right: 7%;
    padding-left: 7%;
  }
}

@media (min-width: 1200px) {
  .hero-module .intro-copy {
    max-width: 700px;
  }
  .hero-module .intro-copy .hero-section-wrapper {
    max-width: none;
    padding-right: 20%;
    padding-left: 75px;
  }
  .hero-module .description .hero-section-wrapper {
    padding-right: 0;
    padding-left: 8%;
  }
}

@media (max-width: 1199.98px) {
  .hero-module .intro-copy {
    top: 50px;
  }
}

@media (max-width: 767.98px) {
  .hero-module {
    margin-bottom: 0;
    background-color: #FFFFFF;
  }
  .hero-module .container {
    margin-bottom: 0;
    padding: 0;
    flex-direction: column;
  }
  .hero-module .intro-copy, .hero-module .description {
    width: 100%;
  }
  .hero-module .intro-copy {
    top: 0;
    left: 0;
    z-index: 5;
    margin-right: 20px;
    background-size: 34px;
  }
  .hero-module .intro-copy:before {
    border-color: transparent #FFFFFF transparent transparent;
    border-width: 0 40px 40px 0;
  }
  .hero-module .intro-copy .hero-section-wrapper {
    max-width: none;
    margin: 0 auto;
    padding: 40px 20px;
  }
  .hero-module .description {
    z-index: 1;
    margin-top: -100px;
    padding: 0;
    padding-top: 100px;
    background-color: #F6F8F9;
  }
  .hero-module .description .hero-section-wrapper {
    max-width: none;
    padding: 40px 0 0 0;
  }
  .hero-module .description p {
    padding: 0 20px;
  }
}

.hero-module-hci {
  position: relative;
}

.hero-module-hci .image {
  overflow: hidden;
}

.hero-module-hci .image img {
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (max-width: 767.98px) {
  .hero-module-hci .image {
    position: relative;
    margin-bottom: -40px;
  }
  .hero-module-hci .image img {
    height: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hero-module-hci .image {
    height: 350px;
  }
}

@media (max-width: 575.98px) {
  .hero-module-hci .image {
    height: 250px;
  }
}

@media (min-width: 768px) {
  .hero-module-hci .image {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  .hero-module-hci .image img {
    min-height: 530px;
    min-width: 100%;
  }
}

.hero-module-hci .hero-container {
  position: relative;
}

@media (min-width: 768px) {
  .hero-module-hci .hero-container {
    padding: 60px 0 0 0;
  }
}

@media (max-width: 767.98px) {
  .hero-module-hci .hero-container {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .hero-module-hci .hero-container .intro-copy {
    margin-left: -90px;
  }
}

@media (min-width: 992px) {
  .hero-module-hci .hero-container .intro-copy {
    margin-top: 100px;
    margin-left: -50px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-module-hci .hero-container .intro-copy {
    margin-top: 50px;
    margin-left: -25px;
  }
}

.hero-module-hci .hero-container .intro-copy .top-edge {
  position: relative;
  height: 70px;
  margin-right: 70px;
  background: #FFFFFF;
}

.hero-module-hci .hero-container .intro-copy .top-edge:after {
  content: '';
  position: absolute;
  left: 100%;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #FFFFFF;
  border-style: solid;
  border-width: 70px 0 0 70px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-module-hci .hero-container .intro-copy .top-edge {
    height: 40px;
    margin-right: 40px;
  }
  .hero-module-hci .hero-container .intro-copy .top-edge:after {
    border-width: 40px 0 0 40px;
  }
}

.hero-module-hci .hero-container .intro-copy .inner {
  padding: 0 90px 60px;
  background-color: #FFFFFF;
}

.hero-module-hci .hero-container .intro-copy .inner .hero-heading {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .hero-module-hci .hero-container .intro-copy .inner {
    padding: 0 10% 60px;
  }
  .hero-module-hci .hero-container .intro-copy .inner .hero-heading {
    font-size: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-module-hci .hero-container .intro-copy .inner {
    padding: 0 40px 40px;
  }
}

@media (max-width: 767.98px) {
  .hero-module-hci .hero-container .intro-copy .inner {
    padding: 0 20px 40px;
  }
  .hero-module-hci .hero-container .intro-copy .inner .her0-heading {
    margin-bottom: 20px;
  }
}

.hero-module-hcv .dark h1, .hero-module-hcv .dark h2, .hero-module-hcv .dark h3, .hero-module-hcv .dark p {
  color: #fff;
}

.hero-module-hcv .hero {
  height: auto;
  padding-top: 85px;
}

.hero-module-hcv .hero .intro-copy, .hero-module-hcv .hero .hero-video, .hero-module-hcv .hero .contents {
  z-index: 10;
  padding: 0;
}

.hero-module-hcv .hero .hero-video {
  cursor: pointer;
}

.hero-module-hcv .hero .hero-video:hover .play-button:before {
  opacity: 1;
  transform: scale(1);
}

.hero-module-hcv .hero .intro-copy {
  padding-right: 30px;
  position: relative;
  height: auto;
}

.hero-module-hcv .hero .hero-bg-image {
  position: absolute;
}

.hero-module-hcv .hero .hero-bg-image video {
  position: relative;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  background: url("image.jpg") no-repeat;
}

.hero-module-hcv .hero.video {
  padding: 120px 0px;
  background-color: #202C3A;
  position: relative;
  background-position: bottom right;
  background-repeat: no-repeat;
  overflow: hidden;
}

.hero-module-hcv .hero.video .hero-container {
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;
}

.hero-module-hcv .hero.video .hero-heading {
  margin-bottom: 40px;
}

.hero-module-hcv .hero.video .hero-heading:before {
  content: '';
  width: 60px;
  height: 60px;
  border: 15px solid #FFFFFF;
  display: block;
  margin-bottom: 40px;
}

.hero-module-hcv .hero.video .background-square {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
}

.hero-module-hcv .hero.video .background-square svg {
  width: 346px;
  height: 454px;
}

@media (max-width: 767.98px) {
  .hero-module-hcv .hero.video .background-square {
    top: -56px;
    right: -40px;
  }
  .hero-module-hcv .hero.video .background-square svg {
    width: 190px;
    height: 240px;
  }
}

.hero-module-hcv .hero.video .drawing-container {
  width: 750px;
  margin: 0 auto;
  z-index: 3;
  position: absolute;
  top: 30px;
  right: -115px;
}

.hero-module-hcv .hero.video .drawing-container svg {
  background: none;
  border: 0;
  backface-visibility: hidden;
}

.hero-module-hcv .hero.video .path {
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash 15s linear forwards 1;
  fill: none;
  stroke: #303b48;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.hero-module-hcv .hero.video .video-embed {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px;
}

.hero-module-hcv .hero.video .video-embed img {
  width: 100%;
}

.hero-module-hcv .hero.image {
  position: relative;
  height: auto;
}

.hero-module-hcv .hero.image .hero-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: center center/cover no-repeat;
}

.hero-module-hcv .hero.image .hero-bg-image .desktop {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.hero-module-hcv .hero.image .hero-bg-image .mobile {
  display: none;
}

.hero-module-hcv .hero.image .hero-container {
  position: relative;
  height: 100%;
  padding: 0;
}

.hero-module-hcv .hero.image .hero-container .intro-copy .top-edge {
  position: relative;
  height: 70px;
  margin-right: 70px;
  background: #FFF;
}

.hero-module-hcv .hero.image .hero-container .intro-copy .top-edge:after {
  content: '';
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 70px 0 0 70px;
  border-color: transparent transparent transparent #ffffff;
}

.hero-module-hcv .hero.image .hero-container .intro-copy .inner {
  height: auto;
  padding: 25px 10%;
  background: #FFF;
}

.hero-module-hcv .hero.image .hero-container .intro-copy .inner .hero-heading {
  margin-bottom: 40px;
}

@media (max-width: 991.98px) {
  .hero-module-hcv .hero.video {
    padding: 50px 0px;
  }
  .hero-module-hcv .hero .cta-container.action .cta {
    padding: 22px;
  }
}

@media (max-width: 767.98px) {
  .hero-module-hcv .hero.video {
    padding: 20px 20px;
    background-position: bottom left;
    background-size: 100%;
  }
  .hero-module-hcv .hero.video .gradient-box {
    right: -80px;
    top: -60px;
    width: 240px;
    height: 240px;
    padding: 60px;
  }
  .hero-module-hcv .hero.video .gradient-box-inner {
    background-position: -360px -11px;
    width: 100%;
    height: 100%;
  }
  .hero-module-hcv .hero.video .hero-heading {
    margin-bottom: 20px;
  }
  .hero-module-hcv .hero.video .hero-heading:before {
    width: 40px;
    height: 40px;
    border-width: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .hero-module-hcv .hero.video .intro-copy {
    order: 2;
    padding-left: 0;
    padding-right: 0;
  }
  .hero-module-hcv .hero.video .hero-video {
    padding: 0 0 30px 0 !important;
  }
  .hero-module-hcv .hero.image {
    height: auto;
  }
  .hero-module-hcv .hero.image .hero-bg-image {
    height: 235px;
  }
  .hero-module-hcv .hero.image .hero-bg-image .desktop {
    display: none;
  }
  .hero-module-hcv .hero.image .hero-bg-image .mobile {
    display: block;
  }
  .hero-module-hcv .hero.image .hero-container {
    height: auto;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy {
    position: relative;
    bottom: 0;
    height: auto;
    padding: 0;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy .top-edge {
    height: 50px;
    margin-right: 50px;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy .top-edge:before {
    top: -30px;
    left: 20px;
    width: 60px;
    height: 60px;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy .top-edge:after {
    border-width: 50px 0 0 50px;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy .inner {
    height: auto;
    padding: 0 20px;
  }
  .hero-module-hcv .hero.image .hero-container .intro-copy .inner .hero-heading {
    margin-bottom: 30px;
  }
}

/** Begin Hero HSCR **/
.hero-HSCR {
  background-color: #FFFFFF;
  margin: 60px 0 40px 0;
}

.hero-HSCR .intro-copy {
  top: 20px;
}

.hero-HSCR .intro-copy:before {
  border-color: transparent #FFFFFF transparent transparent;
}

.hero-HSCR .intro-copy .hero-section-wrapper {
  padding: 60px 5% 70px 5%;
}

.hero-HSCR .description .hero-section-wrapper {
  margin-top: 20px;
}

.hero-HSCR.neutral {
  background-color: #F6F8F9;
}

.hero-HSCR.neutral .intro-copy:before {
  border-color: transparent #F6F8F9 transparent transparent;
}

.hero-HSCR.dark {
  background-color: #202C3A;
}

.hero-HSCR.dark .intro-copy {
  background-color: #FFFFFF;
}

.hero-HSCR.dark .intro-copy:before {
  border-color: transparent #202C3A transparent transparent;
}

.hero-HSCR.dark .intro-copy .primary-heading {
  color: #164A62;
}

.hero-HSCR.dark .intro-copy .supporting-copy {
  color: #8A8B8C;
}

.hero-HSCR.dark .description {
  color: #FFFFFF;
}

@media (max-width: 1199.98px) {
  .hero-HSCR .intro-copy {
    top: 0px;
  }
  .hero-HSCR .description .hero-section-wrapper {
    margin-top: 0;
  }
}

@media (max-width: 767.98px) {
  .hero-HSCR.dark .description {
    background-color: #202C3A;
  }
  .hero-HSCR.neutral .description {
    background-color: #F6F8F9;
  }
}

/** End Hero HSCR **/
/** Begin Hero HC **/
.hero-HC {
  margin-top: 40px;
  padding: 40px 0;
}

@media (min-width: 768px) {
  .hero-HC {
    padding: 60px 0;
  }
}

@media (min-width: 992px) {
  .hero-HC {
    padding: 80px 0;
  }
}

.hero-HC .primary-heading {
  font-size: 44px;
  line-height: 52px;
}

.hero-HC .supporting-copy {
  padding: 30px 0;
  line-height: 26px;
}

.hero-HC .hero-HC-Gradient {
  height: 4px;
  width: 33%;
  background: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

/** End Hero HC **/
.list-module {
  padding-bottom: 7em;
  background: #F6F8F9;
}

.list-module.dark {
  background-color: #202C3A;
}

.list-module .list-header {
  margin-bottom: -110px;
  padding-bottom: 70px;
}

.list-module ul {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 60px 40px;
  column-count: 2;
  column-gap: 0;
  background-color: #FFFFFF;
  list-style: none;
}

.list-module ul ol {
  padding-left: 0;
}

.list-module ul li {
  font-family: 'Flama Book', sans-serif;
  display: inline-block;
  padding: 0 60px 35px 35px;
  font-size: 16px;
  color: #3D4F63;
  line-height: 22px;
}

.list-module ul li:last-of-type {
  margin-bottom: 0;
}

.list-module ul li strong {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  display: block;
  padding-bottom: 10px;
  font-size: 22px;
  color: #3B94A3;
  line-height: 33px;
}

@media (max-width: 767.98px) {
  .list-module .list-header {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .list-module .list-header .container {
    padding: 40px 20px 20px 20px;
  }
  .list-module ul {
    padding: 10px 20px;
    column-count: 1;
  }
  .list-module ul li {
    display: block;
    margin: 0;
    padding: 15px 0 5px 0;
    border-bottom: 1px solid #f6f6f7;
  }
  .list-module ul li strong {
    font-size: 16px;
    line-height: 24px;
  }
  .list-module ul li p {
    display: none;
  }
  .list-module ul li.active p {
    display: block;
  }
}

.docSelector .docSelector-header {
  display: flex;
  width: 100%;
  padding: 70px 0 40px 0;
  flex-direction: row;
  align-content: inherit;
  justify-content: flex-start;
  align-items: center;
}

.docSelector .docSelector-header .primary-heading {
  flex-basis: 30%;
}

.docSelector .docSelector-header .supporting-copy {
  flex-basis: 30%;
}

.docSelector .docSelector-header .supporting-copy p {
  margin-bottom: 0;
}

.docSelector .container {
  display: flex;
  padding: 30px 5% 80px 5%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-content: inherit;
}

.docSelector .docSelector-list {
  padding-left: 0;
  columns: 340px;
  list-style: none;
  columns: 2;
  border-radius: 5px;
  overflow: hidden;
}

.docSelector .content-label {
  display: block;
  margin: 0 0 20px 0;
  color: rgba(255, 255, 255, 0.5);
}

.docSelector .docSelector-type {
  display: block;
  margin: 0 8px 20px 2px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.docSelector .gradient-border {
  padding: 0;
}

.docSelector .docSelector-doc {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  border-right: 1px solid rgba(225, 232, 237, 0.15);
  border-bottom: 1px solid rgba(225, 232, 237, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.docSelector .docSelector-doc:hover {
  background-image: linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
}

.docSelector .docSelector-doc:first-of-type {
  border-radius: 3px;
  border-top: 1px solid rgba(225, 232, 237, 0.15);
}

.docSelector .docSelector-doc:last-of-type {
  border-radius: 3px;
  border-bottom: 1px solid rgba(225, 232, 237, 0.15);
}

.docSelector .docSelector-doc.active {
  background-image: linear-gradient(157.68deg, rgba(197, 52, 148, 0.14) -0.43%, rgba(59, 148, 163, 0.14) 97.4%);
}

.docSelector .docSelector-doc input {
  position: absolute;
  height: 0;
  width: 0;
  cursor: pointer;
  opacity: 0;
}

.docSelector .docSelector-doc:hover input ~ .checkmark {
  background-color: rgba(225, 232, 237, 0.1);
}

.docSelector .docSelector-doc input:checked ~ .checkmark {
  background-color: #C53494;
  border-color: #C53494;
}

.docSelector .docSelector-doc input:checked ~ .checkmark:after {
  display: block;
}

.docSelector .checkmark {
  position: absolute;
  top: 50%;
  left: 15px;
  height: 34px;
  width: 34px;
  margin-right: 20px;
  background-color: rgba(225, 232, 237, 0.05);
  border: 1px solid rgba(225, 232, 237, 0.4);
  border-radius: 100%;
  transform: translateY(-50%);
}

.docSelector .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 8px;
  left: 12px;
  height: 13px;
  width: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.docSelector .btn-primary {
  margin: 20px 0;
}

.docSelector.light .content-label, .docSelector.neutral .content-label {
  color: #C53494;
}

.docSelector.light .docSelector-doc, .docSelector.light .docSelector-doc:first-of-type, .docSelector.light .docSelector-doc:last-of-type, .docSelector.neutral .docSelector-doc, .docSelector.neutral .docSelector-doc:first-of-type, .docSelector.neutral .docSelector-doc:last-of-type {
  border-color: #DFE7ED;
}

.docSelector.light .docSelector-doc, .docSelector.neutral .docSelector-doc {
  background-color: #FFFFFF;
  color: #164A62;
}

.docSelector.light .docSelector-doc .checkmark, .docSelector.neutral .docSelector-doc .checkmark {
  background-color: #F6F8F9;
  border-color: #C3C5C8;
}

.docSelector.light .docSelector-doc:hover, .docSelector.light .docSelector-doc.active, .docSelector.neutral .docSelector-doc:hover, .docSelector.neutral .docSelector-doc.active {
  background-image: linear-gradient(170.1deg, rgba(197, 52, 148, 0.1) -0.43%, rgba(59, 148, 163, 0.1) 97.4%), #FFFFFF;
  border-color: #DFE7ED;
}

.docSelector.light .docSelector-doc:hover input ~ .checkmark, .docSelector.neutral .docSelector-doc:hover input ~ .checkmark {
  background-color: #FFFFFF;
}

.docSelector.light .docSelector-doc input:checked ~ .checkmark, .docSelector.neutral .docSelector-doc input:checked ~ .checkmark {
  background-color: #C53494;
  border-color: #C53494;
}

.docSelector.light .docSelector-doc input:checked ~ .checkmark:after, .docSelector.neutral .docSelector-doc input:checked ~ .checkmark:after {
  display: block;
}

.docSelector.light .checkmark, .docSelector.neutral .checkmark {
  background: rgba(195, 209, 219, 0.1);
  border: 1px solid #DFE7ED;
}

.docSelector.list-view .docSelector-doc {
  padding: 20px 20px 20px 64px;
}

.docSelector.fourUp-view .checkmark {
  top: 15px;
  right: 15px;
  left: auto;
  margin-right: 0;
  transform: translateY(0);
}

.docSelector.fourUp-view .docSelector-title:before {
  content: url("/assets/images/whitepaper.png");
  display: block;
  height: 27px;
  width: 20px;
  margin-bottom: 20px;
}

.docSelector.fourUp-view .docSelector-doc {
  display: inline-block;
  margin: 3px;
  padding: 20px;
  border: 1px solid #DFE7ED;
  border-radius: 3px;
  flex-basis: calc(50% - 6px);
}

.docSelector.fourUp-view .docSelector-list {
  display: flex;
  flex-wrap: wrap;
}

.docSelector.toolkit-view .docSelector-doc:before {
  content: url("/assets/images/whitepaper.png");
  position: absolute;
  display: inline-block;
  top: 50%;
  height: 27px;
  width: 20px;
  transform: translateY(-50%);
}

.docSelector.toolkit-view .docSelector-doc:hover {
  cursor: default;
  background-image: none;
}

.docSelector.toolkit-view .docSelector-title {
  margin-left: 40px;
}

.docSelector.toolkit-view.light .docSelector-doc:hover {
  cursor: inherit;
  background-image: none;
}

.docSelector.listLegacy-view .docSelector-list {
  columns: 1;
  width: 100%;
}

.docSelector.listLegacy-view .content-label {
  display: inline;
  margin-right: 5px;
}

.docSelector.listLegacy-view .docSelector-doc {
  padding: 20px 20px 20px 60px;
  border-top: 1px solid #dfe7ed;
  border-bottom: 1px solid #dfe7ed;
}

.docSelector.listLegacy-view .docSelector-type {
  margin: 0 8px 0 2px;
}

@media (max-width: 767.98px) {
  .docSelector .docSelector-list {
    columns: 1;
  }
  .docSelector .docSelector-header {
    padding: 0 0 40px;
    align-items: flex-start;
    flex-direction: column;
  }
  .docSelector .docSelector-header .primary-heading, .docSelector .docSelector-header .supporting-copy {
    flex-basis: auto;
  }
  .docSelector .intro-copy {
    padding: 0 0 20px 0;
    flex-direction: column;
  }
  .docSelector .btn-primary {
    margin: 10px auto;
  }
  .docSelector .container {
    padding: 40px 20px;
  }
  .docSelector.fourUp-view .docSelector-doc {
    flex-basis: auto;
  }
}

.image-module {
  padding: 70px 0;
}

.image-module .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .image-module img {
    max-width: 100%;
  }
}

.narration .container {
  display: flex;
  padding: 60px 0;
  flex: 1 50%;
  align-items: center;
}

.narration.list-header .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 75px;
}

.narration .primary-heading {
  padding-right: 30px;
  flex-basis: 50%;
}

.narration .primary-heading.gradient-border {
  padding: 0 30px;
  border-width: 0;
  border-image: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
  border-left: 6px solid;
}

.narration .supporting-copy {
  flex-basis: 50%;
}

.narration .layout-left .supporting-copy {
  padding-left: 30px;
  flex-basis: 50%;
}

.narration .layout-right .supporting-copy {
  padding-right: 30px;
  flex-basis: 50%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .narration.list-header .container {
    display: block;
  }
  .narration .primary-heading {
    flex-basis: 40%;
  }
  .narration .layout-left .supporting-copy {
    flex-basis: 50%;
  }
  .narration .layout-right .supporting-copy {
    flex-basis: 50%;
  }
  .narration .supporting-copy {
    padding: 0 !important;
  }
  .narration.narration-paragraph .container {
    display: block;
  }
  .narration.narration-quote .container {
    display: block;
  }
  .narration.narration-image-HC .container {
    display: block;
  }
  .narration.narration-list .container {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .narration .container {
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .narration.list-header .container {
    padding-left: 20px;
  }
  .narration .primary-heading {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    flex-basis: auto;
  }
  .narration .primary-heading.gradient-border {
    padding: 0 20px;
    border-left-width: 5px;
  }
  .narration .supporting-copy {
    padding: 0;
  }
}

.narration-CTA {
  text-align: center;
  padding: 60px 0;
}

.narration-CTA .btn {
  margin-top: 15px;
}

.narration-image-HC .container {
  display: flex;
  flex-direction: column;
}

.narration-image-HC .text-block {
  align-self: flex-start;
  padding-bottom: 30px;
}

.narration-image-HC .image-block {
  max-width: 100%;
  height: auto;
}

.narration-list {
  /*.list-block ul {
    font-size: 28px;
    @include flamasemi-book;
    color: $teal;
    li {
      h3{
        @include flamasemi-book;
        color: $teal;
      }
      p {
        color: $dark-charcoal;
      }
    }
  }*/
}

.narration-list .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.narration-list .list-block ol, .narration-list ul {
  font-size: 26px;
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  color: #3B94A3;
}

.narration-list .list-block ol li h3, .narration-list ul li h3 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  color: #3B94A3;
  font-size: 26px;
}

.narration-list .list-block ol li p, .narration-list ul li p {
  color: #202C3A;
}

.narration-list .two-col {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 2em;
  -moz-column-gap: 2em;
  column-gap: 2em;
}

.narration-list .ol-letter {
  list-style-type: upper-alpha;
}

.narration-list .ol-numbered {
  list-style-type: decimal;
}

.narration-list.dark .list-block ol {
  color: #FFFFFF;
}

.narration-list.dark .list-block ol li h3 {
  color: #FFFFFF;
}

.narration-list.dark .list-block ol li p {
  color: #FFFFFF;
}

.narration-list.dark .list-block ul {
  color: #FFFFFF;
}

.narration-list.dark .list-block ul li h3 {
  color: #FFFFFF;
}

.narration-list.dark .list-block ul li p {
  color: #FFFFFF;
}

.narration-logos {
  padding: 60px 0;
}

.narration-logos h3 {
  font-size: 16px;
  text-align: center;
}

.narration-logos img {
  max-height: 200px;
  max-width: 200px;
  margin: 30px;
}

.narration-paragraph .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.narration-paragraph .paragraph-heading {
  font-size: 28px;
  line-height: 33px;
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
}

.narration-paragraph .gradient-line {
  height: 1px;
  width: 80px;
  margin: 10px 0;
  background-image: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

.narration-quote .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.narration-quote .supporting-copy {
  padding: 0;
}

.narration-quote .quote-text {
  position: relative;
}

.narration-quote .quote-text:before {
  content: '\201C';
  color: #C53494;
  position: absolute;
  font-size: 72px;
  left: -30px;
  top: -10px;
}

.narration-quote .quote-text p {
  font-size: 28px;
  line-height: 47px;
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
}

.narration-quote .gradient-line {
  height: 1px;
  width: 80px;
  margin: 10px 0;
  background-image: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

.narration-quote .quote-author {
  font-size: 22px;
  line-height: 30px;
  color: #3B94A3;
}

.narration-quote .quote-author .author-title {
  font-weight: 600;
}

.narration-quote.dark .quote-text {
  color: #FFFFFF;
}

.narration-quote.dark .quote-author {
  color: #FFFFFF;
}

.narration-quote.neutral {
  background-image: linear-gradient(130deg, #C53494 0%, #3B94A3 100%);
}

.narration-quote.neutral .quote-text {
  color: #FFFFFF;
}

.narration-quote.neutral .quote-text:before {
  color: #FFFFFF;
}

.narration-quote.neutral .gradient-line {
  background-image: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
}

.narration-quote.neutral .quote-author {
  color: #FFFFFF;
}

@media (max-width: 991.98px) {
  .narration-quote .quote-text {
    position: relative;
    margin-top: 15px;
  }
  .narration-quote .quote-text:before {
    position: absolute;
    left: 0px;
    top: -15px;
  }
  .narration-quote .quote-text p {
    font-size: 20px;
    line-height: 30px;
    font-family: 'FlamaSemicondensed Book', sans-serif;
    font-style: normal;
  }
  .narration-quote .supporting-copy {
    padding: 0;
  }
  .narration-quote .quote-author .author-title {
    font-weight: 600;
    font-family: 'FlamaSemicondensed Light', sans-serif;
    font-style: normal;
  }
  .narration-list .two-col {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }
}

.locations-carousel .carousel-list {
  margin: 0 auto;
}

.locations-carousel .carousel-item {
  margin: 0;
  border-right: 1px solid #4F5661;
}

@media (max-width: 991.98px) {
  .locations-carousel .carousel-item {
    margin: 0 5px;
  }
}

.locations-carousel .carousel-item:last-of-type {
  margin-right: 0;
}

.locations-carousel .carousel-item:hover .location-info-overlay {
  cursor: pointer;
  opacity: 1;
}

.locations-carousel .carousel-item:hover .location-text {
  opacity: 0;
}

.locations-carousel .carousel-item .location-description, .locations-carousel .carousel-item .location-info-overlay {
  height: 450px;
  width: 100%;
}

.locations-carousel .carousel-item .location-city {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
  margin-bottom: 6px;
  font-size: 26px;
  line-height: 32px;
}

.locations-carousel .carousel-item .location-country {
  font-family: 'Flama Book', sans-serif;
  font-size: 10px;
  letter-spacing: 0.2em;
  line-height: 10px;
  text-transform: uppercase;
}

.locations-carousel .carousel-item .location-address, .locations-carousel .carousel-item .location-phone {
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
  opacity: 0.7;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 24px;
}

.locations-carousel .carousel-item .location-description {
  position: relative;
  background-color: #3B94A3;
}

.locations-carousel .carousel-item .location-description .location-photo {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: #202C3A;
  justify-content: center;
  align-items: center;
}

.locations-carousel .carousel-item .location-description .location-photo img {
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  height: auto;
  width: 100%;
}

.locations-carousel .carousel-item .location-description .location-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px 30px 30px 30px;
  background-color: #202C3A;
  color: #FFFFFF;
  transition: all 300ms ease;
}

.locations-carousel .carousel-item .location-info-overlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  padding: 30px;
  opacity: 0;
  background-color: rgba(32, 44, 58, 0.95);
  color: #FFFFFF;
  transition: all 200ms ease;
  flex-direction: column;
  justify-content: flex-end;
}

.locations-carousel .carousel-item .location-info-overlay:after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(90deg, #CF2C98 0%, #52C0D3 100%);
}

.locations-carousel .carousel-item .location-info-overlay .location-country {
  margin-bottom: 20px;
}

.locations-carousel .carousel-item .location-info-overlay .location-phone {
  position: relative;
  margin: 10px 0;
  padding-left: 20px;
}

.locations-carousel .carousel-item .location-info-overlay .location-phone:before {
  -moz-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  height: 12px;
  width: 12px;
  background-image: url("/assets/images/phone-small.png");
  background-repeat: no-repeat;
  background-size: 12px 11.12px;
}

.locations-carousel .carousel-item .location-info-overlay .cta-container.text-only {
  color: #FFFFFF;
}

.locations-carousel .carousel-item .location-info-overlay .cta-container.text-only .cta-text {
  display: inline-block;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.locations-carousel .carousel-item .location-info-overlay .cta-container.text-only svg path {
  fill: #FFFFFF;
}

.locations-carousel .carousel-item .location-info-overlay .cta-container.text-only:hover, .locations-carousel .carousel-item .location-info-overlay .cta-container.text-only:hover {
  color: #FFFFFF;
}

.sideSide .layout-left, .sideSide.layout-left {
  flex-direction: column;
}

@media (min-width: 768px) {
  .sideSide .layout-left, .sideSide.layout-left {
    flex-direction: row;
  }
}

.sideSide .layout-right, .sideSide.layout-right {
  flex-direction: column;
}

@media (min-width: 768px) {
  .sideSide .layout-right, .sideSide.layout-right {
    flex-direction: row-reverse;
  }
}

.sideSide-ROI .cube-stack {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sideSide-ROI .cube-stack svg {
  position: absolute;
  left: -200px;
  top: -250px;
}

.sideSide-ROI .btn-primary {
  margin-top: 20px;
}

.two-col-image-text-promo {
  padding: 20px 5px 40px 5px;
}

.two-col-image-text-promo .secondary-heading {
  color: #3B94A3;
  margin-bottom: 15px;
}

.two-col-image-text-promo .image {
  margin-bottom: 30px;
}

.two-col-image-text-promo .image img {
  width: 100%;
}

.two-col-image-text-promo .content {
  position: relative;
}

.two-col-image-text-promo .inner-content {
  width: 100%;
}

@media (min-width: 768px) {
  .two-col-image-text-promo {
    padding: 60px 0;
  }
  .two-col-image-text-promo .secondary-heading {
    margin-bottom: 30px;
  }
  .two-col-image-text-promo .image {
    margin-bottom: 0;
  }
  .two-col-image-text-promo .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .two-col-image-text-promo.flip .image img {
    margin: 0 20px 0 0;
    float: right;
  }
}

.narrationCards {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .narrationCards {
    padding: 45px 0;
  }
}

@media (min-width: 992px) {
  .narrationCards {
    padding: 60px 0;
  }
}

.narrationCards h4 {
  font-size: 18px;
  line-height: 24px;
}

.narrationCards p, .narrationCards blockquote {
  margin-bottom: 0;
  width: 100%;
}

.narrationCards .card-wrapper {
  margin-top: 10px;
}

.narrationCards .card-wrapper .col {
  flex-basis: auto;
  margin-bottom: 15px;
}

.narrationCards .card-wrapper .col:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .narrationCards .card-wrapper .col {
    margin-bottom: 0;
  }
}

.narrationCards .card {
  padding: 30px;
  border-radius: 5px;
  align-items: flex-start;
  height: 100%;
}

.narrationCards.light h3 {
  color: #3B94A3;
}

.narrationCards.light .card {
  border: 1px solid rgba(78, 78, 80, 0.15);
}

.narrationCards.neutral h3 {
  color: #3B94A3;
}

.narrationCards.neutral .card {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
}

.narrationCards.dark h3, .narrationCards.dark h4 {
  color: #FFFFFF;
}

.narrationCards.dark .card {
  background-color: #263240;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(214, 223, 227, 0.15);
}

.narrationCards-info .icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .narrationCards-info .icon {
    width: 80px;
    height: 80px;
  }
}

.narrationCards-info .icon img {
  flex: 0 0 auto;
}

.narrationCards-info h4 {
  font-size: 22px;
  line-height: 30px;
  width: 100%;
}

.narrationCards-quote .quotemark {
  font-size: 80px;
  color: #C53494;
  margin: 10px 0 0 -15px;
}

.narrationCards-quote blockquote {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  font-size: 28px;
  line-height: 33px;
}

.narrationCards-quote .rule {
  width: 33.333%;
  height: 2px;
  margin: 20px 0;
  background: linear-gradient(90deg, #C53494 0%, #3B94A3 100%);
}

.narrationCards-quote .author {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
  font-size: 16px;
}

.narrationCards-quote .logo {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .narrationCards-quote .logo {
    height: 60px;
  }
}

.narrationCards-quote .logo img {
  max-height: 60px;
  max-width: 120px;
}

.narrationCards-HCBI .card {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 15px;
}

.narrationCards-HCBI .card:last-child {
  margin-bottom: 0;
}

.narrationCards-HCBI .card h3, .narrationCards-HCBI .card h4 {
  font-size: 32px;
  line-height: 36px;
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
}

.narrationCards-HCBI .card p {
  font-size: 28px;
  line-height: 32px;
  font-family: 'FlamaSemicondensed Light', sans-serif;
  font-style: normal;
}

.narrationCards-HCBI .card .btn {
  margin-top: 50px;
}

.narrationCards-HCBI h1, .narrationCards-HCBI h2, .narrationCards-HCBI h3, .narrationCards-HCBI h4, .narrationCards-HCBI h5, .narrationCards-HCBI h6, .narrationCards-HCBI p {
  color: #FFFFFF;
}

.narrationCards-links h4 {
  color: #164A62;
  font-size: 22px;
  line-height: 30px;
}

.narrationCards-links .card h4 {
  margin-top: 30px;
}

.narrationCards-links .card .icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.narrationCards-links .card .icon img {
  max-width: 100%;
  max-height: 50px;
}

.narrationCards-links .card .cta-container {
  margin-top: auto;
}

.narrationCards-links .card .cta-container .cta-text {
  color: #C53494;
  border-bottom: 1px solid #C53494;
}

.narrationCards-links.dark .card .cta-container .cta-text {
  color: #FFFFFF;
}

.topic-selector {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}

.topic-selector .indicator {
  width: 12px;
  height: 12px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("/images/default-source/mcui-design-system/ui/ui-icon-plus-purple.png");
}

.topic-selector .active-item .indicator {
  background-image: url("/images/default-source/mcui-design-system/ui/ui-icon-minus-purple.png");
}

.topic-selector .box-graphic {
  position: absolute;
  width: 200px;
  top: -55px;
  right: -70px;
}

.topic-selector h2 {
  margin: 0 0 20px 0;
}

.topic-selector .topic-selector-item {
  border-bottom: 1px solid rgba(22, 74, 98, 0.3);
  padding: 15px 0;
}

.topic-selector .topic-selector-item:last-child {
  border-bottom: none;
}

.topic-selector .tab-wrapper {
  cursor: pointer;
}

.topic-selector .item-number {
  font-family: 'Flama Book', sans-serif;
  font-size: 10px;
  color: rgba(22, 74, 98, 0.5);
  margin: 5px 15px 0 0;
  transition: all 100ms ease;
}

.topic-selector .tab-label {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  color: #4E4E50;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  padding-right: 15px;
}

.topic-selector .bucket {
  margin-top: 20px;
  display: none;
}

.topic-selector .bucket .cta-container {
  margin-top: 25px;
}

.topic-selector .bucket .video-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.topic-selector .bucket .video-embed {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 56.25%;
}

.topic-selector .bucket .video-embed video {
  width: 100%;
}

.topic-selector .bucket .video-embed img {
  margin-bottom: 25px;
}

.topic-selector .bucket .promo-item {
  border: none;
  margin-top: 0;
}

.topic-selector .bucket .promo-item .slug {
  color: #AAA;
}

.topic-selector .bucket .promo-item h4 a {
  color: #FFF;
}

.topic-selector .bucket .promo-item .download-button {
  background: #263240;
  border: 1px solid rgba(225, 232, 237, 0.15);
}

.topic-selector .bucket img {
  margin-bottom: 20px;
}

.topic-selector .bucket p {
  color: #4E4E50;
}

.topic-selector .topic-selector-content .bucket {
  display: block;
}

.topic-selector.dark h2 {
  color: #FFFFFF;
}

.topic-selector.dark .tab-label {
  color: #FFFFFF;
}

.topic-selector.dark .item-number {
  color: #F6F8F9;
}

.topic-selector.dark .bucket p {
  color: #FFFFFF;
}

@media (min-width: 768px) {
  .topic-selector .box-graphic {
    width: 350px;
    top: -50px;
    right: -70px;
  }
}

@media (min-width: 992px) {
  .topic-selector {
    padding: 60px 0;
  }
  .topic-selector .box-graphic {
    width: auto;
    top: -20px;
    right: -230px;
  }
  .topic-selector h2 {
    margin: 0 0 40px 0;
  }
  .topic-selector .topic-selector-item {
    border-bottom-color: transparent;
    padding: 5px 0;
    margin-bottom: 15px;
  }
  .topic-selector .topic-selector-item .indicator {
    background-image: none;
  }
  .topic-selector .topic-selector-item:last-child {
    border-bottom: 1px solid transparent;
    margin-bottom: 0;
  }
  .topic-selector .tab-label {
    color: #8A8B8C;
    transition: all 200ms ease;
  }
  .topic-selector .bucket {
    margin-top: 0;
  }
  .topic-selector.light .topic-selector-item.active-item, .topic-selector.neutral .topic-selector-item.active-item {
    border-bottom-color: rgba(22, 74, 98, 0.5);
  }
  .topic-selector.light .topic-selector-item.active-item .tab-label, .topic-selector.light .topic-selector-item.active-item .item-number, .topic-selector.neutral .topic-selector-item.active-item .tab-label, .topic-selector.neutral .topic-selector-item.active-item .item-number {
    color: #164A62;
  }
  .topic-selector.light .topic-selector-item:hover .tab-label, .topic-selector.light .topic-selector-item:hover .item-number, .topic-selector.neutral .topic-selector-item:hover .tab-label, .topic-selector.neutral .topic-selector-item:hover .item-number {
    color: #164A62;
  }
  .topic-selector.light .active-item .indicator, .topic-selector.neutral .active-item .indicator {
    background-image: url("/images/default-source/mcui-design-system/ui/ui-icon-arrow-right-dark-teal.png");
  }
  .topic-selector.dark .topic-selector-item.active-item {
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }
  .topic-selector.dark .topic-selector-item.active-item .tab-label, .topic-selector.dark .topic-selector-item.active-item .item-number {
    color: #FFFFFF;
  }
  .topic-selector.dark .topic-selector-item:hover .tab-label, .topic-selector.dark .topic-selector-item:hover .item-number {
    color: #FFFFFF;
  }
  .topic-selector.dark .tab-label {
    color: #8A8B8C;
  }
  .topic-selector.dark .item-number {
    color: rgba(138, 139, 140, 0.5);
  }
  .topic-selector.dark .active-item .indicator {
    background-image: url("/assets/images/ui-graphic-boxes-gradient.png");
  }
}

.about-us.dark .featured-event-title:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  border-color: #202C3A transparent transparent transparent;
  border-style: solid;
  border-width: 50px 0 0 50px;
}

.about-us.dark .featured-event-title .secondary-heading {
  color: #3D4F63;
}

.about-us .gradient-carousel .primary-heading {
  flex-basis: auto;
  flex-grow: 1;
}

.about-us .featured-event-title-container, .about-us .featured-content {
  max-width: 1366px;
}

.about-us .featured-event-title {
  position: relative;
  display: flex;
  margin: 0;
  padding: 40px 60px;
  background-color: #F6F8F9;
  justify-content: space-between;
  align-items: center;
}

.about-us .featured-event-title:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  border-width: 50px 0 0 50px;
}

@media (max-width: 767.98px) {
  .about-us .featured-event-title:after {
    border-width: 40px 0 0 40px;
  }
}

.about-us .featured-event-title .secondary-heading {
  margin: 0;
  line-height: normal;
}

.about-us .featured-event-title .cta-container {
  margin: 0;
  padding: 0;
}

.about-us .featured-event-title .cta-container.text-only .cta-text {
  color: #C53494;
}

@media (max-width: 767.98px) {
  .about-us .featured-event-title {
    padding: 30px 50px 30px 30px;
  }
}

.about-us .featured-content-wrapper {
  border-top: 1px solid #D6DFE3;
  border-bottom: 1px solid #D6DFE3;
}

.contact-us strong {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

.contact-us .hero {
  background-color: #202C3A;
  background-image: url("/assets/images/boxes.svg");
  background-repeat: no-repeat;
  background-position: 180% center;
  background-size: 70% 110%;
  margin: 65px 0 30px;
  padding: 30px 15px;
}

.contact-us .hero h1, .contact-us .hero h2 {
  color: #FFFFFF;
  text-align: center;
}

.contact-us .hero h2 {
  margin-top: 30px;
}

.contact-us .hero a, .contact-us .hero a:hover, .contact-us .hero a:focus {
  color: #FFFFFF;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .contact-us .hero {
    padding: 100px 0 20px;
    background-position: 120% center;
    background-size: contain;
  }
  .contact-us .hero h1, .contact-us .hero h2 {
    text-align: left;
  }
  .contact-us .hero h2 {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .contact-us .hero {
    background-position: 100% center;
    background-size: 30% 120%;
  }
}

.contact-us .contact-form h3 {
  margin-bottom: 15px;
}

.contact-us .sidebar .manager {
  margin: 20px 0;
}

@media (max-width: 991.98px) {
  .contact-us .sidebar {
    margin-top: 60px;
  }
}

.contact-us .location {
  margin-bottom: 30px;
}

.contact-us .location h3 {
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
  color: #202C3A;
  margin: 20px 0;
}

.contact-us .location .content-label {
  color: #C53494;
}

.contact-us .location ul {
  padding: 0;
  margin-top: 30px;
  font-size: 16px;
  list-style-type: none;
  color: #C53494;
  font-family: 'FlamaSemicondensed Book', sans-serif;
  font-style: normal;
}

.contact-us .location:last-child {
  margin-bottom: 0;
}

.contact-us .locations {
  border-top: solid 1px #f1f1f1;
  padding-top: 60px;
  margin: 60px 0;
}

.contact-us-thanks strong {
  font-family: 'FlamaSemicondensed Semibold', sans-serif;
  font-style: normal;
}

.contact-us-thanks .hero-background {
  background-image: linear-gradient(to right, #7B266C, #D01E51);
}

.contact-us-thanks .hero {
  background-image: url("/assets/images/boxes-white.svg");
  background-repeat: no-repeat;
  background-position: 180% center;
  background-size: 70% 110%;
  margin: 65px 0 30px;
  padding: 30px 15px;
}

.contact-us-thanks .hero h1, .contact-us-thanks .hero h2 {
  color: #FFFFFF;
  text-align: center;
}

.contact-us-thanks .hero h2 {
  margin-top: 30px;
}

.contact-us-thanks .hero a, .contact-us-thanks .hero a:hover, .contact-us-thanks .hero a:focus {
  color: #FFFFFF;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .contact-us-thanks .hero {
    padding: 100px 0 20px;
    background-position: 120% center;
    background-size: contain;
  }
  .contact-us-thanks .hero h1, .contact-us-thanks .hero h2 {
    text-align: left;
  }
  .contact-us-thanks .hero h2 {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .contact-us-thanks .hero {
    background-position: 100% center;
    background-size: 30% 120%;
  }
}

.contact-us-thanks .mediaGrid {
  padding: 0;
}

.contact-us-thanks .mediaGrid h3 {
  color: #164A62;
  margin-top: 50px;
}

.contact-us-thanks .mediaGrid .content-grid {
  margin-top: 10px;
  margin-bottom: 50px;
}

.contact-us-thanks .mediaGrid .content-grid .large.card .image-container {
  height: 300px;
}

.news {
  padding-top: 30px;
  padding-bottom: 30px;
}

.news .overview {
  padding-top: 60px;
}

.news .news-contact {
  margin-bottom: 30px;
}

.news .news-item {
  margin-top: 15px;
}

.news .news-item p {
  margin: 0;
}

.news li, .news a {
  font-size: 16px;
}

.news .archive-cta {
  margin-top: 30px;
}

.news .news-pagination {
  margin-top: 30px;
}

.error404 {
  padding: 120px 0;
}

.error404-svg {
  padding: 20px 0;
}

.text404 > p > a {
  color: #3B94A3 !important;
}

#search404icon {
  position: absolute;
  right: 30px;
  top: 20px;
}

#searchterm404 {
  font-size: 1.4rem;
  border-radius: 0;
  outline: none;
  border: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 767px) {
  .search404 {
    padding-top: 20px;
  }
  .text404 {
    text-align: center;
  }
  #search404icon {
    top: 40px;
  }
  .card-heading {
    text-align: center;
  }
  .card-row {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 426px) {
  .error404 {
    padding: 55px 0;
  }
  .error404-svg > svg {
    width: 180px;
    height: auto;
  }
  .text404 {
    text-align: center;
  }
  .card-row {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero-module .intro-section .hero-section-wrapper {
    width: 100%;
  }
  .hero-module .intro-copy .hero-section-wrapper {
    width: 100%;
  }
  .cta-container .cta-text-group {
    max-width: 100%;
    padding-right: 85px;
    margin-right: -75px;
  }
  .circle-button:before,
  .play-button:before {
    top: -1px;
    left: -1px;
  }
  .inner-modal .populated input {
    padding-top: 22px !important;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 768px), all and (-ms-high-contrast: active) and (min-width: 768px) {
  .hero-module .intro-copy {
    max-width: 50%;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 1500px), all and (-ms-high-contrast: active) and (min-width: 1500px) {
  .hero-module .intro-copy {
    max-width: 700px;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 992px), all and (-ms-high-contrast: active) and (min-width: 992px) {
  .docSelector .docSelector-list {
    columns: 2;
    max-width: 100%;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 991px), all and (-ms-high-contrast: active) and (max-width: 991px) {
  .docSelector .docSelector-list {
    columns: 1;
    max-width: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fourUp-view.docSelector .docSelector-list {
    width: 100%;
  }
  .contact-module .contact-copy {
    max-width: 45%;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 991px), all and (-ms-high-contrast: active) and (max-width: 991px) {
  .contact-module .contact-copy {
    max-width: 100%;
  }
}

@media all and (-ms-high-contrast: none) and (max-width: 767px), all and (-ms-high-contrast: active) and (max-width: 767px) {
  .narration .container {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .narration .supporting-copy {
    flex-basis: auto;
    padding: 30px;
    width: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer .top-section .site-links {
    justify-content: space-around;
  }
}
