.topic-selector {
  padding: 40px 0;
  position: relative;
  overflow: hidden;

  .indicator {
    width: 12px;
    height: 12px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('/images/default-source/mcui-design-system/ui/ui-icon-plus-purple.png');
  }

  .active-item {

    .indicator {
      background-image: url('/images/default-source/mcui-design-system/ui/ui-icon-minus-purple.png');
    }
  }

  .box-graphic {
    position: absolute;
    width: 200px;
    top: -55px;
    right: -70px;
  }

  h2 {
    margin: 0 0 20px 0;
  }

  .topic-selector-item {
    border-bottom: 1px solid rgba($dark-teal,0.3);
    padding: 15px 0; // Sets top and bottom padding around accorion menu items

    &:last-child {
      border-bottom: none;
    }
  }

  .tab-wrapper {
    cursor: pointer;
  }

  .item-number {
    @include flama-book;
    font-size: 10px;
    color: rgba($dark-teal,0.5);
    margin: 5px 15px 0 0;
    transition: all 100ms ease;
  }

  .tab-label {
    @include flamasemi-book;
    color: $dark-gray;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    padding-right: 15px;
  }

  .bucket {
    margin-top: 20px;
    display: none;

    .cta-container {
      margin-top: 25px;
    }

    .video-bg {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    
    .video-embed {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 56.25%;

      video {
        width: 100%;
      }

      & img {
        margin-bottom: 25px;
      }
    }

    .promo-item {
      border: none;
      margin-top: 0;
      
      .slug {
        color: #AAA;
      }
      
      h4 {
        a {
          color: #FFF;
        }
      }
      
      .download-button {
        background: #263240;
        border: 1px solid rgba(225, 232, 237, 0.15);
      }
    }

    img {
      margin-bottom: 20px;
    }

    p {
      color: $dark-gray;
    }
  }

  .topic-selector-content {
    
    .bucket {
      display: block;
    }
  }

  &.dark {
    h2 { color: $white; }
    .tab-label { color: $white; }
    .item-number { color: $light-gray; }
    .bucket {
      p { color: $white; }
    }
  }
}

@include media-breakpoint-up(md) {
  .topic-selector {
   
    .box-graphic {
      width: 350px;
      top: -50px;
      right: -70px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .topic-selector {
    padding: 60px 0;

    .box-graphic {
      width: auto;
      top: -20px;
      right: -230px;
    }

    h2 {
      margin: 0 0 40px 0;
    }
    
    .topic-selector-item {
      border-bottom-color: transparent;
      padding: 5px 0;
      margin-bottom: 15px;

      .indicator {
        background-image: none;
      }

      &:last-child {
        border-bottom: 1px solid transparent;
        margin-bottom: 0;
      }
    }

    .tab-label {
      color: $mid-gray;
      transition: all 200ms ease;
    }

    .bucket {
      margin-top: 0;
    }

    &.light,
    &.neutral {
      .topic-selector-item {

        &.active-item {
          border-bottom-color: rgba($dark-teal,0.5);

          .tab-label, .item-number {
            color: $dark-teal;
          }
        }

        &:hover {

          .tab-label, .item-number {
            color: $dark-teal;
          }
        }
      }

      .active-item {

        .indicator {
          background-image: url('/images/default-source/mcui-design-system/ui/ui-icon-arrow-right-dark-teal.png');
        }
      }
    }

    &.dark {
      
      .topic-selector-item {

        &.active-item { 
          border-bottom-color: rgba($white,0.5);
  
          .tab-label, .item-number {
            color: $white;
          }
        }

        &:hover {

          .tab-label, .item-number {
            color: $white;
          }
        }
      }

      .tab-label {
        color: $mid-gray;
      }

      .item-number {
        color: rgba($mid-gray,0.5);
      }

      .active-item {

        .indicator {
          background-image: url('/assets/images/ui-graphic-boxes-gradient.png');
        }
      }
    }
  }
}