
.list-module {
  padding-bottom : 7em;
  background     : $light-gray-web;

  &.dark {
    background-color : $dark-charcoal;
  }

  .list-header {
    margin-bottom  : -110px;
    padding-bottom : 70px;
  }

  ul {
    position         : relative;
    z-index          : 10;

    margin           : 0 auto;
    padding          : 60px 40px;
    column-count     : 2;
    column-gap       : 0;

    background-color : $white;
    list-style       : none;

    ol {
      padding-left: 0;
    }

    li {
      @include flama-book;

      display     : inline-block;

      padding     : 0 60px 35px 35px;

      font-size   : 16px;
      color       : $charcoal;
      line-height : 22px;

      &:last-of-type{
        margin-bottom : 0;
      }
      strong {
        @include flamasemi-basic;

        display        : block;

        padding-bottom : 10px;

        font-size      : 22px;
        color          : $teal;
        line-height    : 33px;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .list-module {
    .list-header {
      margin-bottom  : 0;
      padding-bottom : 0;

      .container {
        padding : 40px 20px 20px 20px;
      }
    }
    ul {
      padding      : 10px 20px;
      column-count : 1;

      li {
        display       : block;

        margin        : 0;
        padding       : 15px 0 5px 0;

        border-bottom : 1px solid #f6f6f7;

        strong {
          font-size   : 16px;
          line-height : 24px;
        }

        p {
          display : none;
        }

        &.active {
          p {
            display : block;
          }
        }
      }
    }
  }
}