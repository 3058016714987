// Shared solution marker setup
.solution-marker {
  width: 60px;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    width: 80px;
  }

  .shape {
    background: $gradient-corner;
    border-radius: 5px;
    height: 60px;
    width: 60px;
    @include media-breakpoint-up(md) {
      height: 80px;
      width: 80px;
    }

    abbr {
      text-align: center;
      color: $white;
      @include flamasemi-ultralight;
      font-size: 32px;
      font-weight: 100;
      text-decoration: none !important;
      cursor: default!important;

      @include media-breakpoint-up(md) {
        font-size: 50px;
      }
    }
  }

  .solution-name {
    color: $light-gray-mcui;
    @include flamasemi-basic;
    text-align: center;
    font-size: 10px;

    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }
}

// Custom offsetting per template
.offset-marker {
  .solution-marker {
    position: absolute;
    top: -40px;
    margin: 0;
  }
}