//IE 10 - 11 Fixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // Without this the rows are min-width: 0
  // .row {
  //   width: 100%;
  // }

  .hero-module {
    .intro-section {
      .hero-section-wrapper {
        width: 100%;
      }
    }
  }

  .hero-module .intro-copy .hero-section-wrapper {
    width: 100%;
  }

  .cta-container .cta-text-group {
    max-width: 100%;
    padding-right: 85px;
    margin-right: -75px;
  }

  .circle-button:before,
  .play-button:before {
    top: -1px;
    left: -1px;
  }

  .inner-modal .populated input {
    padding-top: 22px !important;
  }

  @media all and (min-width: 768px) {
    .hero-module .intro-copy {
        max-width: 50%;
    }
  }
  @media all and (min-width:1500px) {
    .hero-module .intro-copy {
        max-width: 700px;
    }
  }

  @media all and (min-width:992px) {
    .docSelector .docSelector-list {
      columns: 2;
      max-width: 100%;
    }
  }

  @media all and (max-width:991px) {
    .docSelector .docSelector-list {
      columns: 1;
      max-width: 100%;
    }
  }

  .fourUp-view.docSelector .docSelector-list {
    width: 100%;
  }

  .contact-module .contact-copy {
    max-width: 45%;
  }

  @media all and (max-width:991px) {
    .contact-module .contact-copy {
      max-width: 100%;
    }
  }

  @media all and (max-width:767px) {
    .narration .container {
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .narration .supporting-copy {
      flex-basis: auto;
      padding: 30px;
      width: 100%;
    }
  }

  //Footer site links fix
  .footer .top-section .site-links {
    justify-content : space-around;
  }
}