// @font-face {
//   font-family: 'Flama Basic';
//   src: url('../../assets/fonts/Flama-Basic.eot') format('embedded-opentype'),
//        url('../../assets/fonts/Flama-Basic.woff2') format('woff2'),
//        url('../../assets/fonts/Flama-Basic.woff') format('woff'),
//        url('../../assets/fonts/Flama-Basic.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Flama Black';
//   src: url('../../assets/fonts/Flama-Black.eot') format('embedded-opentype'),
//        url('../../assets/fonts/Flama-Black.woff2') format('woff2'),
//        url('../../assets/fonts/Flama-Black.woff') format('woff'),
//        url('../../assets/fonts/Flama-Black.ttf') format('truetype');
// }

@font-face {
  font-family: 'Flama Bold';
  src: url('../../assets/fonts/Flama-Bold.eot') format('embedded-opentype'),
       url('../../assets/fonts/Flama-Bold.woff2') format('woff2'),
       url('../../assets/fonts/Flama-Bold.woff') format('woff'),
       url('../../assets/fonts/Flama-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Flama Book';
  src: url('../../assets/fonts/Flama-Book.eot') format('embedded-opentype'),
       url('../../assets/fonts/Flama-Book.woff2') format('woff2'),
       url('../../assets/fonts/Flama-Book.woff') format('woff'),
       url('../../assets/fonts/Flama-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Flama Extrabold';
  src: url('../../assets/fonts/Flama-Extrabold.eot') format('embedded-opentype'),
       url('../../assets/fonts/Flama-Extrabold.woff2') format('woff2'),
       url('../../assets/fonts/Flama-Extrabold.woff') format('woff'),
       url('../../assets/fonts/Flama-Extrabold.ttf') format('truetype');
}

@font-face {
  font-family: 'Flama Light';
  src: url('../../assets/fonts/Flama-Light.eot') format('embedded-opentype'),
       url('../../assets/fonts/Flama-Light.woff2') format('woff2'),
       url('../../assets/fonts/Flama-Light.woff') format('woff'),
       url('../../assets/fonts/Flama-Light.ttf') format('truetype');
}

// @font-face {
//   font-family: 'Flama Medium';
//   src: url('../../assets/fonts/Flama-Medium.eot') format('embedded-opentype'),
//        url('../../assets/fonts/Flama-Medium.woff2') format('woff2'),
//        url('../../assets/fonts/Flama-Medium.woff') format('woff'),
//        url('../../assets/fonts/Flama-Medium.ttf') format('truetype');
// }

@font-face {
  font-family: 'Flama Semibold';
  src: url('../../assets/fonts/Flama-Semibold.eot') format('embedded-opentype'),
       url('../../assets/fonts/Flama-Semibold.woff2') format('woff2'),
       url('../../assets/fonts/Flama-Semibold.woff') format('woff'),
       url('../../assets/fonts/Flama-Semibold.ttf') format('truetype');
}

// @font-face {
//   font-family: 'Flama Thin';
//   src: url('../../assets/fonts/Flama-Thin.eot') format('embedded-opentype'),
//        url('../../assets/fonts/Flama-Thin.woff2') format('woff2'),
//        url('../../assets/fonts/Flama-Thin.woff') format('woff'),
//        url('../../assets/fonts/Flama-Thin.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Flama Ultralight';
//   src: url('../../assets/fonts/Flama-Ultralight.eot') format('embedded-opentype'),
//        url('../../assets/fonts/Flama-Ultralight.woff2') format('woff2'),
//        url('../../assets/fonts/Flama-Ultralight.woff') format('woff'),
//        url('../../assets/fonts/Flama-Ultralight.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'FlamaSemicondensed Basic';
//   src: url('../../assets/fonts/FlamaSemicondensed-Basic.eot') format('embedded-opentype'),
//        url('../../assets/fonts/FlamaSemicondensed-Basic.woff2') format('woff2'),
//        url('../../assets/fonts/FlamaSemicondensed-Basic.woff') format('woff'),
//        url('../../assets/fonts/FlamaSemicondensed-Basic.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'FlamaSemicondensed Black';
//   src: url('../../assets/fonts/FlamaSemicondensed-Black.eot') format('embedded-opentype'),
//        url('../../assets/fonts/FlamaSemicondensed-Black.woff2') format('woff2'),
//        url('../../assets/fonts/FlamaSemicondensed-Black.woff') format('woff'),
//        url('../../assets/fonts/FlamaSemicondensed-Black.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'FlamaSemicondensed Bold';
//   src: url('../../assets/fonts/FlamaSemicondensed-Bold.eot') format('embedded-opentype'),
//        url('../../assets/fonts/FlamaSemicondensed-Bold.woff2') format('woff2'),
//        url('../../assets/fonts/FlamaSemicondensed-Bold.woff') format('woff'),
//        url('../../assets/fonts/FlamaSemicondensed-Bold.ttf') format('truetype');
// }

@font-face {
  font-family: 'FlamaSemicondensed Book';
  src: url('../../assets/fonts/FlamaSemicondensed-Book.eot') format('embedded-opentype'),
       url('../../assets/fonts/FlamaSemicondensed-Book.woff2') format('woff2'),
       url('../../assets/fonts/FlamaSemicondensed-Book.woff') format('woff'),
       url('../../assets/fonts/FlamaSemicondensed-Book.ttf') format('truetype');
}

// @font-face {
//   font-family: 'FlamaSemicondensed Extrabold';
//   src: url('../../assets/fonts/FlamaSemicondensed-Extrabold.eot') format('embedded-opentype'),
//        url('../../assets/fonts/FlamaSemicondensed-Extrabold.woff2') format('woff2'),
//        url('../../assets/fonts/FlamaSemicondensed-Extrabold.woff') format('woff'),
//        url('../../assets/fonts/FlamaSemicondensed-Extrabold.ttf') format('truetype');
// }

@font-face {
  font-family: 'FlamaSemicondensed Light';
  src: url('../../assets/fonts/FlamaSemicondensed-Light.eot') format('embedded-opentype'),
       url('../../assets/fonts/FlamaSemicondensed-Light.woff2') format('woff2'),
       url('../../assets/fonts/FlamaSemicondensed-Light.woff') format('woff'),
       url('../../assets/fonts/FlamaSemicondensed-Light.ttf') format('truetype');
}

// @font-face {
//   font-family: 'FlamaSemicondensed Medium';
//   src: url('../../assets/fonts/FlamaSemicondensed-Medium.eot') format('embedded-opentype'),
//        url('../../assets/fonts/FlamaSemicondensed-Medium.woff2') format('woff2'),
//        url('../../assets/fonts/FlamaSemicondensed-Medium.woff') format('woff'),
//        url('../../assets/fonts/FlamaSemicondensed-Medium.ttf') format('truetype');
// }

@font-face {
  font-family: 'FlamaSemicondensed Semibold';
  src: url('../../assets/fonts/FlamaSemicondensed-Semibold.eot') format('embedded-opentype'),
       url('../../assets/fonts/FlamaSemicondensed-Semibold.woff2') format('woff2'),
       url('../../assets/fonts/FlamaSemicondensed-Semibold.woff') format('woff'),
       url('../../assets/fonts/FlamaSemicondensed-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'FlamaSemicondensed Ultralight';
  src: url('../../assets/fonts/FlamaSemicondensed-Ultralight.eot') format('embedded-opentype'),
       url('../../assets/fonts/FlamaSemicondensed-Ultralight.woff2') format('woff2'),
       url('../../assets/fonts/FlamaSemicondensed-Ultralight.woff') format('woff'),
       url('../../assets/fonts/FlamaSemicondensed-Ultralight.ttf') format('truetype');
}

@mixin flamasemi-ultralight {
  font-family : 'FlamaSemicondensed Ultralight', sans-serif;
  font-style  : normal;
}

@mixin flamasemi-light {
  font-family : 'FlamaSemicondensed Light', sans-serif;
  font-style  : normal;
}

@mixin flamasemi-book {
  font-family : 'FlamaSemicondensed Book', sans-serif;
  font-style  : normal;
}

@mixin flamasemi-basic {
  // font-family : 'FlamaSemicondensed Basic', sans-serif;
  font-family : 'FlamaSemicondensed Book', sans-serif;
  font-style  : normal;
}

@mixin flamasemi-medium {
  // font-family : 'FlamaSemicondensed Medium', sans-serif;
  font-family : 'FlamaSemicondensed Semibold', sans-serif;
  font-style  : normal;
}

@mixin flamasemi-semibold {
  font-family : 'FlamaSemicondensed Semibold', sans-serif;
  font-style  : normal;
}

// @mixin flamasemi-bold {
//   font-family : 'FlamaSemicondensed Bold', sans-serif;
//   font-style  : normal;
// }

// @mixin flamasemi-extrabold {
//   font-family : 'FlamaSemicondensed Extrabold', sans-serif;
//   font-style  : normal;
// }

// @mixin flamasemi-black {
//   font-family : 'FlamaSemicondensed Black', sans-serif;
//   font-style  : normal;
// }

// @mixin flama-ultralight {
//   font-family : 'Flama Ultralight', sans-serif;
// }

@mixin flama-light {
  font-family : 'Flama Light', sans-serif;
}

@mixin flama-book {
  font-family : 'Flama Book', sans-serif;
}

@mixin flama-basic {
  // font-family : 'Flama Basic', sans-serif; // Changed to book
  font-family : 'Flama Book', sans-serif;
}

// @mixin flama-medium {
//   font-family : 'Flama Medium', sans-serif;
// }

@mixin flama-semibold {
  font-family : 'Flama Semibold', sans-serif;
}

@mixin flama-bold {
  font-family : 'Flama Bold', sans-serif;
}

@mixin flama-extrabold {
  font-family : 'Flama Extrabold', sans-serif;
}

// @mixin flama-black {
//   font-family : 'Flama Extrablack', sans-serif;
// }

.flamasemi-ultralight {
  font-family : 'FlamaSemicondensed Ultralight', sans-serif;
  font-style  : normal;
}

.flamasemi-light {
  font-family : 'FlamaSemicondensed Light', sans-serif;
  font-style  : normal;
}

.flamasemi-book {
  font-family : 'FlamaSemicondensed Book', sans-serif;
  font-style  : normal;
}

.flamasemi-basic {
  // font-family : 'FlamaSemicondensed Basic', sans-serif; // Changed to book
  font-family : 'FlamaSemicondensed Book', sans-serif;
  font-style  : normal;
}

.flamasemi-medium {
  // font-family : 'FlamaSemicondensed Medium', sans-serif;
  font-family : 'FlamaSemicondensed Semibold', sans-serif;
  font-style  : normal;
}

.flamasemi-semibold {
  font-family : 'FlamaSemicondensed Semibold', sans-serif;
  font-style  : normal;
}

// .flamasemi-bold {
//   font-family : 'FlamaSemicondensed Bold', sans-serif;
//   font-style  : normal;
// }

// .flamasemi-extrabold {
//   font-family : 'FlamaSemicondensed Extrabold', sans-serif;
//   font-style  : normal;
// }

// .flamasemi-black {
//   font-family : 'FlamaSemicondensed Black', sans-serif;
//   font-style  : normal;
// }

// .flama-thin{
//   font-family : 'Flama Thin', sans-serif;
// }

// .flama-ultralight {
//   font-family : 'Flama Ultralight', sans-serif;
// }

.flama-light {
  font-family : 'Flama Light', sans-serif;
}

.flama-book {
  font-family : 'Flama Book', sans-serif;
}

.flama-basic {
  // font-family : 'Flama Basic', sans-serif; // Changed to basic
  font-family : 'Flama Book', sans-serif;
}

// .flama-medium {
//   font-family : 'Flama Medium', sans-serif;
// }

.flama-semibold {
  font-family : 'Flama Semibold', sans-serif;
}

.flama-bold {
  font-family : 'Flama Bold', sans-serif;
}

.flama-extrabold {
  font-family : 'Flama Extrabold', sans-serif;
}

// .flama-black {
//   font-family : 'Flama Black', sans-serif;
// }