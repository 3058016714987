.contact-us {
  strong {
    @include flamasemi-semibold;
  }

  .hero {
    background-color: $dark-charcoal;
    background-image: url('/assets/images/boxes.svg');
    background-repeat: no-repeat;
    background-position: 180% center;
    background-size: 70% 110%;
    margin: 65px 0 30px;
    padding: 30px 15px;
    
    h1, h2 {
      color: $white;
      text-align: center;
    }

    h2 {
      margin-top: 30px;
    }
    
    a, a:hover, a:focus {
      color: $white;
      text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
      padding: 100px 0 20px;
      background-position: 120% center;
      background-size: contain;

      h1, h2 {
        text-align: left;
      }

      h2 {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      background-position: 100% center;
      background-size: 30% 120%;
    }
  }

  .contact-form {
    h3 {
      margin-bottom: 15px;
    }
    
    // .signout {
    //   color: $white;
    //   margin: 15px 0;
    //   padding: 10px 30px;
    // }
  }

  .sidebar {
    .manager {
      margin: 20px 0;
    }

    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }

  .location {
    margin-bottom: 30px;

    h3 {
      @include flamasemi-basic;
      color: $dark-charcoal;
      margin: 20px 0;
    }

    .content-label {
      color: $purple;
    }

    ul {
      padding: 0;
      margin-top: 30px;
      font-size: 16px;
      list-style-type: none;
      color: $purple;
      @include flamasemi-basic;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .locations {
    border-top: solid 1px #f1f1f1;
    padding-top: 60px;
    margin: 60px 0;
  }
}