.error404 {
	padding: 120px 0;
}

.error404-svg {
	padding: 20px 0;
}

.text404 > p > a {
	color: #3B94A3 !important;
}

#search404icon {
    position: absolute;
    right: 30px;
    top: 20px;
}

#searchterm404 {
  font-size: 1.4rem;
  border-radius: 0;
  outline: none;
  border: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, .15);
}

@media screen and (max-width: 767px){
	.search404 {
		padding-top: 20px;
	}

	.text404 {
		text-align: center;
	}

	#search404icon {
		top: 40px;
	}

	.card-heading {
		text-align: center;
	}

	.card-row {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media screen and (max-width: 426px){
	.error404 {
		padding: 55px 0;
	}

	.error404-svg > svg {
		width: 180px;
		height: auto;
	}

	.text404 {
		text-align: center;
	}

	.card-row {
		padding-left: 30px;
		padding-right: 30px;
	}
}


// .narrationCards-links h4 {
//   color: #164A62; }

// .narrationCards-links .card {
//   padding: 60px 30px 15px 30px;
//   margin: 1%;
//   border: 1px solid rgba(0, 0, 0, 0.125); }
  
//   @media screen and (max-width: 992px) {
//     .narrationCards-links .card {
//       min-width: 250px; }
//   }
//   .narrationCards-links .card .card-img {
//     width: 50px;
//     height: 50px;
//     position: absolute;
//     top: 30px;
//     right: 15px; }
//     .narrationCards-links .card .card-img img {
//       display: block;
//       margin-left: auto;
//       margin-right: auto;
//       max-width: 50px;
//       max-height: 50px;
//       position: absolute;
//       top: 50%;
//       -webkit-transform: translateY(-50%);
//       -ms-transform: translateY(-50%);
//       transform: translateY(-50%); }
//   .narrationCards-links .card .cta-container {
//     margin-top: auto; }
//     .narrationCards-links .card .cta-container .cta-text {
//       color: #C53494;
//       border-bottom: 1px solid rgba(197, 52, 148); }