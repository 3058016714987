
body {
  @include flama-book;
}

h1 {
  @include flamasemi-light;

  font-size   : 40px;
  line-height : 48px;
}

h2 {
  @include flamasemi-book;

  font-size   : 32px;
  line-height : 36px;
}

h3 {
  @include flamasemi-book;

  font-size   : 28px;
  line-height : 33px;
}

h4 {
  @include flamasemi-book;

  font-size   : 22px;
  line-height : 30px;
}

h5 {
  @include flamasemi-book;

  font-size   : 18px;
  line-height : 26px;
}

h6 {
  @include flamasemi-book;

  font-size   : 16px;
  line-height : 24px;
}

p {
  line-height : 22px;
}
p, .default {
  font-size   : 16px;

  & strong {
    @include flama-semibold;
  }
}

// mobile 
@include media-breakpoint-down(sm) {
  h1 {
    font-size   : 26px;
    line-height : 32px;
  }

  h2 {
    font-size   : 23px;
    line-height : 28px;
  }

  h3 {
    font-size   : 20px;
    line-height : 26px;
  }

  h4 {
    font-size   : 16px;
    line-height : 22px;
  }
}

p, tr, td {
  @include flama-book;
}

.hero-heading {
  @include flamasemi-light;

  font-size   : 56px;
  line-height : 62px;
}

.primary-heading {
  @include flamasemi-light;

  font-size   : 40px;
  line-height : 48px;
}

.secondary-heading {
  @include flamasemi-book;

  font-size   : 32px;
  line-height : 36px;
}

// Replaced with semi light
// .ultralight-heading {
//   @include flama-ultralight;

//   font-size   : 18px;
//   line-height : 22px;
// }

.supporting-copy {
  @include flama-book;

  font-size   : 16px;
  line-height : 24px;
}

.tertiary-heading {
  @include flamasemi-book;

  font-size   : 22px;
  line-height : 30px;
}

.cta-text {
  @include flamasemi-basic;

  font-size   : 16px;
  line-height : 24px;
}

.nav-menu-item {
  @include flamasemi-medium;

  font-size   : 16px;
  line-height : 19px;
}

.table-cell-heading {
  @include flamasemi-semibold;

  font-size      : 14px;
  letter-spacing : 0.1em;
  line-height    : 21px;
  text-transform : uppercase;
}

.content-label {
  @include flama-basic;

  font-size      : 10px;
  letter-spacing : 0.25em;
  line-height    : 14px;
  text-transform : uppercase;

  & strong {
    @include flama-semibold;
  }
}

.thead-title {
  @include flamasemi-basic;

  font-size: 28px;
  line-height: 32px;
}

.tbody-title, .comp-title {
  @include flamasemi-semibold;

  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  text-transform: uppercase;

  & strong {
    @include flamasemi-semibold;
  }
}

.list-title {
  @include flamasemi-basic;

  font-size   : 16px;
  line-height : 24px;
}

.list-item {
  @include flamasemi-medium;

  font-size   : 14px;
  line-height : 19px;
}

.text-link {
  @include flama-book;

  font-size   : 14px;
  line-height : 22px;
  // color       : $teal;
}

.caption {
  @include flama-book;
  font-style: italic;

  font-size: 16px;
  line-height: 22px;
}

.helper-text {
  @include flama-basic;

  font-size: 14px;
  line-height: 22px;
}

.megamenu-desc, .arrow-text {
  @include flama-book;

  font-size: 14px;
  line-height: 22px;
}

.form-check .form-check-label {
  @include flamasemi-book;

  font-size: 13px;
  line-height: 26px;
}

.footer .site-links ul.site-links-group-list li a {
  @include flama-basic;

  font-size: 12px;
  line-height: 28px;
}

@include media-breakpoint-down(md) {
  .hero-heading {
    font-size   : 42px;
    line-height : 48px;
  }
}

@include media-breakpoint-down(sm) {
  .hero-heading {
    font-size   : 30px;
    line-height : 35px;
  }

  .primary-heading {
    @include flamasemi-basic;

    font-size   : 26px;
    line-height : 32px;
  }

  .secondary-heading {
    font-size   : 22px;
    line-height : 26px;
  }
}