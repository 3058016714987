@include media-breakpoint-down(md) {
  .accordion-item {
    .toggle-content {
      display : none;
    }
    &.active { 
      .toggle-content {
        display : block;
      }
    }
  } 
}

@include media-breakpoint-up(lg) {
  .accordion-item, .accordion-item.active {
    .toggle-content {
      display : inherit;
    }
  } 
}


// ----- Resources Accordion ----- 
.r-accordion {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.15);
  .gradient-border {
    padding: 0;
  }
  ul.resource-list {
    margin-bottom: 0;
    list-style-type: none;
    padding-inline-start: 0;
    position: relative;
    border-top: 1px solid rgba($light-gray-mcui, 0.35);
    border-bottom: 1px solid rgba($light-gray-mcui, 0.35);
    .resource-row {
      position: relative;
      padding: 10px 40px 10px 20px;
      border-bottom: 1px solid rgba($light-gray-mcui, 0.35);
      &:last-of-type {
        border-bottom: none;
      }
      &.active {
        .info-expand {
          opacity: 1;
          transition: opacity 0.4s;
        }
      }
    }
  }
  .tease-doc-links, .shown-doc-links {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    img.doc-icon {
      width: 40px;
      height: auto;
      margin-right: 15px;
    }
    p.resource-heading {
      color: $dark-teal;
      margin: 0;
    }
    .circle-button {
      margin-left: auto;
    }
  }
  .info-click {
    position: absolute;
    top: 16px;
    right: 10px;
    cursor: pointer;
    color: $magenta;
    padding: 10px;
    font-size: 16px;
  }
  .info-expand {
    position: relative;
    opacity: 0;
    top: 0;
    left: 0;
    padding-top: 15px;
    transition: opacity 0.4s ease-in-out;
  }
}
@media screen and (min-width: 768px) {
  // Gradient Scrollbar
  .r-scroll {
    padding: 10px 15px 10px 0;
    max-height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 80%;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #C53494 0%, #3B94A3 97.93%);
    }
    &::-webkit-scrollbar-track {
      background: rgba(154, 154, 154, 0.1);
    }
  }
}