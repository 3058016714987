.narration {
  .container {
    display     : flex;
    padding     : 60px 0;
    flex        : 1 50%;
    align-items : center;
  }

  &.list-header{
    .container {
      display        : flex;
      flex-direction : column;
      align-items    : flex-start;
      padding-left   : 75px;
    }
  }

  .primary-heading {
    padding-right : 30px;
    flex-basis    : 50%;


    &.gradient-border {
      padding      : 0 30px;
      border-width : 0;
      border-image : linear-gradient(90deg, $purple 0%, $teal 100%);
      border-left  : 6px solid;
    }
  }

  //Added this supporting-copy Aug 16 2019
  .supporting-copy {
    flex-basis   : 50%;
  }

  .layout-left .supporting-copy {
    padding-left : 30px;
    flex-basis   : 50%;
  }
  .layout-right .supporting-copy {
    padding-right : 30px;
    flex-basis   : 50%;
  }
  
  //This fixes the flex-basis on IE
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    &.list-header{
      .container{
        display: block;
      }
      
    }
    .primary-heading {
      flex-basis    : 40%;
    }
    .layout-left .supporting-copy {
      flex-basis   : 50%;
    }
    .layout-right .supporting-copy {
      flex-basis   : 50%;
    }
    .supporting-copy {
      padding : 0 !important;
    }

    &.narration-paragraph {
      .container{
        display: block;
      }
    }
    &.narration-quote {
      .container {
        display: block;
      }
    }
    &.narration-image-HC {
      .container {
        display: block;
      }
    }
    &.narration-list {
      .container {
        display: block;
      }
    }
  }

}
@include media-breakpoint-down(sm) {
  .narration {
    .container {
      padding        : 40px 20px 20px 20px ;
      flex-direction : column;
      align-items    : flex-start;
    }
    &.list-header{
      .container{
        padding-left: 20px;
      }
    }

    .primary-heading {
      margin-bottom : 20px;
      padding       : 0;
      width: 100%;
      flex-basis: auto;

      &.gradient-border {
        padding           : 0 20px;
        border-left-width : 5px;
      }
    }

    .supporting-copy {
      padding : 0;
    }
  }
}

// Narration CTA Blade
.narration-CTA {
  text-align: center;
  padding: 60px 0;

  .btn {
    margin-top: 15px;
  }
}

// Narration Image with Header Blade
.narration-image-HC {
  .container {
    display: flex;
    flex-direction: column;
  }
  .text-block {
    align-self: flex-start;
    padding-bottom: 30px;
  }
  .image-block {
    max-width: 100%;
    height: auto;
  }
}

.narration-list {
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .list-block ol, ul {
    font-size: 26px;
    @include flamasemi-book;
    color: $teal;
    li {
      h3{
        @include flamasemi-book;
        color: $teal;
        font-size: 26px;
      }
      p {
        color: $dark-charcoal;
      }
    }
  }
  /*.list-block ul {
    font-size: 28px;
    @include flamasemi-book;
    color: $teal;
    li {
      h3{
        @include flamasemi-book;
        color: $teal;
      }
      p {
        color: $dark-charcoal;
      }
    }
  }*/
  .two-col {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
    -webkit-column-gap: 2em;
       -moz-column-gap: 2em;
            column-gap: 2em;
  }
  .ol-letter {
    list-style-type: upper-alpha;
  }
  .ol-numbered {
    list-style-type: decimal;
  }
  &.dark {
    .list-block ol {
      color: $white;
      li {
        h3 {
          color: $white;
        }
        p {
          color: $white;
        }
      }
    }
    .list-block ul {
      color: $white;
      li {
        h3 {
          color: $white;
        }
        p {
          color: $white;
        }
      }
    }
  }
}

// Narration Logos Blade
.narration-logos {
  padding: 60px 0;

  h3 {
    font-size: 16px;
    text-align: center;
  }

  img {
    max-height: 200px;
    max-width: 200px;
    margin: 30px;
  }
}

// Narration Paragraph Blade
.narration-paragraph {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .paragraph-heading {
    font-size: 28px;
    line-height: 33px;
    @include flamasemi-book;
  }
  .gradient-line {
    height: 1px;
    width: 80px;
    margin: 10px 0;
    background-image: linear-gradient(90deg, $purple 0%, $teal 100%);
  }  
}

// Narration Quote Blade
.narration-quote {
  .container {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start !important;
  }
  .supporting-copy {
      padding : 0;
    }
  .quote-text {
    position: relative;
    &:before{
      content: '\201C';
      color: $purple;
      position: absolute;
      font-size: 72px;
      left: -30px;
      top: -10px;
    }
    p {
      font-size: 28px;
      line-height: 47px;
      @include flamasemi-book;
    }
  }
  .gradient-line {
    height: 1px;
    width: 80px;
    margin: 10px 0;
    background-image: linear-gradient(90deg, $purple 0%, $teal 100%);
  }
  .quote-author {
    font-size: 22px;
    line-height: 30px;
    color: $teal;
    .author-title {
      font-weight: 600;
    }
  }

  &.dark {
    .quote-text {
      color: $white;
    }
    .quote-author {
      color: $white;
    }
  }
  &.neutral {
    background-image: linear-gradient(130deg, $purple 0%, $teal 100%);
    .quote-text {
      color: $white; 
      &:before {
        color: $white;
      }
    }
    .gradient-line {
      background-image: linear-gradient(90deg, $white 0%, $white 100%);
    }
    .quote-author{
      color: $white;
    }
  }
}

@include media-breakpoint-down(md) {
  .narration-quote {
    .quote-text {
      position: relative;
      margin-top: 15px;
      &:before {
        position: absolute;
        left: 0px;
        top: -15px;
      }
      p {
        font-size: 20px;
        line-height: 30px;
        @include flamasemi-book;
      }
    }
    .supporting-copy {
      padding : 0;
    }

    .quote-author {
      .author-title {
        font-weight: 600;
        @include flamasemi-light;
      }
    }
  }
  .narration-list {
    .two-col {
      -webkit-columns: 1;
         -moz-columns: 1;
              columns: 1;
    }
  }
}