
.hero-module {
  position         : relative;
  margin : 0px 0 40px 0;
  background-color : $light-gray-web;
  justify-content  : center;

  .intro-copy {
    position         : relative;
    display          : flex;
    top              : 40px;

    background-color : $dark-charcoal;

    justify-content  : flex-end;

    &:before {
      content      : '';

      position     : absolute;
      display      : block;
      right        : 0px;

      height       : 0;
      width        : 0;

      border-color : transparent $light-gray-web transparent transparent;
      border-style : solid;
      border-width : 0 60px 60px 0;
    }
    
    .hero-section-wrapper {
      max-width : calc(1140px / 2);
      padding: 70px 5%;
      width: 100%;

      br {
        display:none;
      }
      ul, ol {
        font-family: 'Flama book', sans-serif;
        font-size: 16px;
        line-height: 22px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  .gradient {
    position   : absolute;
    bottom     : 0px;
    left       : 0px;

    height     : 10px;
    width      : 100%;

    background : $purple-to-teal;
  }

  .primary-heading {
    margin-bottom : 3%;
    color         : $white;

    // &:before {
    //   content          : ' ';
    //   display          : block;
    //   height           : 50px;
    //   width            : 50px;
    //   margin-bottom    : 20px;
    //   background       : $gradient-corner;
    //   background-color : $purple;
    // }
  }

  .supporting-copy {
    opacity : 0.8;
    color   : $white;
  }

  .description {
    display         : flex;
    color           : $charcoal;
    justify-content : space-between;
    flex-direction  : column;

    .hero-section-wrapper {
      max-width : calc(1140px / 2);
      padding : 50px 5% 20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-module {
    justify-content : flex-end;
  }

  .hero-module .intro-copy .hero-section-wrapper {
    padding-right : 10%;
    padding-left  : 10% ;
  }

  .hero-module .description .hero-section-wrapper {
    padding-right : 7%;
    padding-left  : 7% ;
  }
}

@include media-breakpoint-up(xl) {
  .hero-module .intro-copy {
    max-width : 700px;
  }

  .hero-module .intro-copy .hero-section-wrapper {
    max-width     : none;
    padding-right : 20%;
    padding-left  : 75px;
  }

  .hero-module .description .hero-section-wrapper {
    padding-right : 0;
    padding-left  : 8% ;
  }
}

@include media-breakpoint-down(lg) {
  .hero-module .intro-copy {
    top : 50px;
  }
}

@include media-breakpoint-down(sm) {
  .hero-module {
    margin-bottom    : 0;
    background-color : $white;

    .container {
      margin-bottom  : 0;
      padding        : 0;
      flex-direction : column;
    }

    .intro-copy, .description {
      width : 100%;
    }

    .intro-copy {
      top             : 0;
      left            : 0;
      z-index         : 5;
      margin-right    : 20px;
      background-size : 34px;

      &:before {
        border-color : transparent $white transparent transparent;
        border-width : 0 40px 40px 0;
      }
      .hero-section-wrapper {
        max-width : none;
        margin    : 0 auto;
        padding   : 40px 20px;
      }
    }

    // .primary-heading {
    //   &:before {
    //     height : 30px;
    //     width  : 30px;
    //   }
    // }

    .description {
      z-index          : 1;

      margin-top       : -100px;
      padding          : 0;
      padding-top      : 100px;

      background-color : $light-gray-web;

      .hero-section-wrapper {
        max-width : none;
        padding   : 40px 0 0 0;
      }
      p {
        padding : 0 20px;
      }
    }
  }
}

.hero-module-hci {
  position: relative;
  
  .image {
    overflow : hidden;

    img {
      @include transform(translate(-50%,-50%));

      position : absolute;
      top      : 50%;
      left     : 50%;
    }

    @include media-breakpoint-down(sm) {
      position      : relative;
      margin-bottom : -$triangle-size-sm;

      img {
        height : 100%;
      }
    }

    @include media-breakpoint-only(sm) {
      height : 350px;
    }

    @include media-breakpoint-only(xs) {
      height : 250px;
    }

    @include media-breakpoint-up(md) {
      position : absolute;

      overflow : hidden;
      height   : 100%;
      width    : 100%;

      img {
        min-height : 530px;
        min-width  : 100%;
      }
    }
  }

  .hero-container{
    position: relative;

    @include media-breakpoint-up(md) {
      padding : 60px 0 0 0;
    }

    @include media-breakpoint-down(sm) {
      padding : 0;
    }

    .intro-copy {
      @include media-breakpoint-only(xl) {
        margin-left : -90px;
      }

      @include media-breakpoint-up(lg) {
        margin-top  : 100px;
        margin-left : -50px;
      }

      @include media-breakpoint-only(md) {
        margin-top  : 50px;
        margin-left : -25px;
      }

      .top-edge {
        position      : relative;
        height        : $triangle-size-lg;
        margin-right  : $triangle-size-lg;
        background    : $white;

        &:after {
          content       : '';
          position      : absolute;
          left          : 100%;
          height        : 0;
          width         : 0;
          border-color  : transparent transparent transparent $white;
          border-style  : solid;
          border-width  : $triangle-size-lg 0 0 $triangle-size-lg;
        }

        @include media-breakpoint-only(md) {
          height        : $triangle-size-sm;
          margin-right  : $triangle-size-sm;

          &:after {
            border-width  : $triangle-size-sm 0 0 $triangle-size-sm;
          }
        }
      }

      .inner {
        padding           : 0 90px 60px;
        background-color  : $white;

        .hero-heading {
          margin-bottom : 40px;
        }

        @include media-breakpoint-up(lg) {
          padding : 0 10% 60px;
          .hero-heading {
            font-size : 50px;
          }
        }

        @include media-breakpoint-only(md) {
          padding : 0 40px 40px;
        }

        @include media-breakpoint-down(sm) {
          padding : 0 20px 40px;
          .her0-heading {
            margin-bottom : 20px;
          }
        }
      }
    }
  }
}

.hero-module-hcv {
      .dark {
        h1, h2, h3, p {
            color:#fff;
        }
    }
    .hero {
        height: auto;
        padding-top: 85px;

        .intro-copy, .hero-video, .contents {
            z-index: 10;
            padding: 0;
        }

        .hero-video {
            cursor: pointer;
            &:hover .play-button:before {
                opacity: 1;
                transform: scale(1);
            }
        }

        .intro-copy {
            padding-right: 30px;
            position: relative;
            height: auto;
        }

        .hero-bg-image {
            position: absolute;

            video { 
                position: relative;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                transform: translateX(-50%) translateY(-50%);
                background: url('image.jpg') no-repeat;
            }
        }

        &.video {
            padding: 120px 0px;
            background-color: $dark-charcoal;
            position: relative;
            //background-image: url('../images/hexagon-bg.png');
            background-position: bottom right;
            background-repeat: no-repeat;
            overflow: hidden;

            .hero-container {
                justify-content: space-between;
                align-items: center; 
                position: relative;
                padding: 0;
            }

            .hero-heading {
                margin-bottom: 40px;

                &:before {
                    content: '';
                    width: 60px;
                    height: 60px;
                    border: 15px solid $white;
                    display: block;
                    margin-bottom: 40px;
                }
            }

            .background-square {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 5;
                svg {
                    width: 346px;
                    height: 454px;
                }

                @include media-breakpoint-down(sm) {
                    top: -56px;
                    right: -40px;
                    svg {
                       width: 190px;
                        height: 240px;
                    }
                }
            }

            .drawing-container {
                width:750px;
                margin: 0 auto;
                z-index: 3;    
                position: absolute;
                top: 30px;
                right: -115px;
                svg {    
                    background: none;
                    border: 0;
                    backface-visibility: hidden; 
                }
            }
            .path {
                stroke-dasharray: 10000;
                stroke-dashoffset: 10000;
                animation: dash 15s linear forwards 1;
                fill:none;
                stroke:#303b48;
                stroke-miterlimit:10;
                stroke-width: 2px;
            }

            @keyframes dash {
                to {
                    stroke-dashoffset: 0;
                }
            }

            .video-embed {
                width:100%;
                height:100%;
                display: flex;
                align-items: center;
                padding: 30px;

                img {
                    width: 100%;
                }
            }
        }
        
        &.image {
            position: relative;
            height: auto;
            
            .hero-bg-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: center center/cover no-repeat; // URL is placed on div in code.
                
                .desktop {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
                
                .mobile {
                    display: none;
                }
            }
            
            .hero-container {
                position: relative;
                height: 100%;
                padding: 0;
                
                .intro-copy {
                    // position: absolute;
                    // height: 475px;
                    // bottom: 0px;
                
                    .top-edge {
                        position: relative;
                        height: 70px;
                        margin-right: 70px;
                        background: #FFF;
                        
                        // &:before {
                        //     content: '';
                        //     position: absolute;
                        //     top: -40px;
                        //     left: 90px;
                        //     width: 80px;
                        //     height: 80px;
                        //     background: url('/assets/images/hero-image-icon.png');
                        //     background-size: contain;
                        // }
                        
                        &:after {
                            content: '';
                            position: absolute;
                            left: 100%;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 70px 0 0 70px;
                            border-color: transparent transparent transparent #ffffff;
                        }
                    }
                    
                    .inner {
                        height: auto;
                        padding: 25px 10%;
                        background: #FFF;
                        
                        .hero-heading {
                            margin-bottom: 40px;
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-down(md) { 
            &.video {
                padding: 50px 0px;
            }

            .cta-container.action .cta {
              padding: 22px;
            }
        }
        @include media-breakpoint-down(sm) { 
            
            &.video {
                padding: 20px 20px;
                background-position: bottom left;
                background-size: 100%;

                .gradient-box {
                    right: -80px;
                    top: -60px;
                    width: 240px;
                    height: 240px;
                    padding: 60px;
                }

                .gradient-box-inner {
                    background-position: -360px -11px;
                    width: 100%;
                    height: 100%;
                }

                .hero-heading {
                    margin-bottom: 20px;
                    &:before {
                        width: 40px;
                        height: 40px;
                        border-width: 10px;
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }
                }
                
                .intro-copy {
                    order: 2;
                    padding-left: 0;
                    padding-right: 0;
                }

                .hero-video {
                    padding: 0 0 30px 0!important
                }
            }
            
            &.image {
                height: auto;
                
                .hero-bg-image {
                    // position: relative;
                    height: 235px;
                    
                    .desktop {
                        display: none;
                    }
                    
                    .mobile {
                        display: block;
                    }
                }
                
                .hero-container {
                    // top: -50px;
                    height: auto;
                    
                    .intro-copy {
                        position: relative;
                        bottom: 0;
                        height: auto;
                        padding: 0;
                        
                        .top-edge {
                            height: 50px;
                            margin-right: 50px;
                            
                            &:before {
                                top: -30px;
                                left: 20px;
                                width: 60px;
                                height: 60px;
                            }
                            
                            &:after {
                                border-width: 50px 0 0 50px;
                            }
                        }
                        
                        .inner {
                            height: auto;
                            padding: 0 20px;

                            .hero-heading {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/** Begin Hero HSCR **/
.hero-HSCR {
  background-color: $white;
  margin: 60px 0 40px 0;

  .intro-copy {
    top: 20px;

    &:before {
      border-color : transparent $white transparent transparent;
    }

    .hero-section-wrapper {
      padding: 60px 5% 70px 5%;
    }
  }
  .description {
    .hero-section-wrapper {
      margin-top: 20px;
    }
  }

  &.neutral {
    background-color: $light-gray-web;

    .intro-copy {
      &:before {
        border-color : transparent $light-gray-web transparent transparent;
      }
    }
  }

  &.dark {
    background-color: $dark-charcoal;

    .intro-copy {
      background-color: $white;
      &:before {
        border-color : transparent $dark-charcoal transparent transparent;
      }

      .primary-heading {
        color: $dark-teal;
      }

      .supporting-copy {
        color: $mid-gray;
      }
    }

    .description {
      color: $white;
    }
  }

  @include media-breakpoint-down(lg) {
    .intro-copy {
      top : 0px;
    }
    .description {
      .hero-section-wrapper {
        margin-top: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.dark {
      .description {
        background-color : $dark-charcoal;
      }
    }
      
    &.neutral {
      .description {
        background-color: $light-gray-web;
      }
    }
  } 
}
/** End Hero HSCR **/
/** Begin Hero HC **/
.hero-HC {
  margin-top: 40px;
  padding: 40px 0;

  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 0;
  }

  .primary-heading {
    font-size: 44px;
    line-height: 52px;
  }
  .supporting-copy {
    padding: 30px 0;
    line-height: 26px;
  }

  .hero-HC-Gradient {
    height     : 4px;
    width      : 33%;

    background : $purple-to-teal;
  }
}
/** End Hero HC **/