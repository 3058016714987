.news {
  padding-top: 30px;
  padding-bottom: 30px;

  .overview {
    padding-top: 60px;
  }

  .news-contact {
    margin-bottom: 30px;
  }

  .news-item {
    margin-top: 15px;

    p {
      margin: 0;
    }
  }

  li, a {
    font-size: 16px;
  }

  .archive-cta {
    margin-top: 30px;
  }

  .news-pagination {
    margin-top: 30px;
  }
}